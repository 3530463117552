import iso3166 from "iso-3166-2";
import moment from "moment-timezone";
import React from "react";

import { ControllerRenderProps } from "react-hook-form";
import { Timeframe } from "../pages/dashboard/home/cards/chart";

const FRACTION_DIGITS_CONFIG = [
  { threshold: 1, digits: 2 },
  { threshold: 0.01, digits: 4 },
  { threshold: 0.0001, digits: 6 },
];
const DEFAULT_DIGITS = 9;

const timeframeFormats: Record<Timeframe, string> = {
  "1D": "hh:mm A z",
  "1W": "MMM DD, yyyy hh:mm A z",
  "1M": "MMM DD, yyyy",
  "1A": "MMM DD, yyyy",
  all: "MMM DD, yyyy",
};

export const getPrettyDate = (value: string | Date | number = 0) => {
  const date = moment(value);
  if (date.isValid()) {
    return date.format("MMM DD, YYYY, hh:mm:ss A z");
  } else {
    return "-";
  }
};

export const getPrettyDateFromTimeframe = (
  timeframe: Timeframe,
  epoch?: number
) => {
  const format = timeframeFormats[timeframe] || timeframeFormats.all;
  return `${moment(epoch).tz(moment.tz.guess()).format(format)}`;
};

export const getPrettyDateFormatted = (
  value = 0,
  format = "MMM DD, yyyy, hh:mm A"
) => `${moment(value).format(format)}`;

export const getPrettyTime = (value: number = 0) =>
  `${moment(value).format("hh:mm A")}`;

const getMaxFractionDigits = (value: number) => {
  const num = Math.abs(value);
  for (const config of FRACTION_DIGITS_CONFIG) {
    if (num === 0 || num > config.threshold) {
      return config.digits;
    }
  }
  return DEFAULT_DIGITS;
};

export const getPrettyCash = (
  value: number | string | undefined,
  options?: {
    withPrefix?: boolean;
    forcePrecision?: number;
    minimumFractionDigits?: number;
    acceptZero?: boolean;
  }
): string => {
  const parsed =
    typeof value === "string"
      ? parseFloat((value as string).replace(/,/g, ""))
      : value;

  if (
    parsed === undefined ||
    parsed === null ||
    isNaN(parsed) ||
    !isFinite(parsed) ||
    (parsed === 0 && !options?.acceptZero)
  ) {
    return "–";
  }

  const sign = options?.withPrefix ? (parsed > 0 ? "+" : "-") : "";
  const prefix = parsed < 0 ? "-" : sign;
  const absValue = Math.abs(parsed).toLocaleString("en-US", {
    minimumFractionDigits: options?.minimumFractionDigits || 0,
    maximumFractionDigits:
      options?.forcePrecision || getMaxFractionDigits(parsed),
  });

  return `${prefix}$${absValue}`;
};

export const getPrettyAmount = (
  value: number | string = 0,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
): string => {
  const parsed = parseFloat(value?.toString());

  if (parsed === 0) {
    return "0.00";
  }

  if (isNaN(parsed) || !isFinite(parsed)) {
    return "-";
  }

  return `${(parsed > 0 ? "" : "-").concat(
    Math.abs(parsed).toLocaleString("en-US", {
      minimumFractionDigits,
      maximumFractionDigits,
    })
  )}`;
};

export const getPrettyPercent = (
  value: number | string | undefined = 0,
  noPrefix: boolean = false,
  multiply: boolean = false
): string => {
  const parsed = parseFloat(value?.toString());

  if (isNaN(parsed) || !isFinite(parsed) || parsed === 0) {
    return "-";
  }

  return `${parsed > 0 && !noPrefix ? "+" : ""}${(multiply
    ? parsed * 100
    : parsed
  ).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}%`;
};

export const getProfitLossColor = (value: string | number) => {
  const parsed = parseFloat(value?.toString());

  if (isNaN(parsed) || !isFinite(parsed) || parsed === 0) {
    return "text-gray-500";
  }

  // if the value is positive, return green
  if (parsed >= 0) {
    return "text-emerald-500";
  }

  // if the value is negative, return red
  return "text-rose-500";
};

/**
 * @name getNumberOnly
 * @description removes all non-numeric characters from a string
 *
 * @param {string} value
 * @return {number}
 */

export const getNumberOnly = (value = "") =>
  Number(value.replace(/[^0-9.]/g, ""));

/**
 * @name getCashTransformer
 * @description a function that modifies the input of a number value to a currency format (e.g. $1,000.00)
 *
 * @param {object} field - the field object from react-hook-form
 * @param {string} field.value - the value of the field
 * @param {function} field.onChange - the onChange function for the field
 */

export const getCashTransformer = ({
  field,
}: {
  field: ControllerRenderProps | any;
}) => {
  const transform = {
    // this will probably always be a pass-through
    input: (value: string) => value,
    // this will get called every time the input changes
    output: (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target?.value?.replace(/[^0-9.]/g, "") || "";
      const separator = value.includes(".") ? "." : "";
      const split = value.split(".");
      const base = split[0] !== "" ? parseInt(split[0]) : 0;
      const cents = split[1] || "";

      const formattedBase = base.toLocaleString("en-US");

      return `${
        base === 0 && value.startsWith("0") ? "0" : `$${formattedBase}`
      }${separator}${cents.slice(0, 2)}`;
    },
  };

  return {
    ...field,
    placeholder: "$0.00",
    value: transform.input(field.value),
    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
      field.onChange(transform.output(event)),
  };
};

/**
 * @name getISO3166Alpha3FormatMapping
 * @description a function that returns ISO3166-ALPHA-2 to ISO3166-ALPHA-3 format
 *
 */

export const getISO3166Alpha3FormatMapping = () => {
  let iso3166Alpha3FormatMapping: any = {};

  Object.keys(iso3166.codes).map((key) => {
    const value = iso3166.codes[key];
    iso3166Alpha3FormatMapping[value] = key;
  });

  return iso3166Alpha3FormatMapping;
};
