import React from "react";
import reduce from "lodash/reduce";
import MenuLabel from "./menuLabel";
import NewLabel from "src/components/AlpacaComponents/Label/New";
import "./style.scss";

import { connect } from "react-redux";
import { isBrowser } from "react-device-detect";
import { Link, withRouter } from "react-router-dom";
import { getAccountIdForProduct, isPaperOrOnboarding } from "selectors";
import { default as menuData } from "./menuData";
import { setLayoutState, setOpenKeys } from "reducers/app";
import { Card, Layout, Menu, Modal, Select } from "antd";

const { Option, OptGroup } = Select;
const { Sider } = Layout;

const SubMenu = Menu.SubMenu;
const Divider = Menu.Divider;

const mapStateToProps = (state, ownProps) => {
  const { app, account } = state;
  const accountId = getAccountIdForProduct(state, ownProps.product);

  const { layoutState, openKeys } = app;
  return {
    // TODO: Fix menu logic based on pathname, remove dependency on path
    pathname: "", //router.location.pathname,
    collapsed: layoutState.menuCollapsed,
    openKeys: openKeys,
    theme: layoutState.themeLight ? "light" : "dark",
    settingsOpened: layoutState.settingsOpened,
    userState: app.userState,
    account: account,
    accountDetails: account.details,
    paperAccount: (account && account.paper) || {},
    accountId,
    clearingBroker:
      (account && account.account && account.account.clearing_broker) || null,
  };
};

@connect(mapStateToProps)
@withRouter
class MenuLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      pathname: this.props.pathname,
      collapsed: this.props.collapsed,
      theme: this.props.theme,
      selectedKeys: "",
      settingsOpened: this.props.settingsOpened,
    };
  }

  handleClick = (e) => {
    const { dispatch, isMobile } = this.props;
    if (isMobile) {
      // collapse menu on isMobile state
      dispatch(setLayoutState({ menuMobileOpened: false }));
    }
    if (e.key === "settings") {
      // prevent click and toggle settings block on theme settings link
      dispatch(setLayoutState({ settingsOpened: !this.state.settingsOpened }));
      return;
    }

    // set current selected keys (unless its  denoted as an external one - must start with http)
    if (!e.key.match("http")) {
      this.setState({
        selectedKeys: e.key,
      });
    }
  };

  onOpenChange = (openKeys) => {
    const { dispatch } = this.props;
    dispatch(setOpenKeys(openKeys));
  };

  getPath(data, id, parents = []) {
    const { selectedKeys } = this.state;
    let items = reduce(
      data,
      (result, entry) => {
        if (entry && entry.key) {
          if (result.length) {
            return result;
          } else if (entry.url === id && selectedKeys === "") {
            return [entry].concat(parents);
          } else if (entry.key === id && selectedKeys !== "") {
            return [entry].concat(parents);
          } else if (entry.children) {
            let nested = this.getPath(
              entry.children,
              id,
              [entry].concat(parents)
            );
            return nested || result;
          }
        }
        return result;
      },
      []
    );
    return items;
  }

  getActiveMenuItem = (props, items) => {
    const { selectedKeys, pathname = "" } = this.state;
    let { collapsed } = props;
    // Note: Items that have keys with the word "switch" in them aren't eligible to be active.
    const eligibleItems = items.filter((item) => {
      return item.key && !item.key.match("switch") ? item : false;
    });
    let [activeMenuItem] = this.getPath(
      eligibleItems,
      !selectedKeys ? pathname : selectedKeys
    );

    // Special case here. Most menu items starting with 'switch' are intended to change product context.
    // Therefore it makes no sense to set them as active.
    // However, "Start Paper Trading" is also a switch, shared with "Go to Live Trading"
    // Could probably use a refactor here too.
    // Also note: this menu item has an additional class that helps it maintain a different color than normal
    // regardless of product context.
    // TODO: Refactor. Likely will make menuData per product (and allow them to share common items).
    if (pathname.match("brokerage/new-account")) {
      eligibleItems.forEach((item) => {
        if (item.key === "goToLiveAccount") {
          activeMenuItem = item;
        }
      });
    }

    this.setState({
      selectedKeys: activeMenuItem ? activeMenuItem.key : "",
      collapsed,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      {
        selectedKeys: "",
        pathname: nextProps.pathname,
        theme: nextProps.theme,
        settingsOpened: nextProps.settingsOpened,
        clearingBroker: nextProps.clearingBroker,
      },
      () => {
        if (!nextProps.isMobile) {
          this.getActiveMenuItem(nextProps, menuData);
        }
      }
    );
  }

  hidePaperTradingBackground() {
    Array.prototype.forEach.call(
      window.document.getElementsByClassName("ant-layout"),
      function (elem) {
        elem.style.background = "";
      }
    );
  }

  generateMenuPartitions(items) {
    return items.map((menuItem, index) => {
      if (menuItem.children) {
        let subMenuTitle = (
          <span className="menuLeft__title-wrap" key={menuItem.key}>
            <span className="menuLeft__item-title">{menuItem.title}</span>
            {menuItem.icon && (
              <span className={menuItem.icon + " menuLeft__icon"} />
            )}
          </span>
        );
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {this.generateMenuPartitions(menuItem.children)}
          </SubMenu>
        );
      }
      return this.generateMenuItem(menuItem, index);
    });
  }

  renderMenuItem = (
    url,
    title,
    target,
    modal,
    icon,
    additionalClassNames,
    isNew = false
  ) => {
    if (url.indexOf("http") === 0) {
      return (
        <a
          href={url}
          rel="noopener noreferrer"
          target={target || "_blank"}
          className={additionalClassNames}
        >
          <span className="menuLeft__item-title">{title}</span>
          {icon && <span className={icon + " menuLeft__icon"} />}
        </a>
      );
    } else if (url.indexOf("modal") === 0) {
      return (
        <div className={additionalClassNames}>
          <div onClick={this.showModal}>
            <span className="menuLeft__item-title">{title}</span>
            {icon && <span className={icon + " menuLeft__icon"} />}
          </div>
          {modal}
        </div>
      );
    } else {
      return (
        <Link
          amplitude-event-name={`${title
            .replace(" ", "_")
            .toLowerCase()}_sidebar_button_clicked`}
          to={url}
          onClick={
            this.props.isMobile
              ? () =>
                  this.props.dispatch(setLayoutState({ menuCollapsed: false }))
              : null
          }
          className={additionalClassNames}
        >
          <span className="menuLeft__item-title">
            {title} {isNew ? <NewLabel /> : undefined}
          </span>
          {icon && <span className={icon + " menuLeft__icon"} />}
          {window.location.pathname === url && <span className="active-icon" />}
        </Link>
      );
    }
  };

  generateMenuItem(item, indexKey) {
    const { product } = this.props;
    const { key, target, icon, disabled, track, isNew } = item;
    let { title, url, additionalClassNames } = item;
    let modal;

    // Special menu item titles - based on a user and the product, things can change.
    // So a function can be used. It will be given props.
    const interpolatedTitle =
      typeof title === "function" ? title(this.props) : title;

    // Special menu item urls - based on a user and the product, things can change.
    // So a function can be used. It will be given props.
    const interpolatedUrl = typeof url === "function" ? url(this.props) : url;

    // Allows the menu definition file (menuData.js) to determine if an item should be hidden.
    // Product context only gets us so far.
    if (typeof item.hideIf === "function" && item.hideIf(this.props)) {
      return null;
    }

    // Labels
    if (item.label) {
      return (
        <MenuLabel key={key || indexKey} label={item.label} product={product} />
      );
    }

    if (item.divider) {
      return <Divider key={key || indexKey} />;
    }

    // is submenu item
    if (item.items) {
      return (
        <SubMenu
          key={key}
          title={
            <>
              <span className="menuLeft__item-title">{title}</span>
              {icon && <span className={icon + " menuLeft__icon"} />}
            </>
          }
        >
          {item.items.map((subitem, index) =>
            this.generateMenuItem(subitem, index)
          )}
        </SubMenu>
      );
    }

    return item.url ? (
      <Menu.Item key={key} disabled={disabled}>
        {this.renderMenuItem(
          interpolatedUrl,
          interpolatedTitle,
          target,
          modal,
          icon,
          additionalClassNames,
          isNew
        )}
      </Menu.Item>
    ) : (
      <Menu.Item key={key} disabled={disabled}>
        <span className="menuLeft__item-title">{interpolatedTitle}</span>
        {icon && <span className={icon + " menuLeft__icon"} />}
      </Menu.Item>
    );
  }

  onCollapse = (value, type) => {
    const { dispatch } = this.props;
    const { collapsed } = this.state;
    if (type === "responsive" && collapsed) {
      return;
    }
    dispatch(setLayoutState({ menuCollapsed: !collapsed }));
  };

  componentDidMount() {
    this.getActiveMenuItem(this.props, menuData);
  }

  render() {
    const { collapsed, selectedKeys, theme } = this.state;
    const { isMobile, product, openKeys } = this.props;

    // Filter menu items b product.
    const menuItems = this.generateMenuPartitions(
      menuData.filter((item) => {
        // First, ensure the item is eligible to be displayed in the current product.
        // Items without this field are ok too, they are not bound to any product.
        if (!item.product || item.product.includes(product)) {
          // If the product matches, the menu item appears.
          return item;
        }
      })
    );

    const trigger = this.props.collapsed ? (
      <i className="fa fa-arrow-circle-right" />
    ) : (
      <>
        <Link
          style={{ opacity: "0.6", color: "rgb(45, 39, 7)" }}
          onClick={() => this.setState({ visible: true })}
        >
          <i
            className="fas fa-exclamation-circle"
            style={{ color: "rgb(252, 211, 5)" }}
          />
          &nbsp; View Important Disclosures
        </Link>
        <br />
        <i className="fa fa-arrow-circle-left" />
      </>
    );

    const paramsMobile = {
      width: 256,
      collapsible: false,
      collapsed: false,
      onCollapse: this.onCollapse,
      trigger,
    };

    const paramsDesktop = {
      width: 256,
      collapsible: true,
      collapsed: collapsed,
      onCollapse: this.onCollapse,
      breakpoint: "lg",
      trigger,
    };

    const params = isMobile ? paramsMobile : paramsDesktop;
    const menuStyle =
      product === "live"
        ? "menuLeft__navigationBrokerage"
        : "menuLeft__navigationPaper";
    const menuClassName = `menuLeft product-${product}`;
    const menuLogo = "/resources/images/logo.svg";
    const menuLogoCollapsed = "/resources/images/logo-collapsed.svg";

    const { account } = this.props;
    const liveActs = [];
    const paperActs = [account.paper?.current?.split("-")[0].toUpperCase()];
    const accountLabel = product === "live" ? "Live Trading" : "Paper Trading";
    const isOnboarding = isPaperOrOnboarding(account);
    const AlpacaSecurities = `Alpaca Securities`;
    const currentYear = new Date().getFullYear();

    if (account.account?.account_number && !isOnboarding)
      liveActs.push(account.account?.account_number);

    return (
      <Sider
        {...params}
        className={menuClassName}
        onBreakpoint={(broken) => {
          // if not broken and collapsed is true, then toggle
          if (!broken && collapsed) {
            this.onCollapse();
          }
        }}
      >
        <div className="menuLeft__logo">
          <div className="menuLeft__logoContainer menuLeft__logoContainer--collapsed">
            <img src={menuLogoCollapsed} alt="" />
          </div>
        </div>
        <Select
          value={accountLabel}
          className="account-select"
          size="large"
          onChange={(v) => this.props.history.push(`/${v}/dashboard/overview`)}
        >
          <OptGroup label="Live Accounts">
            {liveActs.map((act) =>
              act ? (
                <Option
                  key={act}
                  value="brokerage"
                  className={
                    accountLabel === "Live Trading"
                      ? "act-select-act"
                      : "act-select"
                  }
                >
                  {act}
                </Option>
              ) : null
            )}
          </OptGroup>
          <OptGroup label="Paper Accounts">
            {paperActs?.map((act) =>
              act ? (
                <Option
                  key={act}
                  value="paper"
                  className={
                    accountLabel === "Paper Trading"
                      ? "act-select-act"
                      : "act-select"
                  }
                >
                  {act}
                </Option>
              ) : null
            )}
          </OptGroup>
        </Select>
        <Menu
          theme={theme}
          onClick={this.handleClick}
          selectedKeys={[selectedKeys]}
          openKeys={openKeys}
          onOpenChange={this.onOpenChange}
          mode="inline"
          className={menuStyle}
        >
          {isOnboarding && product === "paper" && !isBrowser && (
            <Menu.Item>
              <Link to="/brokerage/new-account" className="mobile-open-live">
                <span className="fa fa-bolt menuLeft__icon" />
                Open Live Trading Account
              </Link>
            </Menu.Item>
          )}
          {menuItems}
          {!params.collapsed && <Menu.Item></Menu.Item>}
        </Menu>

        <Modal
          width={800}
          footer={null}
          bordered={false}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
        >
          <Card title="Disclosures">
            Brokerage services are provided by {AlpacaSecurities}, member{" "}
            <Link href="https://www.finra.org/" isExternal fontWeight="bold">
              FINRA
            </Link>
            /
            <Link href="https://www.sipc.org/" isExternal fontWeight="bold">
              SIPC
            </Link>
            , a wholly-owned subsidiary of AlpacaDB, Inc. Technology and
            services are offered by AlpacaDB, Inc.
            <br />
            <br />
            Brokerage services are provided to customers who can write automated
            investment code and self-direct their own investments. Alpaca
            brokerage services are only provided to customers who agree to
            electronically sign agreements and agree to receive messages,
            confirmations, and statements electronically.{" "}
            <Link
              href="https://alpaca.markets/docs/about-us/#who-is-alpaca-for"
              rel="noopener noreferrer"
              fontWeight="bold"
            >
              Is Alpaca right for me?
            </Link>
            <br />
            <br />
            This is not an offer, solicitation of an offer, or advice to buy or
            sell securities or cryptocurrencies, or open a brokerage account or
            cryptocurrency account in any jurisdiction where Alpaca Securities,
            Alpaca Crypto, are not registered or licensed, as applicable.
            <br />
            <br />
            The Paper Trading API is offered by AlpacaDB, Inc. and does not
            require real money or permit a user to transact in real securities
            in the market. Providing use of the Paper Trading API is not an
            offer or solicitation to buy or sell securities, securities
            derivative or futures products of any kind, or any type of trading
            or investment advice, recommendation or strategy, given or in any
            manner endorsed by AlpacaDB, Inc. or any AlpacaDB, Inc. affiliate
            and the information made available through the Paper Trading API is
            not an offer or solicitation of any kind in any jurisdiction where
            AlpacaDB, Inc. or any AlpacaDB, Inc. affiliate (collectively,
            &#34;Alpaca&#34;) is not authorized to do business.
            <br />
            <br />
            You should know that the use or granting of any third party access
            to your account information or place transactions in your account at
            your direction is solely at your risk. Alpaca does not warrant
            against loss of use or any direct, indirect or consequential damages
            or losses to you caused by your assent, expressed or implied, to a
            third party accessing your account or information, including access
            provided through any other third party apps, systems, or sites.
            <br />
            <br />
            Market prices, data and other information available through Alpaca
            are not warranted as to completeness or accuracy and are subject to
            change without notice.
            <em>
              System response and account access times may vary due to a variety
              of factors, including trading volumes, market conditions, system
              performance, and other factors.
            </em>
            A more complete description of the impact these factors may have can
            be found in our{" "}
            <Link
              href="https://alpaca.markets/learn/risks-of-automated-trading-systems/"
              isExternal
              fontWeight="bold"
            >
              risks of automated trading systems section
            </Link>
            .
            <br />
            <br />
            All investments involve risk and the past performance of a security,
            or financial product does not guarantee future results or returns.
            Keep in mind that while diversification may help spread risk it does
            not assure a profit, or protect against loss, in a down market.
            There is always the potential of losing money when you invest in
            securities, or other financial products. Investors should consider
            their investment objectives and risks carefully before investing.
            <br />
            <br />
            There are risks unique to automated trading algorithms that you
            should know about and plan for. You should setup a method or system
            of continuous monitoring or alerting to let you know if there is a
            mechanical failure, such as connectivity issues, power loss, a
            computer crash, or system quirk. You should also monitor for
            instances where your automated trading system experiences anomalies
            that could result in errant, missing, or duplicated orders. A more
            complete description of these and other risks can be found in our{" "}
            <Link
              href="https://alpaca.markets/learn/risks-of-automated-trading-systems/"
              isExternal
              fontWeight="bold"
            >
              FAQ section.
            </Link>
            <br />
            <br />
            Conditional orders may have increased risk as a result of their
            reliance on trigger processing, market data, and other internal and
            external systems. Such orders are not sent to the market until
            specified conditions are met. During that time, issues such as
            system outages with downstream technologies or third parties may
            occur. Conditional orders triggering near the market close may fail
            to execute that day. Furthermore, our executing partner may impose
            controls on conditional orders to limit erroneous trades triggering
            downstream orders. Alpaca Securities may not always be made aware of
            such changes to external controls immediately, which may lead to
            some conditional orders not being executed. As such, it is important
            to monitor conditional orders for reasonability. Conditional orders
            are “Not Held” orders whose execution instructions are on a best
            efforts basis upon being triggered. Furthermore, conditional orders
            may be subject to the increased risks of stop orders and market
            orders outlined above. Given the increased potential risk of using
            conditional orders, the client agrees that Alpaca Securities cannot
            be held responsible for losses, damages, or missed opportunity costs
            associated with market data problems, systems issues, and user
            error, among other factors. By using conditional orders the client
            understands and accepts the risks outlined above. Alpaca Securities
            encourages leveraging the use of Paper accounts to become more
            comfortable with the intricacies associated with these orders.
            <br />
            <br />
            ETFs can entail risks similar to direct stock ownership, including
            market, sector, or industry risks. Some ETFs may involve
            international risk, currency risk, commodity risk, and interest rate
            risk. Trading prices may not reflect the net asset value of the
            underlying securities.
            <br />
            <br />
            All accounts are opened as limited purpose margin accounts. You
            should know that margin trading involves interest charges and risks,
            including the potential to lose more than deposited or the need to
            deposit additional collateral in a falling market. Before using
            margin, customers must determine whether this type of trading
            strategy is right for them given their specific investment
            objectives, experience, risk tolerance, and financial situation. For
            more information please see{" "}
            <Link
              href="https://files.alpaca.markets/disclosures/library/MarginDiscStmt.pdf"
              isExternal
              fontWeight="bold"
            >
              Alpaca&#39;s Margin Disclosure Statement
            </Link>{" "}
            and{" "}
            <Link
              href="https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf"
              isExternal
              fontWeight="bold"
            >
              Margin Agreement.
            </Link>
            These disclosures contain information on Alpaca Securities&#39;s
            lending policies, interest charges, and the risks associated with
            margin accounts.
            <br />
            <br />
            Commission-Free trading means that there are no commission charges
            for Alpaca Securities self-directed individual brokerage accounts
            that trade U.S. listed securities through an API.{" "}
            <Link
              href="https://files.alpaca.markets/disclosures/library/BrokFeeSched.pdf"
              isExternal
              fontWeight="bold"
            >
              Relevant SEC and FINRA fees may apply.
            </Link>
            <br />
            <br />
            Cryptocurrency is highly speculative in nature, involves a high
            degree of risks, such as volatile market price swings, market
            manipulation, flash crashes, and cybersecurity risks. Cryptocurrency
            is not regulated or is lightly regulated in most countries.
            Cryptocurrency trading can lead to large, immediate and permanent
            loss of financial value. You should have appropriate knowledge and
            experience before engaging in cryptocurrency trading. For additional
            information please click{" "}
            <Link
              href="https://files.alpaca.markets/disclosures/library/CryptoRiskDisclosures.pdf"
              isExternal
              fontWeight="bold"
            >
              here
            </Link>
            .
            <br />
            <br />
            Cryptocurrency services are made available by Alpaca Crypto, a
            FinCEN registered money services business (NMLS # 2160858), and a
            wholly-owned subsidiary of AlpacaDB, Inc. Alpaca Crypto is not a
            member of SIPC or FINRA. Cryptocurrencies are not stocks and your
            cryptocurrency investments are not protected by either FDIC or SIPC.
            Depending on your location. Please see the{" "}
            <Link
              href="https://alpaca.markets/disclosures"
              isExternal
              fontWeight="bold"
            >
              Disclosure Library
            </Link>{" "}
            for more information.
            <br />
            <br />
            &copy; {currentYear} Alpaca Securities LLC All rights reserved.
            <br />
            &copy; {currentYear} Alpaca Crypto LLC All rights reserved.
            <br />
            &copy; {currentYear} AlpacaDB, Inc All rights reserved.
          </Card>
        </Modal>
      </Sider>
    );
  }
}

export { MenuLeft, menuData };
