import { getIpAddress, IpAddressResponse } from "../rest/ip";

import { useState } from "react";
import { useQuery } from "react-query";

export const useGetIpAddress = () => {
  const [ipAddress, setIpAddress] = useState<string>("");

  useQuery("ip-address", getIpAddress, {
    cacheTime: 0,
    onSuccess: (data) => setIpAddress(data.ip_address),
  });

  return {
    ipAddress,
  };
};

export default useGetIpAddress;
