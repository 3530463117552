import loadable from "@loadable/component";
import classNames from "classnames";
import LayoutState from "components/LayoutComponents/LayoutState";
import Menu from "components/LayoutComponents/Menu";
import TopBar from "components/LayoutComponents/TopBar";
import LoadingSpinner from "components/LoadingSpinner";
import PropTypes from "prop-types";
import React from "react";
import Page from "src/v2/components/common/page";
import App from "src/v2/pages";

import { Layout as AntLayout } from "antd";
import { enquireScreen } from "enquire-js";
import { ContainerQuery } from "react-container-query";
import { MFAGuard } from "../../../MFAGuard";
import { useFlag } from "../../../v2/helpers/flags";

const AntContent = AntLayout.Content;
const AntHeader = AntLayout.Header;

const query = {
  "screen-xs": {
    maxWidth: 575,
  },
  "screen-sm": {
    minWidth: 576,
    maxWidth: 767,
  },
  "screen-md": {
    minWidth: 768,
    maxWidth: 991,
  },
  "screen-lg": {
    minWidth: 992,
    maxWidth: 1199,
  },
  "screen-xl": {
    minWidth: 1200,
    maxWidth: 1599,
  },
  "screen-xxl": {
    minWidth: 1600,
  },
};

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class Layout extends React.Component {
  static defaultProps = {
    disableAppMenu: false,
    headerContent: null,
    noHeader: false,
    showBrandInHeader: false,
    showSearchInHeader: true,
    product: "alpaca",
  };

  static propTypes = {
    disableAppMenu: PropTypes.bool,
    headerContent: PropTypes.any,
    noHeader: PropTypes.bool,
    showBrandInHeader: PropTypes.bool,
    showSearchInHeader: PropTypes.bool,
    product: PropTypes.string,
  };

  child = null;
  importer = null;

  state = {
    isMobile,
  };

  loadComponent = () => {
    const { importer, product } = this.props;

    const LoadableComponent = loadable(() => importer({ product }), {
      fallback: <LoadingSpinner />,
    });

    this.setState({ LoadableChild: LoadableComponent });
  };

  componentDidMount() {
    this.enquireHandler = enquireScreen((mobile) => {
      this.setState({
        isMobile: mobile,
      });
    });

    if (this.props.importer) {
      this.loadComponent();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.importer !== prevProps.importer) {
      this.loadComponent();
    }
  }

  render() {
    const { LoadableChild } = this.state;
    const { product } = this.props;

    if (useFlag("uix-v2")) {
      // only save non-dynamic product state to local storage
      if (product !== "dynamic") {
        window.localStorage.setItem("product", product);
      }

      // set product from local storage to mimic a persistent state
      if ((this.props = { ...this.props }).product === "dynamic") {
        this.props.product = window.localStorage.getItem("product") || "paper";
      }

      const { disableAppMenu, headerContent, noHeader, importer, ...more } =
        this.props;

      this.importer = importer;
      this.child = LoadableChild;
      return (
        <App>
          <Page
            product={product}
            noHeader={noHeader}
            disableAppMenu={disableAppMenu}
          >
            <MFAGuard>
              {LoadableChild && <LoadableChild {...this.props} />}
              {this.props.children}
            </MFAGuard>
          </Page>
        </App>
      );
    }

    // only save non-dynamic product state to local storage
    if (product !== "dynamic") {
      window.localStorage.setItem("product", product);
    }
    // set product from local storage to mimic a persistent state
    if ((this.props = { ...this.props }).product === "dynamic") {
      this.props.product = window.localStorage.getItem("product") || "paper";
    }

    const isMobile = !!this.state.isMobile;
    const { disableAppMenu, headerContent, importer, ...more } = this.props;

    // Accept a loadable component using the `importer` prop.
    // Normal composition follows with `this.props.children`. See for example NotFoundPage.
    // We are imitating useMemo to preserve loadable between updates if we are
    // given the same importer
    this.importer = importer;
    this.child = LoadableChild;
    return (
      <ContainerQuery query={query}>
        {(params) => (
          <div className={classNames(params)}>
            <AntLayout {...more}>
              <LayoutState />
              {!disableAppMenu && <Menu {...this.props} isMobile={isMobile} />}
              <AntLayout>
                {!this.props.noHeader && (
                  <AntHeader>
                    {headerContent ? headerContent : <TopBar {...this.props} />}
                  </AntHeader>
                )}
                <AntContent style={{ height: "100%", padding: "2rem" }}>
                  <MFAGuard>
                    {LoadableChild && <LoadableChild {...this.props} />}
                    {this.props.children}
                  </MFAGuard>
                </AntContent>
              </AntLayout>
            </AntLayout>
          </div>
        )}
      </ContainerQuery>
    );
  }
}

export default Layout;
