// basically fuck chakra's color mode provider
import React, { useCallback, useEffect } from "react";

// switch color mode state ref based on local storage
export const useDarkMode = () => {
  const [darkMode, setDarkMode] = React.useState<boolean>(
    // JSON.parse(localStorage.getItem("dark-mode") || "false")
    false
  );

  /** 
  
  useEffect(() => {
    const tick = setInterval(
      () =>
        setDarkModeValue(
          JSON.parse(localStorage.getItem("dark-mode") || "false")
        ),
      250
    );

    return () => clearInterval(tick);
  }, []);
**/

  const useDarkModeValue = useCallback((a: any, b: any) => (darkMode ? a : b), [
    darkMode,
  ]);

  const setDarkModeValue = (newValue: boolean) => {
    // before we set the dark mode state, we need to set the local storage
    // localStorage.setItem("dark-mode", `${newValue}`);
    // update state
    // setDarkMode(newValue);
  };

  return {
    darkMode,
    useDarkModeValue,
    setDarkMode: setDarkModeValue,
  };
};
