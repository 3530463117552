import useRequest, { QueryOptions } from "./useRequest";

import { useState } from "react";
import { QueryObserverResult } from "react-query";
import { Account, getAccount } from "../rest/account";
import { useAccountContext } from "src/v2/providers/AccountProvider";

type UseGetAccount = {
  account?: Account;
  isAuthenticated: boolean | undefined;
  refetch: () => Promise<QueryObserverResult<Account>>;
};

/**
 * AccountProvider is the source of truth for which account is currently displayed. We use
 * the product argument to determine which account to return, with live returning the one
 * liveAccount, and paper returning the currentAccount as chosen in AccountProvider
 */
export const useGetAccount = (
  key: any | any[] = "",
  // todo: revisit this type, we need to include product for all account-dynamic hooks
  options?: QueryOptions<Account> & { product?: string }
): UseGetAccount => {
  const [isAuthenticated, setAuthed] = useState<boolean | undefined>();

  const {
    currentAccount,
    liveAccounts,
    refetchLiveAccounts,
    refetchPaperAccounts,
  } = useAccountContext();

  const {
    data: account,
    isError,
    isSuccess,
    refetch,
  } = useRequest(
    ["account", key, options?.product],
    () => getAccount({ product: options?.product || "live" }),
    {
      ...options,
      refetchInterval: 10000,
      enabled:
        options?.enabled !== undefined
          ? options?.enabled === true
          : options?.product !== "paper" && !currentAccount,
      select: (data) => {
        // does not rely on onSuccess and onError being called
        if (!!data && !isAuthenticated) {
          setAuthed(true);
        }

        return data;
      },
      onSuccess: (data) => {
        setAuthed(true);
        options?.onSuccess?.(data);
      },
      onError: () => {
        setAuthed(false);
        options?.onError?.({} as any);
      },
    }
  );

  const handleRefetch = () => {
    refetchLiveAccounts();
    refetchPaperAccounts();
    return refetch();
  };

  if (isError || (isSuccess && !account?.id)) {
    // redirect should occur at router level
    // AuthService.signOut();
    // history.push("/login");
  }

  return {
    account:
      options?.product === "paper" && !!currentAccount
        ? currentAccount
        : liveAccounts?.[0] ?? account,
    refetch: handleRefetch,
    isAuthenticated,
  };
};
