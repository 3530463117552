import useRequest, { QueryOptions } from "./useRequest";

import { QueryObserverResult } from "react-query";
import { AchRelationship, getAchRelationships } from "../rest/banking";

type UseGetAchRelationships = {
  achRelationships?: AchRelationship[];
  refetch: () => Promise<QueryObserverResult<AchRelationship[], unknown>>;
};

export const useGetAchRelationships = (
  accountId?: string,
  key: any | any[] = "",
  options?: QueryOptions<AchRelationship[]>
): UseGetAchRelationships => {
  const { data: achRelationships, refetch } = useRequest(
    "ach-relationships" + key,
    () => getAchRelationships(accountId || ""),
    {
      ...options,
    }
  );

  return { achRelationships, refetch };
};
