import React from "react";

const Markdown = ({ source, className, style, onScroll }) => {
  return (
    <div
      id="foo"
      onScroll={onScroll}
      style={style}
      className={className}
      dangerouslySetInnerHTML={{ __html: source }}
    />
  );
};

export default Markdown;
