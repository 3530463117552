import {
  getCurrencyCloudAccount,
  GetCurrencyCloudAccountResponse,
  getCurrencyCloudBeneficiaryRequiredDetails,
  GetCurrencyCloudBeneficiaryResponse,
  getCurrencyCloudStatus,
  GetCurrencyCloudStatusResponse,
} from "../rest/currencyCloud";
import useRequest, { QueryOptions } from "./useRequest";

type UseGetCurrencyCloudAccountProps = {
  accountID: string;
  key?: any | any[];
  options?: QueryOptions<GetCurrencyCloudAccountResponse>;
};

export const useGetCurrencyCloudAccount = ({
  accountID,
  key = "cc-account",
  options,
}: UseGetCurrencyCloudAccountProps) => {
  const cc = useRequest(
    [key, accountID],
    () => getCurrencyCloudAccount(accountID),
    {
      ...options,
    }
  );

  return cc;
};

type UseGetCurrencyCloudBeneficiaryProps = {
  accountID: string;
  key?: any | any[];
  options?: QueryOptions<GetCurrencyCloudBeneficiaryResponse>;
};

export const useGetCurrencyCloudBeneficiaryRequiredDetails = ({
  accountID,
  key = "cc-account-required-details",
  options,
}: UseGetCurrencyCloudBeneficiaryProps) => {
  const ccBeneficiaryRequiredDetails = useRequest(
    [key, accountID],
    () => getCurrencyCloudBeneficiaryRequiredDetails(accountID),
    {
      ...options,
    }
  );

  return ccBeneficiaryRequiredDetails;
};

type UseGetCurrencyCloudStatusProps = {
  accountID: string;
  key?: any | any[];
  options?: QueryOptions<GetCurrencyCloudStatusResponse>;
};

export const useGetCurrencyCloudStatus = ({
  accountID,
  key = "cc-status",
  options,
}: UseGetCurrencyCloudStatusProps) => {
  const ccStatus = useRequest(
    [key, accountID],
    () => getCurrencyCloudStatus(accountID),
    {
      ...options,
    }
  );

  return ccStatus;
};
