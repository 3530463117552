import { createAction, createReducer } from "redux-act";
import api from "api";
import "fast-text-encoding";
import { getAccountIdForProduct } from "selectors";
import { updateIntercom } from "src/utils/intercom";

const REDUCER = "profitloss";
const NS = `@@${REDUCER}/`;

const _setProfitLoss = createAction(`${NS}SET_PROFIT_LOSS`);

/**
 * loadProfitLoss
 */
export const loadProfitLoss = ({ product = "paper" }) => (
  dispatch,
  getState
) => {
  const ownerId = getState().account?.details?.owner_id || false;
  const accountId = getAccountIdForProduct(getState(), product);
  const endpoint = product === "paper" ? "paperProfitloss" : "profitloss";

  if (accountId) {
    return dispatch(api[endpoint].get({ accountId })).then((profitloss) =>
      dispatch(_setProfitLoss({ accountId, product, ownerId, profitloss }))
    );
  }
  return Promise.resolve();
};

const initialState = {};
export default createReducer(
  {
    [_setProfitLoss]: (state, { accountId, product, ownerId, profitloss }) => {
      if (!profitloss) {
        return state;
      }

      const newState = { ...state };
      const previousRetrieval = newState[accountId]?.previousRetrieval;

      if (!previousRetrieval) {
        // For now, only one live trading account
        if (product === "live" && ownerId) {
          updateIntercom(ownerId, {
            profitLossDay: profitloss.day_plpc,
            profitLossTotal: profitloss.total_plpc,
          });
        }
      }

      newState[accountId] = profitloss;
      newState[accountId].previousRetrieval = true;
      return newState;
    },
  },
  initialState
);
