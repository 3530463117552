import { createReducer } from "redux-act";

import { eraseCookie } from "lib/cookies";
import { eventTracker } from "src/utils/eventTracker";

export const REDUCER = "signup";

export const accountCreated = (response) => () => {
  eraseCookie("alpaca_ref_by");
  let referralSucceededOrUndefined = response["referral_succeeded"];
  if (referralSucceededOrUndefined !== undefined) {
    const referredBy = response["referred_by"];
    if (referralSucceededOrUndefined && referredBy) {
      eventTracker("Referral Succeeded", {
        category: "AccountCreation",
        referredBy: `${referredBy}`,
      });
    } else {
      const referralErrorMessage = response["referral_error"];
      eventTracker("Referral Failed", {
        category: "AccountCreation",
        error: referralErrorMessage,
      });
    }
  } else {
    eventTracker("Referral Unknown Status", {
      category: "AccountCreation",
    });
  }
  eventTracker("Account Creation Succeeded", {
    category: "AccountCreation",
  });
};

export const accountCreationFailed = (err) => () => {
  eventTracker(`Account Creation Failed: ${err.message}`, {
    category: "AccountCreation",
  });
};

const initialState = {};
export default createReducer({}, initialState);
