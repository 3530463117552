import instance from "..";
import moment from "moment";
import { EntityProfile } from "../../pages/dashboard/entityOnboarding/pages/entityProfile/schema";
import { AccountOpener } from "../../pages/dashboard/entityOnboarding/pages/accountOpener/schema";
import {
  Transform,
  transformObject,
} from "../../pages/dashboard/entityOnboarding/util";
import {
  AuthorizedIndividual,
  UltimateBeneficialOwner,
} from "../../pages/dashboard/entityOnboarding/pages/authIndividualsUBOs/schema";
import { Owner } from "./account";

export const toEntityAccount = async (accountId: string): Promise<void> => {
  const res = await instance.patch(`/accounts/${accountId}/to-entity-account`);
  return res.data;
};

export const toIndividualAccount = async (accountId: string): Promise<void> => {
  const res = await instance.patch(
    `/accounts/${accountId}/to-individual-account`
  );
  return res.data;
};

export type PostOwnerArgs =
  | { type: "legal-entity"; accountId: string; data: EntityProfile }
  | { type: "account-opener"; accountId: string; data: AccountOpener }
  | {
      type: "authorized-individual";
      accountId: string;
      data: AuthorizedIndividual;
    }
  | { type: "ubo"; accountId: string; data: UltimateBeneficialOwner };

export type OwnerType = PostOwnerArgs["type"];

export type PatchOwnerArgs =
  | {
      type: "legal-entity";
      accountId: string;
      ownerId: string;
      data: EntityProfile;
    }
  | {
      type: "account-opener";
      accountId: string;
      ownerId: string;
      data: AccountOpener;
    }
  | {
      type: "authorized-individual";
      accountId: string;
      ownerId: string;
      data: AuthorizedIndividual;
    }
  | {
      type: "ubo";
      accountId: string;
      ownerId: string;
      data: UltimateBeneficialOwner;
    };

const getOwnerTransforms = (
  type: PostOwnerArgs["type"]
): Transform<{ [key: string]: any }>[] => {
  if (type === "legal-entity") {
    return [
      {
        keys: ["funding_source"],
        condition: (value) => typeof value === "string",
        transform: (value) => value.split(","),
      },
      {
        keys: ["date_of_incorporation"],
        condition: (value) => moment.isDate(value),
        transform: (value) => moment(value).format("YYYY-MM-DD"),
      },
      {
        keys: "ALL_FIELDS",
        condition: (value) => value === "",
        transform: () => undefined,
      },
    ];
  }

  return [
    {
      keys: ["date_of_birth"],
      condition: (value) => moment.isDate(value),
      transform: (value) => moment(value).format("YYYY-MM-DD"),
    },
    {
      keys: ["controlling_firms"],
      condition: (value) => typeof value === "string",
      transform: (value) => value.split(","),
    },
    {
      keys: "ALL_FIELDS",
      condition: (value) => value === "",
      transform: () => undefined,
    },
  ];
};

export const postOwner = async ({ type, accountId, data }: PostOwnerArgs) => {
  const transforms = getOwnerTransforms(type);
  const res = await instance.post<Owner>(`/accounts/${accountId}/owners`, {
    ...transformObject(data, transforms),
  });
  return res.data;
};

export const patchOwner = async ({
  type,
  accountId,
  ownerId,
  data,
}: PatchOwnerArgs) => {
  const transforms = getOwnerTransforms(type);
  const res = await instance.patch<Owner>(
    `/accounts/${accountId}/owners/${ownerId}`,
    {
      ...transformObject(data, transforms),
    }
  );
  return res.data;
};

export const deleteOwner = async ({
  accountId,
  ownerId,
}: {
  accountId: string;
  ownerId: string;
}) => {
  const res = await instance.delete(`/accounts/${accountId}/owners/${ownerId}`);
  return res.data;
};

export type OwnerDocument = {
  id: string;
  owner_id: string;
  doc_type: string;
  doc_sub_type: string;
};

export const getOwnerDocuments = async (accountId: string, ownerId: string) => {
  const res = await instance.get<{ documents: OwnerDocument[] }>(
    `/accounts/${accountId}/owners/${ownerId}/documents`
  );
  return res.data.documents;
};

export const getOwnerDocumentURL = async (
  accountId: string,
  ownerId: string,
  documentId: string
) => {
  const res = await instance.get(
    `/accounts/${accountId}/owners/${ownerId}/documents/${documentId}/url`
  );
  return res.data;
};

export const postFileUpload = async ({
  accountId,
  ownerId,
  docType,
  docSubType,
  file,
}: {
  accountId: string;
  ownerId: string;
  docType: string;
  docSubType?: string;
  file: File;
}) => {
  const form = new FormData();
  form.append("uploadfile", file);
  const res = await instance.post<{ id: string; object_key: string }>(
    `/accounts/${accountId}/owners/${ownerId}/documents/${docType}${
      docSubType ? `/${docSubType}` : ""
    }`,
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.data;
};

export const deleteFileUpload = async ({
  accountId,
  ownerId,
  documentId,
}: {
  accountId: string;
  ownerId: string;
  documentId: string;
}) => {
  const res = await instance.delete(
    `/accounts/${accountId}/owners/${ownerId}/documents/${documentId}`
  );
  return res.data;
};

export const uploadOwnerDocument = async ({
  accountId,
  docType,
  docSubType,
  file,
}: {
  accountId: string;
  docType: string;
  docSubType?: string;
  file: File;
}) => {
  const form = new FormData();
  form.append("uploadfile", file);
  const res = await instance.post(
    `/documents/accounts/${accountId}/upload/${docType}${
      docSubType ? `/${docSubType}` : ""
    }`,
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.data;
};

export const getTaxFileForm = async ({
  accountId,
  ownerId,
  form,
}: {
  accountId: string;
  ownerId: string;
  form: "certifications_and_resolutions_statement" | "w_8ben";
}): Promise<Blob> => {
  const res = await instance.get(
    `/accounts/${accountId}/owners/${ownerId}/taxfiles/${form}/preview`,
    { responseType: "blob" }
  );
  return res.data;
};

export const acceptTaxFileForm = async ({
  accountId,
  ownerId,
  form,
}: {
  accountId: string;
  ownerId: string;
  form: "certifications_and_resolutions_statement" | "w_8ben";
}) => {
  const res = await instance.post(
    `/accounts/${accountId}/owners/${ownerId}/taxfiles/${form}/accept`
  );
  return res.data;
};

export const submitEntityAccount = async (accountId: string) => {
  const res = await instance.post(`/accounts/${accountId}/submit`);
  return res.data;
};

export const postAgreement = async ({
  accountId,
  ownerId,
  agreement,
  revision,
}: {
  accountId: string;
  ownerId: string;
  agreement: string;
  revision: string;
}) => {
  const res = await instance.post(
    `/accounts/${accountId}/owners/${ownerId}/agreements/${agreement}/${revision}`
  );
  return res.data;
};
