import useRequest, { QueryOptions } from "./useRequest";

import { getAccessKeys, AccessKey } from "../rest/account";

export const useGetAccessKeys = (
  options?: QueryOptions<AccessKey[]> & { accountID?: string }
) => {
  const { data, ...rest } = useRequest(
    ["access-keys", options?.accountID],
    () => getAccessKeys({ accountID: options?.accountID }),
    {
      ...options,
      cacheTime: 1000 * 60 * 5,
    }
  );

  return { access_keys: data || [], ...rest };
};

export default useGetAccessKeys;
