type ClearCookiesOptions = {
  ignore?: string[];
  ignoreStartsWith?: string[];
};

export const clearCookies = ({ ignore = [], ignoreStartsWith = [] }: ClearCookiesOptions = {}) =>
  document.cookie
    .split(";")
    .filter((cookie) => !ignore.includes(cookie.split("=")[0]))
    .filter((cookie) => ignoreStartsWith.some((prefix) => cookie.split("=")[0].startsWith(prefix)))
    .forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
