import { createAction, createReducer } from "redux-act";
import api from "api";
import { handleSessionExpire } from "./alpacaAuth/session";
import { getAccountIdAndJWT, getAccountIdForProduct } from "selectors";
import { eventTracker } from "src/utils/eventTracker";

// access keys
const _setAccessKeys = createAction(`SET_ACCESS_KEYS`);
// call when creating new paper account because an access key for the old paper account
// would still be in state
export const unsetAccessKeys = createAction(`UNSET_ACCESS_KEYS`);

/**
 * listAccessKeys will retrieve all API access keys for a user
 */
export const listAccessKeys = () => (dispatch, getState) => {
  const { token } = getAccountIdAndJWT(getState());

  const accountId = getAccountIdForProduct(getState(), "live");
  const paperAccountId = getAccountIdForProduct(getState(), "paper");

  // If you don't get this reference, figure it out immediately.
  const nakedEyes = [];
  if (accountId) {
    nakedEyes.push(
      handleSessionExpire(
        api.accessKeys
          .list(accountId, token)
          .then((payload) =>
            dispatch(_setAccessKeys({ accessKeys: payload, product: "live" }))
          )
          .catch(console.error),
        dispatch
      )
    );
  }

  if (paperAccountId) {
    nakedEyes.push(
      handleSessionExpire(
        api.paperAccessKeys
          .list(paperAccountId, token)
          .then((payload) =>
            dispatch(_setAccessKeys({ accessKeys: payload, product: "paper" }))
          )
          .catch(console.error),
        dispatch
      )
    );
  }

  return Promise.all(nakedEyes);
};

/**
 * createAccessKey will re/create an API access key for an account
 *
 * @param {String} product      The product (paper or live)
 */
export const createAccessKey = (product) => (dispatch, getState) => {
  const { token } = getAccountIdAndJWT(getState());

  const accountId = getAccountIdForProduct(getState(), "live");
  const paperAccountId = getAccountIdForProduct(getState(), "paper");

  if (product) {
    eventTracker("Created Access Key", {
      category: "API",
      label: product,
    });

    if (product === "paper") {
      // Intercom can not use event labels like GA and other things can.
      // So it needs its own special event in order to build e-mail campaign rules.
      eventTracker("Created Paper Access Key", {
        category: "API",
      });
      return handleSessionExpire(
        api.paperAccessKeys.create(paperAccountId, token),
        dispatch
      );
    }
    if (product === "live") {
      // Intercom can not use event labels like GA and other things can.
      // So it needs its own special event in order to build e-mail campaign rules.
      eventTracker("Created Live Access Key", {
        category: "API",
      });
      return handleSessionExpire(
        api.accessKeys.create(accountId, token),
        dispatch
      );
    }
  }
  return;
};

/**
 * deleteAccessKey will delete an API key
 */
export const deleteAccessKey = (key) => (dispatch, getState) => {
  const { token } = getAccountIdAndJWT(getState());

  // Removing based on product. Paper/live, more in the future.
  if (key && key.product && key.id) {
    eventTracker("Deleted Access Key", {
      category: "API",
      label: key.product,
    });

    if (key.product === "live") {
      return handleSessionExpire(
        // NOTE: key.account_id could be either the paper account id or the live trade account id
        // Each product can theoretically have multiple accounts
        api.accessKeys.delete(key.account_id, key.id, token),
        dispatch
      );
    }
    if (key.product === "paper") {
      return handleSessionExpire(
        api.paperAccessKeys.delete(key.account_id, key.id, token),
        dispatch
      );
    }
  }
  return;
};

// Export this reducer
const initialState = {};
export default createReducer(
  {
    // Account access keys, these can be added to or updated
    [_setAccessKeys]: (state, payload) => {
      // Likely only one key at a time. When we regenerate we delete the old.
      // However, if not, this can certainly expose an issue.
      const currentKeys = state.accessKeys || {};
      if (payload.product && payload.accessKeys) {
        payload.accessKeys.forEach((key) => {
          currentKeys[key.account_id] = { ...key, product: payload.product };
        });
      }
      return { ...state, ...currentKeys };
    },
    [unsetAccessKeys]: () => {
      return {};
    },
  },
  initialState
);
