import Spinner from "../../../../../components/common/spinner";

import React, { useContext, useEffect, useMemo, useState } from "react";

import {
  getCashTransformer,
  getISO3166Alpha3FormatMapping,
  getPrettyCash,
} from "../../../../../utils/formatting";

import {
  createBank,
  deleteBank,
  getBanks,
  uploadBankStatement,
} from "../../../../../api/rest/account";

import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Select,
  Switch,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";

import { DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import iso3166 from "iso-3166-2";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { RiCloseCircleLine } from "react-icons/ri";
import { Field } from "src/v2/components/form";
import { BankingContext, TransferDirection } from "../../";
import { getCashWithdrawable } from "../../../../../../pages/Dashboard/Product/BrokerageAccount/Banking/BankingContainer/CurrencyCloud/constants";
import { useMutationRequest, useRequest } from "../../../../../api/hooks";
import { createAchTransfer } from "../../../../../api/rest/banking";

const Deposit = () => {
  const { account } = useContext(BankingContext);

  const fields = useMemo(
    () => ({
      "Bank Name": "BMO HARRIS BANK NA",
      "Bank Address": "111 W. Monroe Street Chicago, IL 60603",
      "Account Name": "Alpaca Securities LLC",
      "ABA Routing Number": "071 000 288",
      "Swift Code": "HATRUS44",
      "Account Number": "1636877",
      "Special Instructions": (
        <>
          Please include the following text:
          <br />
          FFC LPCA-{account?.account_number}
        </>
      ),
    }),
    [account]
  );

  return (
    <>
      <Text fontSize="xl" fontWeight="600" mb="2.8rem">
        Via Wire Transfer
      </Text>
      <Flex
        bg="gray.20"
        rounded="md"
        fontSize="md"
        padding="1rem"
        overflowX="scroll"
        whiteSpace="nowrap"
        justifyContent="space-between"
      >
        <Box mr="1rem">
          {Object.keys(fields).map((key, index) => {
            const last = index === Object.keys(fields).length - 1;
            return (
              <Text
                w="35%"
                fontWeight="500"
                key={index}
                mb={last ? "0" : ".8rem"}
              >
                {key}
              </Text>
            );
          })}
        </Box>
        <Box>
          {Object.values(fields).map((value, index) => {
            const last = index === Object.values(fields).length - 1;
            return (
              <Text mb={last ? "0" : ".8rem"} key={index}>
                {value}
              </Text>
            );
          })}
        </Box>
      </Flex>
      <Text mt="2rem">
        <ul className="list-disc ml-4">
          <li>
            Wire transfers may take 2-3 business days. Your bank may charge you
            for outgoing wires.
          </li>
        </ul>
      </Text>
      <Text my="1rem" color="red.70" className="font-semibold">
        Make sure you fill in your account number in the special instructions
        field.
      </Text>
      <Text>
        Please contact{" "}
        <Link
          color="black"
          fontWeight="500"
          textDecoration="underline"
          href="mailto:support@alpaca.markets"
        >
          support@alpaca.markets
        </Link>{" "}
        if you do not see your wire deposit in your account after: 2 business
        days for Domestic (US) wires or 5 business days for SWIFT
        (international) wires.
      </Text>
    </>
  );
};

interface Fields {
  amount: string;
  name: string;
  account_number: string;
  bank_code: string;
  bank_code_type: "ABA" | "BIC";
  bank_statement: File;
  country: string;
  state_province: string;
  postal_code: string;
  city: string;
  street_address: string;
  additional_information: string;
}

const Withdrawal = () => {
  const toast = useToast();
  const ctx = useContext(BankingContext);

  const [submitCheck, setSubmitCheck] = useState(false);
  const [withdrawable, setWithdrawable] = useState(0);
  const [international, setInternational] = React.useState(false);

  const {
    control,
    register,
    watch,
    reset,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Fields>();

  const { data: banks, refetch } = useRequest(
    "banks",
    () => getBanks({ accountID: ctx.account?.id || "" }),
    {
      staleTime: 10000,
      refetchInterval: 10000,
    }
  );

  const dropzone = useDropzone({
    maxFiles: 1,
    maxSize: 20000000,
    onDrop: (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        return;
      }

      setValue("bank_statement", acceptedFiles[0]);
    },
    onDropRejected: (fileRejection) => {
      if (
        fileRejection.length > 0 &&
        fileRejection[0]?.errors[0]?.code === "file-too-large"
      ) {
        setError("bank_statement", { message: "File must be less than 20MB" });
      }
    },
  });

  const bank = banks?.[0];
  const isDeposit = ctx.transferDirection === TransferDirection.INCOMING;
  const amountString = watch("amount");
  const amount = amountString
    ? Number(amountString.replace(/[^0-9.]/g, ""))
    : 0;
  const withdrawableString = getPrettyCash(withdrawable, { acceptZero: true });
  const isWithdrawalInvalid = !isDeposit && amount > 0 && amount > withdrawable;
  const isConfirmDisabled = isDeposit
    ? amount <= 0
    : amount <= 0 || withdrawable < amount;
  const bankStatement = watch("bank_statement");

  const infos = useMemo(
    () => [
      {
        imgSrc: "/resources/images/bank.svg",
        label: bank && bank?.name,
      },
      {
        imgSrc: "/resources/images/logo-collapsed.svg",
        label: `Alpaca Account ${ctx.account?.account_number}`,
      },
    ],
    [bank]
  );

  const { mutate: createTransfer } = useMutationRequest(
    () =>
      createAchTransfer(ctx.account?.id || "", {
        amount: String(amount),
        bank_id: bank?.id || "",
        direction: isDeposit ? "INCOMING" : "OUTGOING",
        transfer_type: "wire",
      }),
    {
      onSuccess: () => {
        // we do this in-case they revisit the modal quickly
        refetch();

        // refetch
        ctx.refetch();
        ctx.onBeforeClose();
        ctx.setTransferType(null);

        // let them know it worked
        toast({
          duration: 5000,
          isClosable: true,
          title: "Success",
          status: "success",
          description: `Your ${
            isDeposit ? "deposit" : "withdrawal"
          } has been initiated.`,
        });
      },
    }
  );

  const { mutate: deleteRelationship } = useMutationRequest(
    () =>
      deleteBank({
        bankID: bank?.id || "",
        accountID: ctx.account?.id || "",
      }),
    {
      onSuccess: () => {
        // we do this in-case they revisit the modal quickly
        refetch();

        // clear states
        ctx.setTransferType(null);
        ctx.setNextStep(false);

        // let them know it worked
        toast({
          duration: 3000,
          isClosable: true,
          status: "success",
          title: "Bank removed",
        });
      },
    }
  );

  const { mutate: createBankMutation } = useMutationRequest(
    (values: any) =>
      Promise.all([
        uploadBankStatement({
          accountID: ctx.account?.id || "",
          file: values.bank_statement,
        }),
        createBank({
          accountID: ctx.account?.id || "",
          bank: { ...values, bank_statement: undefined },
        }),
      ]),
    {
      onSuccess: () => {
        // we do this in-case they revisit the modal quickly
        refetch();

        // let them know it worked
        toast({
          duration: 3000,
          isClosable: true,
          status: "success",
          title: "Bank added",
        });
      },
    }
  );

  const handleRemoveBankStatement = (
    evt: React.MouseEvent<HTMLButtonElement>
  ) => {
    evt.stopPropagation();
    const values = { ...getValues(), bank_statement: undefined };
    reset(values);
  };

  // fetch and set withdrawable amount
  useEffect(() => {
    getCashWithdrawable(ctx.account?.id, (value: string) =>
      setWithdrawable(Number(value))
    );
  }, [ctx.account]);

  // set id if no bank exists
  useEffect(() => {
    if (!bank) {
      ctx.setWizardFormId("form--wire-withdrawal");
    } else {
      // null out the form id
      ctx.setWizardFormId(null);
    }
  }, [bank]);

  const RemoveRelIcon = () => (
    <Flex alignItems="center">
      <Icon
        cursor="pointer"
        fontSize="xl"
        color="gray.50"
        as={RiCloseCircleLine}
        onClick={() => deleteRelationship({})}
      />
    </Flex>
  );

  const countryList = useMemo(() => {
    const iso3166Alpha3FormatMapping = getISO3166Alpha3FormatMapping();
    return Object.keys(iso3166.data)
      .map((code) => {
        return {
          key: iso3166Alpha3FormatMapping[code],
          label: iso3166.data[code].name,
        };
      })
      .filter((value) => value.key !== "USA")
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }, []);

  if (!banks) {
    return <Spinner />;
  }

  return bank ? (
    <>
      <Text fontSize="xl" fontWeight="600" mb="2.8rem">
        Enter an amount
      </Text>
      <FormControl isInvalid={isWithdrawalInvalid}>
        <InputGroup>
          <Controller
            name="amount"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                p="sm"
                fontSize="xl"
                fontWeight="500"
                variant="skeleton"
                //@ts-ignore
                {...getCashTransformer({ field })}
              />
            )}
          />
          <InputRightElement
            pr="1rem"
            width="unset"
            children={
              <Text whiteSpace="nowrap" fontWeight="500" color="gray.50">
                {withdrawableString}
                &nbsp;available to withdraw
              </Text>
            }
          />
        </InputGroup>
        <FormErrorMessage px="1rem">
          {isWithdrawalInvalid &&
            `Cannot withdraw more than ${withdrawableString}. Some deposits take up to
          7 days to settle, after which they will be made available for
          withdrawal`}
        </FormErrorMessage>
      </FormControl>
      <Flex mb="1.4rem" mt="1rem" fontWeight="500">
        {["$500", "$1,000", "$10,000"].map((amount, index) => (
          <Box
            p=".4rem .6rem"
            cursor="pointer"
            borderRadius=".4rem"
            border="1px solid var(--chakra-colors-gray-20)"
            mr={index === 2 ? "0" : "1rem"}
            onClick={() => {
              setValue("amount", amount);
            }}
          >
            {amount}
          </Box>
        ))}
      </Flex>
      <Box>
        <Text fontWeight="500" fontSize="md" mb="1.4rem">
          From:
        </Text>
        <Flex
          rounded="md"
          p=".4rem 1rem"
          alignItems="center"
          justifyContent="start"
          border="1px solid var(--chakra-colors-gray-20)"
        >
          <Image
            boxSize="24px"
            my=".5rem"
            src={infos[isDeposit ? 0 : 1].imgSrc}
          />
          <Text fontWeight="500" fontSize="md" ml="1rem">
            {infos[isDeposit ? 0 : 1].label}
          </Text>
          <Box ml="auto">{isDeposit && <RemoveRelIcon />}</Box>
        </Flex>
      </Box>
      <Box mt="1.4rem">
        <Text fontWeight="500" fontSize="md" mb="1.4rem">
          To:
        </Text>
        <Flex
          rounded="md"
          p=".4rem 1rem"
          alignItems="center"
          justifyContent="start"
          border="1px solid var(--chakra-colors-gray-20)"
        >
          <Image
            my=".5rem"
            boxSize="24px"
            src={infos[isDeposit ? 1 : 0].imgSrc}
          />
          <Text fontWeight="500" fontSize="md" ml="1rem">
            {infos[isDeposit ? 1 : 0].label}
          </Text>
          <Box ml="auto">{!isDeposit && <RemoveRelIcon />}</Box>
        </Flex>
      </Box>
      <Box my="1.4rem">
        {submitCheck ? (
          <VStack>
            <Text mb="0.5rem" fontWeight="500">{`Initiate ${
              isDeposit ? "deposit" : "withdrawal"
            } of ${amount}?`}</Text>
            <Box width="100%" justifyContent="space-between" display="flex">
              <Button onClick={() => createTransfer({})} flexGrow={1} mr="1rem">
                Yes
              </Button>
              <Button
                flexGrow={1}
                variant="skeleton"
                onClick={() => setSubmitCheck(false)}
              >
                Cancel
              </Button>
            </Box>
          </VStack>
        ) : (
          <Button
            w="100%"
            onClick={() => setSubmitCheck(true)}
            disabled={isConfirmDisabled}
          >
            Confirm
          </Button>
        )}
      </Box>
      <p>
        Note: You will not be able to withdraw newly deposited money for 6
        business days. Alpaca partners with BMO Harris Bank to process ACH
        transfers.
      </p>
    </>
  ) : (
    <form
      id="form--wire-withdrawal"
      onSubmit={handleSubmit((values) =>
        createBankMutation({
          ...values,
          bank_code_type: international ? "BIC" : "ABA",
        })
      )}
    >
      <Text fontSize="xl" fontWeight="600" mb="2.8rem">
        Via Wire Transfer
      </Text>
      <FormControl mb="1rem">
        <Field label="US bank vs International bank">
          <Switch
            id="international"
            colorScheme="yellow"
            onChange={() => setInternational(!international)}
            isChecked={international}
          />
        </Field>
      </FormControl>
      <FormControl mb="1rem">
        <Field
          label="Bank Name"
          error={!!errors.name}
          errorMsg={errors.name?.message}
          required={true}
        >
          <Input
            variant="filled"
            placeholder="Example Bank"
            {...register("name", {
              required: "Required",
            })}
          />
        </Field>
      </FormControl>
      <FormControl mb="1rem">
        <Field
          label="Account Number"
          error={!!errors.account_number}
          errorMsg={errors.account_number?.message}
          required={true}
        >
          <Input
            variant="filled"
            placeholder="123456789"
            {...register("account_number", {
              required: "Required",
            })}
          />
        </Field>
      </FormControl>
      <FormControl mb="1rem">
        <Field
          label={!international ? "Routing Number" : "SWIFT/BIC Code"}
          error={!!errors.bank_code}
          errorMsg={errors.bank_code?.message}
          required={true}
        >
          <Input
            variant="filled"
            placeholder="EXAMPLE1234"
            {...register("bank_code", {
              required: "Required",
            })}
          />
        </Field>
      </FormControl>
      <FormControl mb="1rem">
        <Field
          label={"Bank Statement"}
          error={!!errors.bank_statement}
          errorMsg={errors.bank_statement?.message}
          required={true}
        >
          <Flex
            {...dropzone.getRootProps()}
            padding="1.25em"
            cursor="pointer"
            _hover={{ backgroundColor: "light.300" }}
            justifyContent="center"
            alignItems="center"
            border="2px dashed"
            rounded="md"
            borderColor="light.900"
            transition="background-color 0.25s ease"
            my="1rem"
          >
            <input
              placeholder="Bank Statement"
              {...register("bank_statement", {
                required: "Required",
              })}
              {...dropzone.getInputProps()}
            />
            {bankStatement?.name ? (
              <IconButton
                aria-label={`Remove document ${bankStatement?.name}`}
                icon={<DeleteIcon />}
                onClick={handleRemoveBankStatement}
                position="relative"
                zIndex={1}
                mx="1em"
              />
            ) : (
              <Icon
                as={DownloadIcon}
                size="md"
                color={"light.100"}
                variant="ghost"
                aria-label="upload-document"
                margin="0.5em"
              />
            )}
            <Center color={"light.100"} fontWeight="medium">
              {bankStatement?.name ||
                "Drag and drop here, or click to select file."}
            </Center>
          </Flex>
        </Field>
      </FormControl>
      {international && (
        <>
          <FormControl mb="1rem">
            <Field
              label="Country"
              error={!!errors.country}
              errorMsg={errors.country?.message}
              required={true}
            >
              <Select
                maxW="fit-content"
                {...register("country", {
                  required: "Required",
                })}
              >
                {countryList.map(({ key, label }) => (
                  <option value={key}>{label}</option>
                ))}
              </Select>
            </Field>
          </FormControl>
          <FormControl mb="1rem">
            <Field
              label="Street Address"
              error={!!errors.street_address}
              errorMsg={errors.street_address?.message}
              required={true}
            >
              <Input
                variant="filled"
                placeholder="123 Main St"
                {...register("street_address", {
                  required: "Required",
                })}
              />
            </Field>
          </FormControl>
          <Flex mb="1rem">
            <FormControl>
              <Field
                label="City"
                error={!!errors.city}
                errorMsg={errors.city?.message}
                required={true}
              >
                <Input
                  variant="filled"
                  placeholder="San Francisco"
                  {...register("city", {
                    required: "Required",
                  })}
                />
              </Field>
            </FormControl>
            <FormControl mx="1rem">
              <Field
                label="State"
                error={!!errors.state_province}
                errorMsg={errors.state_province?.message}
                required={true}
              >
                <Input
                  variant="filled"
                  placeholder="CA"
                  {...register("state_province", {
                    required: "Required",
                  })}
                />
              </Field>
            </FormControl>
            <FormControl>
              <Field
                label="Postal Code"
                error={!!errors.postal_code}
                errorMsg={errors.postal_code?.message}
                required={true}
              >
                <Input
                  variant="filled"
                  placeholder="94105"
                  {...register("postal_code", {
                    required: "Required",
                  })}
                />
              </Field>
            </FormControl>
          </Flex>
          <FormControl>
            <Field
              label="Additional Information"
              error={!!errors.additional_information}
              errorMsg={errors.additional_information?.message}
            >
              <Input
                variant="filled"
                placeholder="Enter more information here"
                {...register("additional_information")}
              />
            </Field>
          </FormControl>
        </>
      )}
      {international && (
        <Box
          mt="2rem"
          bg="gray.20"
          rounded="md"
          fontSize="md"
          padding="1rem"
          maxHeight="300px"
          overflowY="scroll"
        >
          <Flex flexDir="column">
            <Text mb="1rem">
              Funds have to be received and sent in USD. We do not convert funds
              or reimburse for any conversion that may inadvertently happen.
            </Text>
            <Text mb="1rem">
              Alpaca does not accept wire transfers from, or issue wire
              transfers to, third parties. As such, the name and address on the
              account of both the initiator and recipient of wire transfers
              coming to or being sent from Alpaca must be the same.
            </Text>
            <Text mb="1rem">
              Most international banks use an intermediary/correspondent. On
              outgoing wires, we need info on both the
              intermediary/correspondent bank as well as the FFC to the
              customer’s bank and their account there. We usually use SWIFT and
              not IBAN on outgoing wires.
            </Text>
            <Text mb="1rem">
              Neither the intermediary nor the customer bank should be on our
              restricted country list.
            </Text>
            <Text mb="1rem">
              International wires can take up to 7 days for receipt/delivery
              depending on the intermediary bank used by the customer’s bank.
            </Text>
            <Text>
              The most common reasons for delays or issues with international
              wires are due to:
              <Flex flexDir="column" mt="1rem" ml="1rem">
                <Text>Restricted countries</Text>
                <Text my=".5rem">
                  Translation differences in names or addresses
                </Text>
                <Text>
                  Missing data such as names, addresses, account numbers due to
                  the format used by the customer’s bank or the intermediary
                  bank
                </Text>
              </Flex>
            </Text>
          </Flex>
          <Box mt="1rem">
            <Text mb="1rem">
              If you have a bank account within the United States:
            </Text>
            <Text>
              You can deposit and withdraw using ACH by linking your bank in the
              United States.
            </Text>
          </Box>
        </Box>
      )}
    </form>
  );
};

export const Wire = () =>
  useContext(BankingContext).wireDirection === TransferDirection.INCOMING ? (
    <Deposit />
  ) : (
    <Withdrawal />
  );

export default Wire;
