import instance from "..";
import { EliteStatus } from "./account";

export const getEliteNetDeposits = async (
  accountID: string
): Promise<string> => {
  const res = await instance.get<string>(
    `/accounts/${accountID}/elite/net_deposit`
  );

  return res.data;
};

export type AlgoRouting = {
  model: AlgoRoutingModel;
};

export const getEliteAlgoRouting = async (
  accountID: string
): Promise<AlgoRouting> => {
  const res = await instance.get<AlgoRouting>(
    `/accounts/${accountID}/elite/algo_routing`
  );
  return res.data;
};

export type AlgoRoutingModel = "retail" | "cost_plus" | "all_in";

export const applyEliteAlgoRouting = async (
  accountID: string,
  model: AlgoRoutingModel
): Promise<void> =>
  await instance.post(`/accounts/${accountID}/elite/algo_routing/apply`, {
    model,
  });

export type ElitePerks = {
  elite_status: EliteStatus;
  router: AlgoRouting;
  market_data: boolean;
};

export const getElitePerks = async (accountID: string): Promise<ElitePerks> => {
  const res = await instance.get(`/accounts/${accountID}/elite/perks`);
  return res.data;
};

export const applyEliteMarketData = async (accountID: string): Promise<any> => {
  const res = await instance.post(
    `/accounts/${accountID}/elite/market_data/apply`
  );
  return res.data;
};
