import ReactGA from "react-ga4";

const trackingID = window.env.GOOGLE_ANALYTICS_ID;

if (trackingID) {
  ReactGA.initialize(trackingID);
}

export const eventTracker = (action, data) => {
  if (trackingID && data) {
    ReactGA.event({
      action: action,
      category: data.category,
      label: data.label,
      value: data.value,
    });
  }
};
