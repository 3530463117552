import React from "react";

export default class MenuLabel extends React.Component {
  static defaultProps = {
    product: "paper",
    label: "",
  };

  render() {
    const variableLabels = {};
    const { label, product } = this.props;
    // Only gets a label if looking at live brokerage view
    variableLabels.accountType =
      product === "live" ? "Brokerage" : "Paper Trading";

    let interpolatedLabel = null;

    if (label && label.match("{")) {
      const labelKey = label.replace("{", "").replace("}", "");
      interpolatedLabel = variableLabels[labelKey] || null;
    } else {
      interpolatedLabel = label;
    }

    return interpolatedLabel ? (
      <li className="ant-menu-item-label">
        <small>{interpolatedLabel}</small>
      </li>
    ) : null;
  }
}
