import useRequest, { QueryOptions } from "./useRequest";

import { getStatus, Status } from "../rest/status";

export const useGetStatus = (
  key: any | any[] = "",
  options?: QueryOptions<Status>
) => {
  const { data: status } = useRequest(key, () => getStatus(), {
    ...options,
  });

  return { status };
};

export default useGetStatus;
