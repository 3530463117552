import React, { useEffect } from "react";

import { Button, Form, useModal, useUIForm } from "@alpacahq/ui";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutationRequest } from "src/v2/api/hooks";
import { createPaperAccount, PaperAccount } from "src/v2/api/rest/account";
import { useToast } from "@chakra-ui/react";
import { useAccountContext } from "src/v2/providers/AccountProvider";
import { getPrettyCash } from "src/v2/utils/formatting";

export interface CreatePaperAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  ownerId?: string;
}

const MIN_FUNDS = 1;
const MAX_FUNDS = 1000000;

const schema = z.object({
  cash: z.coerce.number().gte(MIN_FUNDS).lte(MAX_FUNDS),
  name: z.string(),
});

type CreatePaperAccountModalForm = z.infer<typeof schema>;

export const CreatePaperAccountModal: React.FC<
  CreatePaperAccountModalProps
> = ({ isOpen, onClose, ownerId }) => {
  const toast = useToast();

  const { refetchPaperAccounts } = useAccountContext();

  const createPaperAccountMutation = useMutationRequest<
    PaperAccount,
    CreatePaperAccountModalForm
  >((formValues) => createPaperAccount(formValues), {
    onSuccess: () => {
      toast({
        title: "Success",
        description: "You have successfully created a new account",
        status: "success",
      });

      refetchPaperAccounts();
      onClose();
    },
  });

  const form = useUIForm<CreatePaperAccountModalForm>({
    resolver: zodResolver(schema),
  });

  const handleSubmit = (formValues: CreatePaperAccountModalForm) => {
    createPaperAccountMutation.mutate(formValues);
  };

  const {
    component: Modal,
    isOpen: isModalOpen,
    toggle,
  } = useModal({
    title: "Open New Paper Account",
    content: (
      <div className="space-y-2">
        <div>
          Create a new paper account to simulate trades and test strategies. You
          can have up to 3 paper accounts.
        </div>
        <Form formMethods={form} onSubmit={handleSubmit}>
          <Form.TextInput label="Nickname" name="name" />

          <Form.TextInput
            type="number"
            label="Set Funds"
            name="cash"
            placeholder={`${getPrettyCash(MIN_FUNDS)} - ${getPrettyCash(
              MAX_FUNDS
            )}`}
          />
        </Form>
      </div>
    ),
    controls: (
      <div className="flex space-x-2">
        <Button variant="alternate" onClick={() => toggle()}>
          Close
        </Button>
        <Button onClick={form.handleSubmit(handleSubmit)}>Submit</Button>
      </div>
    ),
    onClose: () => {
      if (isModalOpen) {
        onClose();
      }
    },
  });

  useEffect(() => {
    if (isOpen && !isModalOpen) {
      toggle();
    }
  }, [isOpen, isModalOpen, toggle]);

  return <>{Modal}</>;
};
export default CreatePaperAccountModal;
