import useRequest, { QueryOptions } from "./useRequest";
import { Billing, getBillingOverview } from "../rest/billing";
import { QueryObserverResult } from "react-query";

type UseGetBillingOverview = {
  billing?: Billing;
  refetch: () => Promise<QueryObserverResult<Billing, unknown>>;
};

export const useGetBillingOverview = (
  key: any | any[] = "",
  options?: QueryOptions<Billing>
): UseGetBillingOverview => {
  const { data: billing, isError, isSuccess, refetch } = useRequest(
    "billing" + key,
    getBillingOverview,
    { refetchInterval: false, ...options }
  );

  return { billing, refetch };
};
