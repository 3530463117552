export type IpAddressResponse = {
  ip_address: string;
};

export const getIpAddress = async () => {
  const response = await fetch("https://alpaca.markets/_ip_address");
  const data = await response.json();

  return data;
};
