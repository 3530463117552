import useRequest, { QueryOptions } from "./useRequest";

import { AlgoRouting, getEliteAlgoRouting } from "../rest/elite";

export const useGetEliteAlgoRouting = (
  key: any | any[] = "elite-net-deposit",
  accountID: string,
  options?: QueryOptions<AlgoRouting>
) => {
  const { data } = useRequest(key, () => getEliteAlgoRouting(accountID), {
    ...options,
  });

  return { ...data };
};

export default useGetEliteAlgoRouting;
