import { colors } from "@alpacahq/sassy";
import { extendTheme } from "@chakra-ui/react";

export const TOAST_THEME = {
  style: {
    background: "#ffffff",
    border: "1px solid #E4E4E7",
    borderRadius: "6px",
    padding: "16px",
    paddingTop: "12px",
    paddingBottom: "12px",
    gap: "5px",
    boxShadow: "none",
    maxWidth: "500px",
  },
};

export const customTheme = (mode: any) =>
  extendTheme({
    styles: {
      global: () => ({
        html: {
          fontFamily: "Inter var, sans-serif",
          background: "#ffffff",
          color: "black",
          fontSize: "14px",
          lineHeight: "16px",
          textRendering: "unset",
          WebkitFontSmoothing: "unset",
        },
        body: {
          fontFamily: "Inter var, sans-serif",
          background: "#ffffff",
          color: "black",
          fontSize: "14px",
          lineHeight: "16px",
        },
        div: {
          borderColor: mode(
            "var(--chakra-colors-gray-90) !important",
            "var(--chakra-colors-gray-20)"
          ),
        },
        hr: {
          opacity: "1 !important",
          borderColor: mode(
            "var(--chakra-colors-gray-90) !important",
            "var(--chakra-colors-gray-15) !important"
          ),
        },
        p: {
          color: mode("gray.10", "gray.100"),
        },

        h2: {
          color: mode("gray.10", "gray.100"),
          borderColor: mode(
            "var(--chakra-colors-gray-90) !important",
            "var(--chakra-colors-gray-20)"
          ),
        },
        ".chakra-menu__menuitem": {
          color: mode("gray.10", "black !important"),
          fontWeight: 400,
          _focus: {
            background: "var(--chakra-colors-gray-15) !important",
          },
        },
      }),
    },
    fonts: {
      heading: "Inter var, sans-serif",
      body: "Inter var, sans-serif",
      mono: "Roboto Mono, monospace",
    },
    space: {
      xxs: "0.215rem",
      xs: "0.43rem",
      sm: "0.96rem",
      md: "1.6rem",
      lg: "3.2rem",
      xl: "6.4rem",
    },
    fontSizes: {
      xs: ".86rem",
      sm: ".96rem",
      md: "0.98rem",
      lg: "1.125rem",
      xl: "1.250rem",
      "2xl": "1.5rem",
      "3xl": "1.875rem",
      "4xl": "2.25rem",
      "5xl": "2.86rem",
      "6xl": "3.75rem",
      "7xl": "4.5rem",
      "8xl": "6rem",
      "9xl": "8rem",
    },
    colors: {
      ...colors,
      pastel: {
        green: "#5DAF90",
        red: "#e65555",
      },
      black: "#010101",
      gray: {
        ...colors.gray,
        90: "#181818",
        20: "#e4e4e4",
        15: "#f0f0f0",
        10: "#f7f7f7",
      },
    },
    components: {
      Radio: {
        baseStyle: {
          control: {
            borderColor: "gray.20",
            _checked: {
              bg: "yellow.60",
              color: "gray.10",
              borderColor: "yellow.60",
              _hover: {
                bg: "yellow.60",
                color: "gray.10",
                borderColor: "yellow.60",
              },
            },
            _focus: {
              boxShadow: "none !important",
            },
          },
          label: {
            fontSize: "1rem !important",
            fontFamily: "Inter var, sans-serif",
          },
        },
      },
      Icon: {
        baseStyle: (props: any) => ({
          color: mode("gray.10 !important", "gray.100"),
        }),
      },
      Text: {
        baseStyle: (props: any) => ({
          color: mode("gray.10 !important", "gray.100"),
          fontFamily: "Inter var, sans-serif !important",
        }),
      },
      Checkbox: {
        baseStyle: {
          label: {
            fontFamily: "Inter var, sans-serif !important",
          },
          control: {
            _checked: {
              color: "yellow.80",
              background: "yellow.50",
              borderColor: "yellow.50",
              _hover: {
                background: "yellow.50",
                borderColor: "yellow.50",
              },
            },
          },
        },
      },
      Link: {
        baseStyle: {
          fontSize: "1rem",
          color: "#191919",
          textDecoration: "none",
          fontFamily: "Inter var, sans-serif !important",
        },
      },
      Switch: {
        baseStyle: {
          track: {
            bg: "gray.40",
            _checked: {
              bg: "yellow.50",
            },
          },
        },
      },
      Input: {
        baseStyle: {
          fontFamily: "Inter var, sans-serif !important",
        },
        defaultProps: {
          variant: "filled",
        },
        variants: {
          flushed: {
            field: {
              color: "gray.100",
              borderBottom: "1px solid var(--chakra-colors-gray-20) !important",
              boxShadow: "none !important",
              _focused: {
                borderBottom:
                  "1px solid var(--chakra-colors-gray-20) !important",
                boxShadow: "none !important",
              },
              _placeholder: {
                color: "var(--chakra-colors-gray-60) !important",
                opacity: "1 !important",
              },
            },
          },
          skeleton: {
            field: {
              color: "gray.100",
              border: "2px solid var(--chakra-colors-gray-15) !important",
              boxShadow: "none !important",
              _focused: {
                border: "2px solid var(--chakra-colors-gray-20) !important",
                boxShadow: "none !important",
              },
              _placeholder: {
                color: "var(--chakra-colors-gray-60) !important",
                opacity: "1 !important",
              },
            },
          },
          filled2: {
            field: {
              color: "gray.100",
              border: "1px solid transparent",
              background: "gray.15",
              _placeholder: {
                color: "var(--chakra-colors-gray-60) !important",
                opacity: "1 !important",
              },
              _focus: {
                border: "1px solid gray.10",
                color: mode("gray.10", "gray.100"),
              },
            },
          },

          filled: {
            field: {
              background: mode("#29292c", "gray.20"),
              color: "gray.100",
              border: "1px solid transparent",
              _placeholder: {
                color: "var(--chakra-colors-gray-60) !important",
                opacity: "1 !important",
              },
              _hover: {
                background: "gray.20",
                color: "gray.100",
              },
              _focus: {
                shadow: "sm",
                background: mode("#000", "gray.20"),
                borderColor: "transparent",
                color: mode("gray.10", "gray.100"),
              },
            },
          },
        },
      },
      Select: {
        defaultProps: {
          variant: "skeleton",
        },
        variants: {
          lightGray: {
            field: {
              fontWeight: 400,
              color: "black",
              borderRadius: "md",
              background: "gray.20",
              fontSize: ".9rem",
              border: "none",
              _hover: {
                boxShadow: "0 3px 6px -2px rgba(140,152,164,.25)",
              },
              _focus: {
                boxShadow: "0 3px 6px -2px rgba(140,152,164,.25)",
              },
            },
          },
          yellow: {
            field: {
              fontWeight: 500,
              color: "black",
              borderRadius: "md",
              background: "yellow.20",
              fontSize: ".9rem",
              border: "1px solid var(--chakra-colors-yellow-40)",
              _hover: {
                boxShadow: "0 3px 6px -2px rgba(140,152,164,.25)",
              },
              _focus: {
                boxShadow: "0 3px 6px -2px rgba(140,152,164,.25)",
              },
            },
          },
          skeleton: {
            field: {
              fontWeight: 500,
              color: "black",
              borderRadius: "md",
              fontSize: ".9rem",
              border: "1px solid var(--chakra-colors-gray-20)",
              _hover: {},
              _focus: {},
            },
          },
          skeleton__light_gray: {
            field: {
              background: "gray.20",
              color: "black",
              borderRadius: "md",
              fontSize: "xs",
              fontWeight: 500,
              border: "1px solid transparent !important",
              _hover: {},
              _focus: {},
            },
          },
          solid: {
            field: {
              border: "none",
              background: "gray.15",
              fontWeight: 400,
              color: "gray.100",
              _placeholder: { color: "gray.50", opacity: 1 },
              _hover: { backgroundColor: "gray.15", color: "auto" },
              _focus: { backgroundColor: "gray.15", color: "auto" },
            },
          },
        },
      },
      Button: {
        defaultProps: {
          variant: "primary",
        },
        variants: {
          skeleton: {
            fontWeight: 500,
            color: "black",
            borderRadius: "md",
            fontSize: ".9rem",
            minWidth: "fit-content",
            border: "2px solid var(--chakra-colors-gray-20)",
            _hover: {
              border: "2px solid var(--chakra-colors-gray-30)",
            },
          },
          primary: {
            bg: "yellow.40",
            fontWeight: 500,
            color: "yellow.100",
            fontSize: ".9rem",
            borderRadius: "md",
            _disabled: {
              _hover: {
                background: "var(--chakra-colors-yellow-40) !important",
              },
            },
            _hover: {
              bg: "yellow.40",
            },
          },
          red: {
            bg: "red.60",
            fontWeight: "500",
            color: "black",
            borderRadius: "md",
            fontSize: ".9rem",
            _disabled: {
              _hover: {
                background: "red.60 !important",
              },
            },
            _hover: {
              bg: "red.60",
            },
          },
        },
        sizes: { lg: { height: "46px !important" } },
      },
    },
  });
