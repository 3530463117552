import useRequest, { QueryOptions } from "./useRequest";
import { getWatchlist, Watchlist } from "../rest/account";

export const useGetWatchlist = (
  key: any | any[] = "",
  accountID: string = "",
  name: string = "Primary Watchlist",
  options?: QueryOptions<Watchlist>
) => {
  const { data: watchlist, refetch, isFetched } = useRequest(
    key,
    () => getWatchlist({ accountID, name }),
    { ...options, refetchInterval: false }
  );

  return { watchlist, refetch, isFetched };
};

export default useGetWatchlist;
