import { useRequest } from ".";
import { getCryptoSnapshots } from "../rest/data";

export const useGetCryptoSnapshots = (symbols: (string | any)[]) => {
  const { data } = useRequest(
    [symbols, "snapshots"],
    () => getCryptoSnapshots({ symbols }),
    {
      enabled: !!symbols.length,
      refetchInterval: 10000,
      staleTime: 10000,
    }
  );

  return { ...data?.snapshots };
};

export default useGetCryptoSnapshots;
