const initialState = {};

const dataAgreementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "app/RECEIVE_DATA_AGREEMENT":
      return {
        ...state,
        [action.payload.name]: {
          blob: action.payload.agreement,
        },
      };
    default:
      return state;
  }
};

export default dataAgreementReducer;
