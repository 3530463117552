import React, { ReactNode } from "react";

import { Alert, Text, classes } from "@alpacahq/ui";

// this is a legacy react feature; hasn't been brought to hook form yet
class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    errorMessage: "",
  };

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      errorMessage: error.message,
    };
  }

  componentDidCatch(error: any, info: any) {
    console.error("ErrorBoundary caught an error", error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Alert
            variant="danger"
            title="Something went wrong."
            message={`Please refresh the page and try again or contact support. (error: ${this.state.errorMessage})`}
          />
        </>
      );
    }

    return this.props.children;
  }
}

export interface PageProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  title?: ReactNode;
  subtitle?: ReactNode;
  breadcrumbs?: ReactNode;
}

export const Page: React.FC<PageProps> = ({
  className,
  title,
  subtitle,
  breadcrumbs,
  children,
  ...rest
}) => (
  <div
    className={classes(
      "xs:pl-0 mx-auto w-full flex-1 space-y-8 py-8 pr-8 pl-8",
      className
    )}
    {...rest}
  >
    <ErrorBoundary>
      {(title || subtitle || breadcrumbs) && (
        <div className="space-y-2">
          {title && (
            <Text className="text-3xl font-semibold text-gray-900">
              {title}
            </Text>
          )}
          {subtitle && (
            <Text className="text-xl text-gray-500">{subtitle}</Text>
          )}
          {breadcrumbs}
        </div>
      )}
      {children}
    </ErrorBoundary>
  </div>
);

export default Page;
