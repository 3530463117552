const TOTAL_TIME_KEY = "entity_onboarding_timer";

export const timer = {
  start: (key = TOTAL_TIME_KEY) => {
    window?.localStorage?.setItem(key, Date.now().toString());
  },
  end: (key = TOTAL_TIME_KEY) => {
    const start = window?.localStorage?.getItem(key);

    if (!start) {
      return;
    }

    const now = Date.now();
    const duration = now - parseInt(start, 10);

    return duration;
  },
  remove: (key = TOTAL_TIME_KEY) => {
    window?.localStorage?.removeItem(key);
  },
  isSet: (key = TOTAL_TIME_KEY) => {
    return window?.localStorage?.getItem(key) !== null;
  },
};
