import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Select } from "antd";
import debounce from "lodash/debounce";
import api from "api";
import { GlobalHotKeys } from "react-hotkeys";
import { eventTracker } from "src/utils/eventTracker";

const { Option } = Select;

const SearchInput = ({ product, placeholder }) => {
  const [state, setState] = useState({
    assets: [],
    query: null,
    fetching: false,
  });
  const { assets, fetching, query } = state;
  const lastFetchId = useRef(0);
  const dispatch = useDispatch();

  const onBlur = () => {
    setState({ query: null, assets: [], fetching: false });

    eventTracker(`Search Dismissed ${query}`, {
      category: "Search",
    });
  };
  const onSelect = (value) => {
    dispatch(
      push((product === "live" ? "/brokerage" : "/paper") + "/stocks/" + value)
    );
  };
  const onSearch = debounce((query) => {
    lastFetchId.current += 1;
    const fetchId = lastFetchId.current;

    if (!query) {
      setState({ ...state, assets: [], fetching: false });
      return;
    }

    setState({ query, fetching: true, assets });
    dispatch(api.assets.search({}, { query }))
      .then((assets) => {
        if (fetchId !== lastFetchId.current) {
          return;
        }
        setState({ ...state, assets, fetching: false });
      })
      .catch(() => {
        if (fetchId !== lastFetchId) {
          return;
        }
        setState({ ...state, assets: [], fetching: false });
      });
  }, 400);
  const selectRef = useRef(null);

  return (
    <div className="d-inline-block">
      <GlobalHotKeys
        keyMap={{ search: ["/", "s"] }}
        handlers={{
          search: (event) => {
            event.preventDefault();
            if (selectRef.current) {
              selectRef.current.focus();
            }
          },
        }}
      />
      <Select
        ref={selectRef}
        showSearch
        loading={fetching}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        showAction={["focus", "click"]}
        showArrow={false}
        defaultOpen={false}
        filterOption={false}
        onSearch={onSearch}
        onSelect={onSelect}
        onBlur={onBlur}
        notFoundContent={null}
        size="large"
        className="searchbar"
      >
        {assets.map((asset) => (
          <Option key={asset.symbol}>{asset.symbol}</Option>
        ))}
      </Select>
    </div>
  );
};

export default SearchInput;
