import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { Subject } from "rxjs";

import { history } from "utils";
import createRootReducer from "reducers/index";

export const actions = new Subject();

const dynamicMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  actions.next([action, store.getState()]);
  return result;
};

const router = routerMiddleware(history);
const middlewares = [dynamicMiddleware, router, thunk];

const isLogger = false;

if (isLogger && process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}

export const store = createStore(
  createRootReducer(history),
  composeWithDevTools(applyMiddleware(...middlewares))
);
