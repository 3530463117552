import * as Sentry from "@sentry/browser";
import * as SecondaryIntegrations from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

export function init() {
  Sentry.init({
    dsn: window.env.SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    environment: window.env.ENV,
    integrations: [
      new Integrations.BrowserTracing(),
      new SecondaryIntegrations.Dedupe(),
      new SecondaryIntegrations.ExtraErrorData(),
    ],
    ignoreErrors: [
      // network failure
      "Failed to fetch",
      /Loading chunk [\d]+ failed/,
      /Loading CSS chunk [\d]+ failed/,
      /NetworkError when attempting to fetch resource/,
      // elm
      "Cannot read property 'childNodes' of undefined",
      'Can\'t access property "childNodes"',
      ".replaceData is not a function",
    ],
  });
}
