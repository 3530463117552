import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as cognitoActions from "reducers/auth/cognito";
import * as accountListActions from "reducers/auth/accountList";
import { getCookie } from "src/lib/cookies";
import api from "./api";
import { initAmplify } from "./reducers/auth/common";

const fireLinkedInPixel = () => {
  const partnerId = window._linkedin_partner_id;
  const element = document.createElement("img");
  element.alt = "";
  element.height = 1;
  element.width = 1;
  element.src = `https://px.ads.linkedin.com/collect/?pid=${partnerId}&fmt=gif`;
};

const mapStateToProps = ({ cognito, accountList }) => ({
  cognitoUser: cognito,
  accountList,
});

// AuthCheck is a layer to load current login state into redux state
// before mounting the actual components. All mounted child components can expect
// cognito/accountList to be the loaded state.
@connect(mapStateToProps)
class AuthCheck extends React.Component {
  load() {
    const { dispatch, cognitoUser, accountList } = this.props;
    // funny way to check that cognito state is loaded
    if (cognitoUser.status !== "invalid") {
      return;
    }

    initAmplify();
    dispatch(cognitoActions.load()).then((cognitoResponse) => {
      if (accountList.status !== "invalid") {
        return;
      }

      if (!cognitoResponse) {
        dispatch(accountListActions._receive(null));
        return;
      }
      dispatch(accountListActions.load()).catch((e) => {
        // bail out to create a new account in case this is not done yet.
        // this is not expected as a normal flow
        if (e?.name === "NotFoundException") {
          const attrs = cognitoResponse.attributes;
          const utm_params = JSON.parse(
            attrs["custom:utm_params"] ||
              localStorage.getItem("signup-utm-params")
          );
          dispatch(
            api.account.create(
              {},
              {
                email: attrs.email,
                clearing_broker: "ALPACA_APCA",
                crypto_broker: attrs["custom:crypto_broker"],
                source: attrs["custom:signup_source"],
                utm_params,
              }
            )
          ).then(() => {
            dispatch(accountListActions._invalidate());
            dispatch(cognitoActions._invalidate());
          });
        } else {
          dispatch(accountListActions._receive(null));
        }
      });
    });
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate() {
    this.load();
  }

  render() {
    const { cognitoUser, accountList, routeProps } = this.props;
    // first time log-in where the cognito entry exists but no entry exists in our DB
    if (
      cognitoUser.status === "loaded" &&
      accountList.error?.name === "NotFoundException"
    ) {
      return <Redirect to="/brokerage/new-account" />;
    }
    if (cognitoUser.status === "error") {
      console.error("cognitoUser error: ", cognitoUser.error);
      return <Redirect to="/login?error=auth" />;
    }
    if (accountList.status === "error") {
      console.error("accountList error: ", accountList.error);
      return <Redirect to="/login?error=account" />;
    }
    if (cognitoUser.status !== "loaded" || accountList.status !== "loaded") {
      return <></>;
    }

    // todo: need to expose each no-login-required page
    // no payload means the user is not logged in
    // if (!cognitoUser.payload && !this.props.exposed) {
    //  return <Redirect to="/login" />;
    // }

    let destination = null;
    if (this.props.redirector) {
      destination = this.props.redirector(
        cognitoUser.payload,
        accountList.payload,
        routeProps
      );
    }

    if (destination && destination !== routeProps.location.pathname) {
      return <Redirect to={destination} />;
    }

    const pathname = routeProps.location.pathname;
    if (pathname != this.prevPathname) {
      fireLinkedInPixel();
      this.prevPathname = pathname;
    }
    const Component = this.props.component;
    return (
      <Component
        cognitoUser={cognitoUser.payload}
        accountList={accountList.payload}
        routeProps={routeProps}
      />
    );
  }
}
export default AuthCheck;
