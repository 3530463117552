import React, { forwardRef } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Container = styled.div`
  .react-tel-input {
    font-family: Carnas;

    .form-control {
      width: 100%;
      border: 1px solid #e4e9f0;
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
    }

    .flag-dropdown,
    .flag-dropdown.open {
      background-color: transparent;
      border: 1px solid transparent;
    }
  }
`;

const AntPhoneInput = forwardRef(({ onChange, country, ...props }, _ref) => (
  <Container>
    <PhoneInput
      inputClass="ant-input"
      onChange={(phone) => onChange(`+${phone}`)}
      country={country || "us"}
      {...props}
    />
  </Container>
));

export default AntPhoneInput;
