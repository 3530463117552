import styled, { css } from "styled-components";
import Loader from "react-loader-spinner";
import { Button, Input, Modal, notification, Select } from "antd";
import { currencyCloud, tradeAccount } from "src/api";

export const BaseCopy =
  "Alpaca has partnered with CurrencyCloud to facilitate ";
export const IntroCopy =
  BaseCopy + "cross border payments as fast and as cheap as local rails.";

// General Transfer
export const TransferHeadCopy =
  BaseCopy +
  "international deposits and withdrawals, faster and cheaper than traditional wire.";

// Deposits
export const DepositHeadCopy =
  BaseCopy +
  "you funding your account faster and cheaper than traditional wire.";
export const DepositBodyText = `Please make a local transfer from your favorite bank account / mobile banking app to the account details above.
  Your funds will be sent to Alpaca’s bank account in your country, and as soon as the funds hit our account we will credit your Alpaca account with the funds.`;
export const DepositFee =
  "Alpaca charges a 1.5% (maximum $40) transaction fee on deposits. Minimum deposit equivalent to $2.00.";
export const DepositFeeNote = `Note: For European users, your bank may charge you to send over SWIFT. Due to Brexit some banks may charge additional fees, even though the European Payment Council has extended the UK's membership to SEPA (European rails). For any questions please reach out to support@alpaca.markets.`;

// Withdrawals
export const WithdrawalHeadCopy =
  BaseCopy + "withdrawing your funds faster and cheaper than traditional wire.";
export const WithdrawalBodyText =
  "Your funds will be sent to your local bank account in your local currency.";
export const WithdrawalFee = `Alpaca charges a 1.5% transaction (maximum $40) fee on withdrawals. Minimum withdrawal equivalent to $2.00.`;

export const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${(props) => (props.nonModalMode ? "none" : "528px")};
  border-radius: 5px;
  text-align: center;
  margin-top: 2.25rem;
`;

const compactBreakPoint = `@media only screen and (min-width: 991px) and (max-width: 1600px)`;

export const TransferButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffd700;
  border-radius: 4px;
  padding: 32px 45px;
  max-height: 108px;
  max-width: 248px;
  cursor: pointer;
  width: ${(props) => (props.compact ? "45%" : "")};

  ${compactBreakPoint} {
    width: 100%;
    margin-top: 1rem;
  }
`;

export const TransferButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 2.25rem;

  ${compactBreakPoint} {
    ${(props) =>
      props.compact ? "flex-direction: column; align-items: center;" : ""}
  }
`;

export const GenerateContainer = styled(BaseContainer)`
  border: 1px solid #000000;
  padding: 40px 64px;
`;

export const TransferContainer = styled(BaseContainer)``;

export const GenerateButton = styled(Button)`
  max-height: 36px;
  margin-top: 3rem;
`;

export const GenerateLoader = styled(Loader)`
  margin-bottom: 3rem;
  margin-top: 1rem;
`;

export const CCTextNormal = styled.span`
  font-size: 14px;
  color: #000;
`;

export const CCTextBold = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #000;
`;

export const CCModalContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => (props.nonModalMode ? "text-align:left;" : "")}
`;

export const BankDetailsContainer = styled(CCModalContainer)`
  border: 1px solid #000000;
  margin-top: 1rem;
  max-width: 400px;
  border-radius: 5px;
  min-width: auto;
`;

export const CCModal = styled(Modal)`
  min-width: 604px;
`;

export const ModalCopy = styled(CCTextNormal)`
  margin-bottom: 0.75rem;
  align-self: start;
  ${(props) =>
    props.isError &&
    css`
      color: #cc2f3c;
    `}
`;

export const ModalHeader = styled(CCTextBold)`
  font-size: 24px;
  margin-bottom: 1.5rem;
`;

export const ModalSubcontainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 2rem;
`;

export const DepositInfoRow = styled.div`
  width: 100%;
  margin-bottom: 0.75rem;
  display: flex;
`;

export const DepositInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
`;

export const CCInput = styled(Input)`
  border: 1px solid #000000;
  min-height: 37px;
  margin-bottom: 2.25rem;
  margin-top: 0.25rem;

  ${(props) => (!props.$valid ? "border: 1px solid #fb434a;" : "")}
`;

export const CopyButtonIcon = styled.img`
  max-height: 18px;
  cursor: pointer;
  margin-left: 0.75rem;
`;

export const CopyButton = styled.div`
  align-self: end;
`;

export const CountrySelect = styled(Select)`
  width: 100%;
  min-height: 37px;
  border: 1px solid #000;
  border-radius: 4px;
  margin-bottom: 2.25rem;
`;

export const ButtonRow = styled.div`
  display: flex;
`;

export const BenefDetailsContainer = styled.div`
  width: 100%;
`;

export const BenefDetailHeader = styled.span`
  font-size: 14px;
  margin-left: 0.75rem;

  ${(props) => (!props.$valid ? "color: #fb434a;" : "")}
`;

export const WithdrawableText = styled.span`
  opacity: 60%;
  font-size: 14px;
  margin-right: 0.5rem;
`;
export const WithdrawableBold = styled(WithdrawableText)`
  font-weight: 600;
  opacity: 100%;
  margin-left: 0.5rem;
`;

export const BenefDetailsPlaceholders = {
  account_number: "e.g. 123xxxx",
  iban: "e.g. AABBCCCCDDDDDDEEEEEEEE",
  bic_swift: "e.g. ABCD",
  sort_code: "e.g. 123456",
  aba: "e.g. 123456789",
  bsb_code: "e.g. XXYZZZ",
  bank_code: "e.g. ABCD",
  branch_code: "e.g. 1234",
  clabe: "e.g. AAABBBCCCCCCCCCCCD",
  cnaps: "e.g. 123412341234",
  ifsc: "e.g. ABCD1A2B3CD",
};

// creates an associated CC sub account along with a beneficiary
export const generateCurrencyCloudAccount = (
  accountId,
  benefReq,
  setDepositDetails
) => {
  const path = `/currency_cloud/${accountId}/funding_account`;
  fetch(window.env.API_URL + path, { method: "post" })
    .then(() => {
      createBeneficiary(accountId, benefReq, setDepositDetails);
    })
    .catch(console.error);
};

export const getCurrencyCloudAccount = (accountId, setDepositDetails) => {
  const endpoint = currencyCloud.get({
    accountId,
  });
  endpoint()
    .then((resp) => {
      setDepositDetails(resp);
    })
    .catch((e) => {
      console.error(e);
    });
};

export const getBeneficiaryDetails = (
  accountId,
  setBenefDetails,
  setLoading
) => {
  const endpoint = currencyCloud.beneficiary.get({
    accountId,
  });
  endpoint()
    .then((resp) => {
      setBenefDetails(resp.required_details[0]);
      setLoading(false);
    })
    .catch((e) => {
      console.error(e);
    });
};

export const createBeneficiary = (accountId, req, setDepositDetails) => {
  const endpoint = currencyCloud.beneficiary.create(
    {
      accountId,
    },
    req
  );
  endpoint()
    .catch(console.error)
    .finally(() => getCurrencyCloudAccount(accountId, setDepositDetails));
};

export const getStatus = (accountId, setBeneficiaryExists, setLoading) => {
  const endpoint = currencyCloud.status({
    accountId,
  });
  endpoint().then((resp) => {
    // bool value comes as a string in the response
    setBeneficiaryExists(resp.beneficiary_exists === "true");
    setLoading(false);
  });
};

export const createWithdrawal = (accountId, amount, onSuccess) => {
  // TODO: update to use the general API object we use for CC. This is a workaround as this
  // endpoint currently returns nothing as a response for 200s and the resp.json() in rest.js throws an error.
  const path = `/currency_cloud/${accountId}/transfers`;
  fetch(window.env.API_URL + path, {
    method: "post",
    body: JSON.stringify({ amount }),
  })
    .then(() => {
      notification.open({
        type: "success",
        message: "Transfer successfully initiated!",
      });
      onSuccess();
    })
    .catch((e) => {
      console.error(e);
    });
};

export const getCashWithdrawable = (accountId, setCashWithdrawable) => {
  const endpoint = tradeAccount.getETC({
    accountId,
  });
  endpoint().then((resp) => {
    setCashWithdrawable(resp.cash_withdrawable);
  });
};
