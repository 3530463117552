import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setLayoutState } from "reducers/app";
import { MenuLeft } from "./MenuLeft";
import { MenuTop } from "./MenuTop";
import { Icon, Drawer } from "antd";
import "./style.scss";

const selector = ({ app: { layoutState } }) => ({
  open: layoutState.menuMobileOpened,
  isMenuTop: layoutState.isMenuTop,
});

const AppMenu = (props) => {
  const { isMobile } = props;
  const dispatch = useDispatch();
  const { open, isMenuTop } = useSelector(selector);
  const toggleOpen = useCallback(() => {
    dispatch(setLayoutState({ menuMobileOpened: !open }));
  }, [dispatch, open]);

  return isMobile ? (
    <>
      <Icon type="menu" className="menu-icon" onClick={toggleOpen} />
      <Drawer
        visible={open}
        placement="left"
        onClose={toggleOpen}
        className={props.product}
      >
        <MenuLeft {...props} />
      </Drawer>
    </>
  ) : isMenuTop ? (
    <MenuTop {...props} />
  ) : (
    <MenuLeft {...props} />
  );
};

export default AppMenu;
