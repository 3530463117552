import { createAction, createReducer } from "redux-act";
import api from "api";
import { handleSessionExpire } from "./alpacaAuth/session";
import { getAccountIdAndJWT, getAccountIdForProduct } from "selectors";

// documents for an account, uploads and requests
const _setDocumentRequests = createAction(`SET_ACCOUNT_DOCUMENTS_REQUESTS`);
const _setDocumentUploads = createAction(`SET_ACCOUNT_DOCUMENTS_UPLOADS`);

/**
 * listAccountDocumentRequests retrieves all document requests for the current user's account
 */
export const listAccountDocumentRequests = () => (dispatch, getState) => {
  const { token } = getAccountIdAndJWT(getState());

  const accountId = getAccountIdForProduct(getState(), "live");

  return handleSessionExpire(
    api.accountDocuments
      .listRequests(accountId, token)
      .then((documentRequests) =>
        dispatch(_setDocumentRequests({ accountId, documentRequests }))
      ),
    dispatch
  );
};

/**
 * listAccountDocumentUploads retrieves all documents uploaded for the current user's account
 */
export const listAccountDocumentUploads = () => (dispatch, getState) => {
  const { token } = getAccountIdAndJWT(getState());

  const accountId = getAccountIdForProduct(getState(), "live");

  return handleSessionExpire(
    api.accountDocuments
      .listUploads(accountId, token)
      .then((documentUploads) =>
        dispatch(_setDocumentUploads({ accountId, documentUploads }))
      ),
    dispatch
  );
};

/**
 * uploadDocumentForRequest handles a file upload with sub type field for a given request
 */
export const uploadDocumentForRequest = (docId, subType, file) => (
  dispatch,
  getState
) => {
  const { token } = getAccountIdAndJWT(getState());

  const accountId = getAccountIdForProduct(getState(), "live");

  // post form data with the key `uploadfile`
  var formData = new FormData();
  formData.append("uploadfile", file);
  return handleSessionExpire(
    api.accountDocuments.upload(accountId, token, { docId, subType, formData }),
    dispatch
  );
};

export const uploadByDocType = (docType, docSubType, file) => (
  dispatch,
  getState
) => {
  const { token } = getAccountIdAndJWT(getState());

  const accountId = getAccountIdForProduct(getState(), "live");

  // post form data with the key `uploadfile`
  var formData = new FormData();
  formData.append("uploadfile", file);
  return handleSessionExpire(
    api.accountDocuments.uploadByDocType(accountId, token, {
      docType,
      docSubType,
      formData,
    }),
    dispatch
  );
};

// Export this reducer
const initialState = {
  uploads: {},
  requests: {},
};
export default createReducer(
  {
    [_setDocumentRequests]: (state, { accountId, documentRequests }) => {
      // the document records will all belong to the same account_id
      const currentDocuments = state.accountDocuments || {};
      const requests = currentDocuments["requests"] || {};

      if (accountId) {
        if (!requests[accountId]) {
          requests[accountId] = {};
        }
        requests[accountId] = documentRequests;
      }
      currentDocuments["requests"] = requests;
      return { ...state, ...currentDocuments };
    },
    [_setDocumentUploads]: (state, { accountId, documentUploads }) => {
      // the document records will all belong to the same account_id
      const currentDocuments = state.accountDocuments || {};
      const uploads = currentDocuments["uploads"] || {};

      if (accountId) {
        if (!uploads[accountId]) {
          uploads[accountId] = {};
        }
        uploads[accountId] = documentUploads;
      }
      currentDocuments["uploads"] = uploads;
      return { ...state, ...currentDocuments };
    },
  },
  initialState
);
