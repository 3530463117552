import { useEffect, useState } from "react";
import { getCountryOfCitizenship } from "../rest/account";

export const useGetCitizenship = () => {
  const [country, setCountry] = useState<string>("");

  useEffect(() => {
    async function fetchCitizenship() {
      const country = await getCountryOfCitizenship();
      setCountry(country);
    }
    fetchCitizenship();
  }, []);

  return { country };
};

export default useGetCitizenship;
