// The API will return a response if the JWT is invalid, but Amplify should also handle that.
// There is also a rate limit that apparently throws this error too.
export const handleSessionExpire = (promise) =>
  promise.catch((e) => {
    if (e.name === "InvalidCredentialException") {
      // Not necessarily a lotout event here.
      // TODO: logout
    }
    throw e;
  });
