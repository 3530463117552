import { createAction, createReducer } from "redux-act";
import Auth from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import { get } from "lodash";
import { getCurrentAuthenticatedUser } from "../../utils";

import {
  getAccount,
  loadAccountDetails,
  updateAccountDetails,
  updateAccountOwner,
} from "../account";
import * as accountListActions from "reducers/auth/accountList";
import { logout } from "./logout";
import { getCookie } from "src/lib/cookies";
import sha1 from "js-sha1";
import { updateIntercom } from "src/utils/intercom";

const COGNITO_OK_RESPONSE = "SUCCESS";

const REDUCER = "auth";
const NS = `@@${REDUCER}/`;

const _setFrom = createAction(`${NS}SET_FROM`);
const _setPasswordWasReset = createAction(`${NS}SET_PASSWORD_WAS_RESET`);
// TODO: Implement this.
// const _setNewPasswordRequired = createAction(`${NS}SET_NEW_PASSWORD`)
export const setUserState = createAction(`${NS}SET_USER_STATE`);
export const setAccountVerificationRequired = createAction(
  `${NS}SET_ACCOUNT_VERIFICATION_REQUIRED`
);
export const setResentAccountVerification = createAction(
  `${NS}SET_RESENT_ACCOUNT_VERIFICATION`
);
export const setAccountVerificationError = createAction(
  `${NS}SET_ACCOUNT_VERIFICATION_ERROR`
);
export const setEmailUpdateVerificationRequired = createAction(
  `${NS}SET_EMAIL_UPDATE_VERIFICATION_REQUIRED`
);

export const initAmplify = () => {
  Amplify.configure({
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // (for accessing services like Storage)
      // (Federated Identities > Selected Identity Pool/Create new > Sample code > Select Javascript > Get AWS Credentials)
      identityPoolId: window.env.COGNITO_IDENTITY_POOL_ID,

      // REQUIRED - Amazon Cognito Region
      region: window.env.COGNITO_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      // (User pools > General Settings > Pool Id)
      userPoolId: window.env.COGNITO_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      // (User pools > General Settings > App clients > App client id)
      userPoolWebClientId: window.env.COGNITO_CLIENT_ID,

      endpoint: window.env.COGNITO_ENDPOINT,
      
      authenticationFlowType: window.env.COGNITO_AUTH_FLOW,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Configuration for cookie storage
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: window.location.hostname,
        // OPTIONAL - Cookie path
        path: "/",
        // OPTIONAL - Cookie expiration in days
        expires: 7,
        // OPTIONAL - Cookie secure flag
        secure: window.env.ENV === "PROD",
      },
    },
    Storage: {
      bucket: window.env.COGNITO_BUCKET,
    },
    // AppSync can power the API with GraphQL simply by proxying HTTP requests to the REST API
    // 'aws_appsync_graphqlEndpoint': 'https://xxxxxx.appsync-api.us-east-1.amazonaws.com/graphql',
    // 'aws_appsync_region': 'us-east-1',
    // 'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
  });
};

/**
 * initUser will initialize a new user, setting up their account for
 * the first time if necessary. It will also identify the user in Segment.
 * Mandatory to call after first login. Good to always call after login
 * or after any change in user data so it can report to Segment.
 *
 * @param {Boolean} refreshAccountInfo Pass true to get updated account info
 */
export const initUser = (refreshAccountInfo = false) => (
  dispatch,
  getState
) => {
  const getCookieValue = (a) => {
    var b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  };

  // Just call this to get the session.
  // Note: Unless `allowAccess` is given, access will be permitted if `getCurrentAuthenticatedUser()`
  // comes back with a response/user.
  return getCurrentAuthenticatedUser()
    .then((data) => {
      dispatch(
        setUserState({
          userState: data,
        })
      );

      setDomainJWT(data);
      // Don't fetch account more than necessary
      const { account = false } = getState().account;

      // Note: One challenge is that in order to redirect, we need to have the account details.
      // So if we do not have account info, return the dispatch(getAccount()) and within there, get details.
      // Then check auth after having those details.
      // This is a good bit of info that needs to be retrieved before a user gets redirected.
      // Given how much gets loaded anyway, this may not even be needed. We might just dispatch getAccount()
      // all the time.
      if (!account || !account.id || refreshAccountInfo) {
        return dispatch(getAccount()).then(async (action) => {
          // Sync tasks.
          const accountId =
            (action &&
              action.payload &&
              action.payload.account &&
              action.payload.account.id) ||
            false;
          const cognitoAccountIdAttr =
            (data && data.attributes && data.attributes["custom:account_id"]) ||
            false;
          const accountEmail =
            (action &&
              action.payload &&
              action.payload.account &&
              action.payload.account.email) ||
            false;
          const cognitoAccountEmail =
            (data && data.attributes && data.attributes["email"]) || false;
          if (accountId) {
            // If, for some reason, the Cognito user does not have its account_id attribute set, do so.
            // This should never be the case. It was the case during testing/migration. It's harmless to set.
            // Maybe a migration script failed to update the account id? This would catch it.
            if (!cognitoAccountIdAttr) {
              Auth.updateUserAttributes(data, {
                "custom:account_id": accountId,
              });
            }

            // In the case where a cognito email is not synced up with the email for this account in GoBroker:
            // Sleep the thread for a moment and then retry to see if it resolves a potential race case that happened,
            // If it's still happening, update the email, which will set the status as ACCOUNT_UPDATED and we will need to resolve the issue.
            if (
              accountEmail &&
              cognitoAccountEmail &&
              accountEmail.toLowerCase() !== cognitoAccountEmail.toLowerCase()
            ) {
              setTimeout(() => {
                // retry call after sleep
                dispatch(getAccount()).then((action) => {
                  const retryGbEmail =
                    (action &&
                      action.payload &&
                      action.payload.account &&
                      action.payload.account.email) ||
                    false;
                  const retryCognitoEmail =
                    (data && data.attributes && data.attributes["email"]) ||
                    false;

                  // if still out of sync, update mark as needing attention
                  if (
                    retryGbEmail &&
                    retryCognitoEmail &&
                    retryGbEmail.toLowerCase() !==
                      retryCognitoEmail.toLowerCase()
                  ) {
                    dispatch(updateAccountOwner({ email: retryCognitoEmail }));
                  }
                });
              }, 5000);
            }
          }

          // Once the account has been retrieved (and possibly created for first time users)
          // The account_id will be set in the state. The following calls are useful for all areas
          // of the dashboard (and for segment), so retrieve the data from them now.
          return Promise.all([
            dispatch(loadAccountDetails()),
          ])
            .then(() => {
              const state = getState();
              const account = state.account || false;

              // TODO: track the following:
              // last order executed date (*not currently sent, may not come from dashboard)
              // first order executed date (*not currently sent, may not come from dashboard)

              if (account) {
                // Age
                let age = 0;
                if (account.details) {
                  const thisYear = new Date().getFullYear();
                  const birthYear = new Date(
                    account.details.date_of_birth
                  ).getFullYear();
                  age = thisYear - birthYear;
                }

                // The algoId in this case will just be the current account
                // We won't report the paper account's trade details (portfolio_value, cash, buying_power to Segment)
                const algoId = account.account.id;
                const trade = state.trade || {};
                const ownerIdHash = sha1(account.details.owner_id) || "";
                const ownerRefId = ownerIdHash.substring(0, 10);
                // refBy will be pushed during new account sign up
                // This way it doesn't always get applied even for existing accounts

                // Follow the spec. Segment has to properly map attributes to various destinations.
                // https://segment.com/docs/spec/identify/#traits
                let traits = {
                  // Technically, this may not matter. Segment should be good with snake or camel case.
                  createdAt: account.account.created_at,
                  apexApprovalStatus: account.account.apex_approval_status,
                  status: account.account.status,
                  ownerId: account.details.owner_id,
                  // The user's referrer ID is first 10 of sha1 of their owner_id
                  refId: ownerRefId,
                  email: account.account.email,
                  name: account.account.name,
                  firstName: account.details.given_name,
                  lastName: account.details.family_name,
                  age,
                  buyingPower: Number.parseFloat(
                    (trade && trade[algoId] && trade[algoId].buying_power) || 0
                  ),
                  cash: Number.parseFloat(
                    (trade && trade[algoId] && trade[algoId].cash) || 0
                  ),
                  portfolioValue: Number.parseFloat(
                    (trade && trade[algoId] && trade[algoId].portfolio_value) ||
                      0
                  ),
                  tradingBlocked: account.account.trading_blocked,
                  transfersBlocked: account.account.transfers_blocked,
                  // Want both the address object (as per Segment spec) as well as easier to target fields (for now).
                  country: "US",
                  state: account.details.state,
                  address: {
                    country: "US",
                    state: account.details.state,
                  },
                };

                // Optional clearing broker
                if (account.account && account.account.clearing_broker) {
                  traits.clearing_broker = account.account.clearing_broker;
                }

                // Optional profile pic / avatar
                if (state.profile && state.profile.picUrl) {
                  traits.avatar = state.profile.picUrl;
                }

                // This will be recorded by another call under the profitloss reducer. No need to always load profit loss.
                // May also move paper account identify call as well.
                // traits.profitLossDay = profitloss.day_plpc;
                // traits.profitLossTotal = profitloss.total_plpc;

                // IMPORTANT: From where did our users come from?
                // These come from cookies. These only get sent while the user's status is not 'ACTIVE'
                // Note on attribution: This models a "last click/came from" type of model. Meaning, a user
                // could come from one ad campaign, sign up, then come back from another ad later before
                // being approved. In this case, it would be the second ad that was attributed with the conversion.
                // Unless cookies aren't overwritten/set again. Could control that outside of here.
                // Note: These are also tracked as events during onboarding. However, this allows us to target users
                // based on this information.
                if (account.account.status !== "ACTIVE") {
                  traits.referrerCampaignName = getCookieValue(
                    "alpaca_referrer_campaign_name"
                  );
                  traits.referrerCampaignSource = getCookieValue(
                    "alpaca_referrer_campaign_source"
                  );
                  traits.referrerCampaignMedium = getCookieValue(
                    "alpaca_referrer_campaign_medium"
                  );
                  traits.referrerCampaignContent = getCookieValue(
                    "alpaca_referrer_campaign_content"
                  );
                  traits.referrerCampaignTerm = getCookieValue(
                    "alpaca_referrer_campaign_term"
                  );
                  traits.referrerURL = getCookieValue("alpaca_referrer_url");
                }

                // NOTE: Segment is given our ownerId for the user id.
                // This does mean any user signing up for a newsletter, handled by Intercom, could have two records.
                // Once they are converted to an Alpaca user, the old entry in Intercom can be archived.
                // Easy to filter out that "old" record with any of the above props too (since it won't have them).
                updateIntercom(traits.ownerId, traits);
              }
              return;
            }) // end then() for Promise.all() - retrieval of account info
            .catch((err) => {
              console.error(err);
            }); // end catch() for Proimise.all() - retrieval of account info
        }); // end dispatch getAccount()
      }
      return;
    })
    .catch(() => {
      return Promise.reject();
    });
};

// Copy JWT cookie to the current domain to share the
// login info to the subdomain apps such as zaam.
const setDomainJWT = (user) => {
  const jwt = get(user, "signInUserSession.idToken.jwtToken", user);
  if (jwt) {
    document.cookie = [
      "dashboard.authtoken=" + jwt,
      // needs to have this leading dot to propagate to subdomain
      "domain=." + location.hostname,
      "max-age=" + 30 * 24 * 60 * 60,
      "path=/",
    ].join("; ");
  }
};

/**
 * Resends a user's account verification code.
 * This can be used in a variety of places including
 * during signup verification or forgot password verification.
 *
 * @param {string} username
 */
export const resendSignUp = (username) => (dispatch) => {
  // TODO: Maybe handle the error using the same dispatch... one will just have an error instead
  // Then what? It shouldn't fail, but will it? Have to see.
  Auth.resendSignUp(username.toLowerCase())
    .then((data) => {
      dispatch(setResentAccountVerification(data));
    })
    .catch((err) => console.error("error resending signup:", err));
};

/**
 * Resets the user's password in Cognito
 *
 * @param {string} username
 * @param {string} password
 * @param {string} code
 */
export const resetPassword = (username, code, password) => (dispatch) => {
  Auth.forgotPasswordSubmit(username.toLowerCase(), code, password)
    .then(() => {
      // workaround we have to do because there's no Cognito support for global session invalidation on pw reset
      Auth.signIn(username, password).then(() => {
        dispatch(logout());
      });
      dispatch(_setPasswordWasReset(true));
    })
    .catch((err) => {
      console.error("error resetting password: ", err);
      dispatch(_setPasswordWasReset(false));
    });
};

/**
 * Verifies a user attribute change using a code.
 * This action should not be called unless the user is logged in with a valid session.
 * This is useful for attribute changes for which Cognito sends a verification code to confirm.
 * For example, an e-mail update.
 *
 * Note: Not all attributes need to be verified - `email` does.
 *
 * @param {string} attribute  The Cognito user attribute name being updated/verified
 * @param {string} code       The verification code that was sent to the user
 */
export const verifyAsCurrentUser = (attribute, code) => async (dispatch) => {
  if (attribute && code) {
    let result = await Auth.verifyCurrentUserAttributeSubmit(attribute, code);
    if (result === COGNITO_OK_RESPONSE) {
      // Get the user again with fresh attributes.
      let user = await getCurrentAuthenticatedUser();
      const newEmail =
        (user && user.attributes && user.attributes.email) || false;
      // Certainly do not update email if something went wrong here.
      if (newEmail) {
        return Promise.all([
          dispatch(setEmailUpdateVerificationRequired(false)),
          // update in gobroker
          dispatch(updateAccountDetails({ email: newEmail.toLowerCase() })),
        ]);
      }
    }
  }
};

// Export this reducer
const initialState = {
  // an initial account verification
  accountVerificationRequired: {},
  accountVerificationError: false,
  // when updating email Cognito attributes, user must re-verify (the email part triggers it)
  emailUpdateVerificationRequired: false,
  resentAccountVerification: false,
  failedLogin: false,
  forgotPasswordVerification: false,
  passwordWasReset: false,
  isHideLogin: false,
  // USER STATE
  userState: {},
};
export default createReducer(
  {
    [_setFrom]: (state, from) => ({ ...state, from }),
    [_setPasswordWasReset]: (state, param) => ({
      ...state,
      passwordWasReset: param,
    }),
    [setUserState]: (state, { userState }) => ({ ...state, userState }),
    [setAccountVerificationRequired]: (state, param) => {
      // like invalid forms, this requires a form id and (otherwise default 'all' forms)
      // however the key value here will be whatever was passed
      const accountVerificationRequired = param
        ? {
            ...state.submitForms,
            [param.id || "all"]: param,
          }
        : false;
      return { ...state, accountVerificationRequired };
    },
    [setAccountVerificationError]: (state, param) => {
      return { ...state, accountVerificationError: param };
    },
    [setResentAccountVerification]: (state, param) => ({
      ...state,
      resentAccountVerification: param,
    }),
    [setEmailUpdateVerificationRequired]: (state, param) => ({
      ...state,
      emailUpdateVerificationRequired: param,
    }),
    // TODO: There will be a case where Cognito requires users to set a new password.
    // It could perhaps be if an admin sets up an account with the requirement for the user to set a new password on first login.
    // Implement this.
    // [_setNewPasswordRequired]: (state, needsNewPassword) => ({ ...state, needsNewPassword }),
  },
  initialState
);
