import useRequest, { QueryOptions } from "./useRequest";

import { CryptoMonthlyVolume, getCryptoMonthlyVolume } from "../rest/billing";

export const useGetCryptoMonthlyVolume = (
  key: any | any[] = "crypto-monthly-volume",
  accountID: string = "",
  options?: QueryOptions<CryptoMonthlyVolume>
) => {
  const { data } = useRequest(key, () => getCryptoMonthlyVolume(accountID), {
    enabled: !!accountID,
    ...options,
  });

  return { ...data };
};

export default useGetCryptoMonthlyVolume;
