import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { round } from "lodash";
import {
  BankDetailsContainer,
  BenefDetailHeader,
  BenefDetailsContainer,
  BenefDetailsPlaceholders,
  ButtonRow,
  CCInput,
  CCModal,
  CCModalContainer,
  CCTextBold,
  CCTextNormal,
  CopyButton,
  CopyButtonIcon,
  createWithdrawal,
  DepositBodyText,
  DepositFee,
  DepositFeeNote,
  DepositHeadCopy,
  DepositInfo,
  DepositInfoRow,
  GenerateButton,
  GenerateContainer,
  generateCurrencyCloudAccount,
  GenerateLoader,
  getBeneficiaryDetails,
  getCashWithdrawable,
  getCurrencyCloudAccount,
  getStatus,
  IntroCopy,
  ModalCopy,
  ModalHeader,
  ModalSubcontainer,
  TransferButton,
  TransferButtonRow,
  TransferContainer,
  TransferHeadCopy,
  WithdrawableBold,
  WithdrawableText,
  WithdrawalBodyText,
  WithdrawalFee,
  WithdrawalHeadCopy,
} from "./constants";
import { Spinner } from "../../../../../../../v2/components/common/spinner";

const DespositInfoCopyButton = ({ text }) => {
  return (
    <CopyButton
      onClick={() => {
        navigator.clipboard.writeText(text);
      }}
    >
      <CopyButtonIcon src="/resources/images/copy-icon.png" />
    </CopyButton>
  );
};

const CCTransferButton = ({ text, onClick, compact }) => {
  return (
    <TransferButton onClick={onClick} compact={compact}>
      <CCTextBold>{text}</CCTextBold>
      <CCTextNormal>
        {text === "Deposit" ? "from" : "to"} outside of United States
      </CCTextNormal>
    </TransferButton>
  );
};

const CCGenerateDetails = ({
  compact,
  accountId,
  accountStatus,
  setDepositDetails,
  currency,
}) => {
  const [generating, setGenerating] = useState(false);
  const [showBankInfo, setShowBankInfo] = useState(false);

  if (showBankInfo) {
    return (
      <BankDetails
        accountId={accountId}
        onSubmit={() => {
          setShowBankInfo(false);
          setGenerating(true);
        }}
        compact={compact}
        setDepositDetails={setDepositDetails}
        currency={currency}
      />
    );
  }

  return (
    <GenerateContainer>
      {generating ? (
        <>
          <GenerateLoader
            type="ThreeDots"
            color="#FFD700"
            height={50}
            width={50}
            timeout={200000}
          />
          <CCTextNormal>This usually takes around 1 minute.</CCTextNormal>
          <CCTextNormal>
            You can navigate away - we will email you when your funding details
            are ready.
          </CCTextNormal>
        </>
      ) : (
        <>
          <CCTextNormal>{IntroCopy}</CCTextNormal>
          <GenerateButton
            type="primary"
            size="large"
            amplitude-event-name="generate_funding_details_clicked"
            onClick={() => setShowBankInfo(true)}
            disabled={accountStatus !== "ACTIVE"}
          >
            Generate funding details
          </GenerateButton>
        </>
      )}
    </GenerateContainer>
  );
};

const CCTransferDetails = ({
  depositDetails,
  accountId,
  benefExists,
  compact,
  nonModalMode,
  singleOption,
}) => {
  const [depositModalVisible, setDepositModalVisible] = useState(false);
  const [withdrawalModalVisible, setWithdrawalModalVisible] = useState(false);
  const [cashWithdrawable, setCashWithdrawable] = useState("");

  useEffect(() => {
    getCashWithdrawable(accountId, setCashWithdrawable);
  }, []);

  const buttonRowHidden = Boolean(singleOption) && nonModalMode;

  return (
    <TransferContainer nonModalMode={nonModalMode}>
      {!buttonRowHidden && (
        <>
          <CCTextNormal>{TransferHeadCopy}</CCTextNormal>
          <TransferButtonRow compact={compact}>
            <CCTransferButton
              text="Deposit"
              amplitude-event-name="cc_deposits_clicked"
              onClick={() => {
                setDepositModalVisible(true);
              }}
              compact={compact}
            />
            <CCTransferButton
              text="Withdrawals"
              amplitude-event-name="cc_withdrawals_clicked"
              onClick={() => {
                setWithdrawalModalVisible(true);
              }}
              compact={compact}
            />
          </TransferButtonRow>
        </>
      )}
      <DepositModal
        modalVisible={depositModalVisible || singleOption === "INCOMING"}
        onCancel={() => setDepositModalVisible(false)}
        depositDetails={depositDetails}
        nonModalMode={nonModalMode}
      />
      <WithdrawalModal
        modalVisible={withdrawalModalVisible || singleOption === "OUTGOING"}
        onCancel={() => setWithdrawalModalVisible(false)}
        accountId={accountId}
        benefExists={benefExists}
        cashWithdrawable={cashWithdrawable}
        nonModalMode={nonModalMode}
      />
    </TransferContainer>
  );
};

const DepositModal = ({
  modalVisible,
  onCancel,
  depositDetails,
  nonModalMode,
}) => {
  const contents = (
    <CCModalContainer nonModalMode={nonModalMode}>
      <ModalHeader>Deposit</ModalHeader>
      <ModalSubcontainer>
        <div style={{ display: "flex" }}>
          <div
            style={{
              marginRight: "1rem",
              whiteSpace: "nowrap",
            }}
          >
            {[
              "Account Number",
              "Account Holder Name",
              "Routing Code",
              "Routing Code Type",
              "Bank Name",
              "Bank Address",
            ].map((label, index, arr) => {
              const last = index === arr.length - 1;

              return (
                <div
                  style={{
                    fontWeight: 500,
                    marginBottom: last ? 0 : ".8rem",
                  }}
                  key={index}
                >
                  {label}
                </div>
              );
            })}
          </div>
          <div>
            {[
              "account_number",
              "account_holder_name",
              "routing_code",
              "routing_code_type",
              "bank_name",
              "bank_address",
            ].map((key, index, arr) => {
              const last = index === arr.length - 1;

              return (
                <div
                  style={{
                    marginBottom: last ? 0 : ".8rem",
                  }}
                  key={index}
                >
                  {depositDetails[key]}
                </div>
              );
            })}
          </div>
        </div>
      </ModalSubcontainer>
      <ModalCopy>{DepositHeadCopy}</ModalCopy>
      <ModalCopy>{DepositBodyText}</ModalCopy>
      <ModalCopy>{DepositFee}</ModalCopy>
      <ModalCopy>{DepositFeeNote}</ModalCopy>

      {!nonModalMode && (
        <Button type="primary" onClick={onCancel}>
          Done
        </Button>
      )}
    </CCModalContainer>
  );

  if (!modalVisible) return <></>;

  if (nonModalMode) {
    return contents;
  }
  return (
    <CCModal visible={modalVisible} onCancel={onCancel} footer={null}>
      {contents}
    </CCModal>
  );
};

const InfoRow = ({ header, value }) => {
  return (
    <DepositInfoRow>
      <DepositInfo>
        <span style={{ fontWeight: "500" }}>{header}</span>
        <div style={{ display: "flex" }}>
          <CCTextNormal>{value}</CCTextNormal>
          <DespositInfoCopyButton text={value} />
        </div>
      </DepositInfo>
    </DepositInfoRow>
  );
};

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const BeneficiaryDetails = ({
  benefDetails,
  benefReq,
  setBenefReq,
  allValid,
  setAllValid,
}) => {
  const [validationFields, setValidationFields] = useState({});

  useEffect(() => {
    // the required details response body is the same as the req body we send back
    const benefCopy = JSON.parse(JSON.stringify(benefDetails));
    Object.keys(benefCopy).forEach((val) => {
      benefCopy[val] = "";
    });
    // we also want a second copy as the validations for the fields are sent from the backend in the object
    const validationCopy = JSON.parse(JSON.stringify(benefDetails));
    setValidationFields(validationCopy);

    setBenefReq(benefCopy);
  }, [benefDetails]);

  return (
    <BenefDetailsContainer>
      {Object.keys(benefDetails).map((key) => {
        if (benefDetails[key] !== "") {
          let valid = benefReq[key] !== "" ? allValid[key] : true;
          return (
            <>
              <BenefDetailHeader key={`${key}-header`} $valid={valid}>
                {toTitleCase(key.replace("_", " "))}
              </BenefDetailHeader>
              <CCInput
                placeholder={BenefDetailsPlaceholders[key]}
                key={key}
                onChange={(e) => {
                  let val = e.target.value;
                  let re = new RegExp(validationFields[key]);
                  setBenefReq({
                    ...benefReq,
                    [key]: val,
                  });
                  setAllValid({ ...allValid, [key]: re.test(val) });
                }}
                $valid={valid}
              />
            </>
          );
        }
      })}
    </BenefDetailsContainer>
  );
};

const WithdrawalModal = ({
  modalVisible,
  onCancel,
  accountId,
  cashWithdrawable,
  nonModalMode,
}) => {
  // the Withdrawal modal has two steps - the first page has info fields and the second is
  // to review and submit
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const cashWithdrawablePlusFee = round(
    cashWithdrawable - Math.min(25, cashWithdrawable * 0.015),
    2
  );

  const meetsMinimum = withdrawalAmount && parseFloat(withdrawalAmount) >= 2.0;
  const pattern = /^[1-9]\d*(\.\d+)?$/;
  const isOverWithdrawable =
    parseFloat(withdrawalAmount) > cashWithdrawablePlusFee;
  const validInput =
    pattern.test(withdrawalAmount) && meetsMinimum && !isOverWithdrawable;

  const contents = (
    <CCModalContainer nonModalMode={nonModalMode}>
      <ModalHeader>Withdrawals</ModalHeader>
      <ModalCopy>{WithdrawalHeadCopy}</ModalCopy>
      <ModalCopy>{WithdrawalBodyText}</ModalCopy>
      <ModalCopy>{WithdrawalFee}</ModalCopy>
      {isOverWithdrawable && (
        <ModalCopy isError>
          Cannot withdraw more than ${cashWithdrawablePlusFee}. Some deposits
          take up to 7 days to settle, after which they will be made available
          for withdrawal
        </ModalCopy>
      )}

      <Input
        placeholder="Amount"
        prefix={"$"}
        onChange={(e) => {
          setWithdrawalAmount(e.target.value);
        }}
        style={{ marginBottom: "2.25rem" }}
        suffix={
          <WithdrawableText>
            Your withdrawable funds
            <WithdrawableBold>${cashWithdrawablePlusFee}</WithdrawableBold>
          </WithdrawableText>
        }
      />

      <ButtonRow>
        <Button type="primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            createWithdrawal(accountId, withdrawalAmount, onCancel);
          }}
          disabled={withdrawalAmount === "" || !validInput}
          style={{ marginLeft: "2rem" }}
        >
          Submit
        </Button>
      </ButtonRow>
    </CCModalContainer>
  );

  if (!modalVisible) return <></>;

  if (nonModalMode) {
    return contents;
  }

  return (
    <CCModal visible={modalVisible} onCancel={onCancel} footer={null}>
      {contents}
    </CCModal>
  );
};

const BankDetails = ({
  accountId,
  onSubmit,
  compact,
  setDepositDetails,
  currency,
}) => {
  const [benefReq, setBenefReq] = useState({});
  const [benefDetails, setBenefDetails] = useState({});
  const [allValid, setAllValid] = useState({});
  const [loading, setLoading] = useState(true);

  let validInputs =
    Object.values(allValid).length > 0 &&
    Object.values(allValid).every((val) => val);

  useEffect(() => {
    getBeneficiaryDetails(accountId, setBenefDetails, setLoading);
  }, []);

  useEffect(() => {
    let relevantFields = {};
    Object.keys(benefDetails).forEach((val) => {
      if (benefDetails[val] !== "") {
        relevantFields[val] = false;
      }
    });
    setAllValid(relevantFields);
  }, [benefDetails]);

  return (
    <BankDetailsContainer compact={compact}>
      <ModalHeader>Enter bank details</ModalHeader>
      <ModalCopy>{`Please input your local bank details in ${currency}.`}</ModalCopy>
      <ModalCopy>
        This is the bank account that should send and receive funds.
      </ModalCopy>
      <ModalCopy>This should be in your name.</ModalCopy>
      {loading ? (
        <GenerateLoader
          type="ThreeDots"
          color="#FFD700"
          height={50}
          width={50}
        />
      ) : (
        <BeneficiaryDetails
          benefDetails={benefDetails}
          benefReq={benefReq}
          setBenefReq={setBenefReq}
          allValid={allValid}
          setAllValid={setAllValid}
        />
      )}
      <Button
        type="primary"
        onClick={() => {
          onSubmit();
          generateCurrencyCloudAccount(accountId, benefReq, setDepositDetails);
        }}
        disabled={!validInputs}
      >
        Next
      </Button>
    </BankDetailsContainer>
  );
};

export const CurrencyCloud = ({
  compact,
  accountId,
  accountStatus,
  currency,
  nonModalMode = false,
  singleOption,
}) => {
  const [depositDetails, setDepositDetails] = useState(null);
  const [benefExists, setBenefExists] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCurrencyCloudAccount(accountId, setDepositDetails);
    getStatus(accountId, setBenefExists, setLoading);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return depositDetails && benefExists ? (
    <CCTransferDetails
      depositDetails={depositDetails}
      accountId={accountId}
      benefExists={benefExists}
      compact={compact}
      nonModalMode={nonModalMode}
      singleOption={singleOption}
    />
  ) : (
    <CCGenerateDetails
      compact={compact}
      accountId={accountId}
      accountStatus={accountStatus}
      setDepositDetails={setDepositDetails}
      currency={currency}
    />
  );
};
