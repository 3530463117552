import React, { useContext } from "react";

import { Icon, UIContext, SidebarState, Button } from "@alpacahq/ui";

export const SidebarToggle: React.FC = () => {
  const { sidebarState, setSidebarState } = useContext(UIContext);

  const handleClick = () => {
    switch (sidebarState) {
      case SidebarState.EXPANDED:
        // if the sidebar is currently expanded, then collapse it
        // and update the local storage to remember this state
        setSidebarState(SidebarState.COLLAPSED);
        localStorage.setItem("sidebarState", SidebarState.COLLAPSED);
        break;

      case SidebarState.MOBILE:
        // if the sidebar is currently in the mobile state, then hide it
        // and remove the corresponding local storage entry
        setSidebarState(SidebarState.HIDDEN);
        localStorage.removeItem("sidebarState");
        break;

      case SidebarState.COLLAPSED:
        // if the sidebar is currently collapsed, then expand it
        // and update the local storage to remember this state
        setSidebarState(SidebarState.EXPANDED);
        localStorage.setItem("sidebarState", SidebarState.EXPANDED);
        break;

      default:
        // if the sidebar state is neither expanded, mobile nor collapsed
        console.error("invalid sidebarState");
    }
  };

  // determine the name of the icon based on the current sidebar state
  const iconName = [SidebarState.EXPANDED, SidebarState.MOBILE].includes(
    sidebarState
  )
    ? "ArrowSmallLeft"
    : "ArrowSmallRight";

  return (
    <Button onClick={handleClick} variant="secondary">
      <Icon name={iconName} />
    </Button>
  );
};

export default SidebarToggle;
