import useRequest, { QueryOptions } from "./useRequest";
import {
  getDetails,
  getTrustedAccount,
  OwnerDetails,
  TrustedContact,
} from "../rest/account";

export const useGetTrustedContact = (
  key: any | any[] = "",
  accountID: string = "",
  options?: QueryOptions<TrustedContact>
) => {
  const { data: details, refetch, isFetched } = useRequest(
    key,
    () => getTrustedAccount(accountID),
    { ...options, refetchInterval: false }
  );

  return { trusted_contact: details, refetch, isFetched };
};

export default useGetTrustedContact;
