import useRequest, { QueryOptions } from "./useRequest";
import { getWatchlists, Watchlist } from "../rest/account";

export const useGetWatchlists = (
  key: any | any[] = "",
  accountID: string = "",
  options?: QueryOptions<Watchlist[]>
) => {
  const { data: watchlists, refetch, isFetched } = useRequest(
    key,
    () => getWatchlists({ accountID }),
    { ...options, refetchInterval: false }
  );

  return { watchlists, refetch, isFetched };
};

export default useGetWatchlists;
