// todo: move this into formatting utils file

import CryptoJS from "crypto-js";
import { get } from "lodash";
import { useMemo } from "react";

import { GeocodeResult, getGeocode } from "use-places-autocomplete";
import { Account, OwnerDetails } from "../api/rest/account";

export interface ParsedAddress {
  street_address?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
}

export const emailIsValid = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const weakEncrypt = (str: any) => {
  const encrypted = CryptoJS.AES.encrypt(str, window.env.SHARED_SECRET);
  return encrypted.toString();
};

export const parseAddress = async (address: string): Promise<ParsedAddress> => {
  const geocode: GeocodeResult[] = await getGeocode({ address });
  if (geocode.length === 0) {
    throw new Error("could not find address");
  }

  const components = geocode[0].address_components;

  const getField = (name: string) => {
    for (const entry of components) {
      if (entry.types.includes(name)) {
        // State has to be the 2 or 3 character long version
        if (name === "administrative_area_level_1") {
          return entry.short_name;
        }
        return entry.long_name;
      }
    }
    console.error(
      `parse address: ${name} cannot be parsed. Please try another address.`
    );
    return null;
  };

  return {
    street_address: [getField("street_number"), getField("route")]
      .join(" ")
      .trim(),
    city: getField("locality") || getField("administrative_area_level_3"),
    state: getField("administrative_area_level_1"),
    country: getField("country"),
    postal_code: getField("postal_code"),
  } as ParsedAddress;
};

export const normalizeTaxID = (
  val: string,
  country: string | undefined | null
) => {
  if (country !== "USA") return val.replace(/\W/g, "");
  const stripped = val.replace(/\D/g, "");
  return `${stripped.slice(0, 3)}-${stripped.slice(3, 5)}-${stripped.slice(
    5,
    9
  )}`.replace(/-+$/, "");
};

export const isDisclosed = (details: OwnerDetails) => {
  // possible fields
  const keys: Array<keyof OwnerDetails> = [
    "is_politically_exposed",
    "is_discretionary",
    "is_affiliated_exchange_or_finra",
    "immediate_family_exposed",
    "is_control_person",
  ];

  // are some or all of the fields populated
  return keys.some((key) => !!details[key]);
};

export const isPaperOrOnboarding = (account: Account | undefined) => {
  // hotfix: override errant last_onboarding_step values
  if (account?.status === "ACTIVE") {
    return false;
  }

  const step = account?.last_onboarding_step;

  // if v2, check if we've reached the last step
  if (!!step) {
    return step !== "finished";
  }

  // for v1, check the account status
  return ["ONBOARDING", "PAPER_ONLY", "SIGNED_UP"].includes(
    account?.status || ""
  );
};

export function getPercentageChange(
  oldNumber: string | number,
  newNumber: string | number
) {
  const parsedOldNumber =
    typeof oldNumber === "string" ? parseFloat(oldNumber) : oldNumber;
  const parsedNewNumber =
    typeof newNumber === "string" ? parseFloat(newNumber) : newNumber;

  const decreaseValue = parsedOldNumber - parsedNewNumber;
  const increaseValue = parsedNewNumber - parsedOldNumber;
  const value = ((a: number) => (parsedNewNumber < parsedOldNumber ? -a : a))(
    ((parsedNewNumber > parsedOldNumber ? increaseValue : decreaseValue) /
      parsedOldNumber) *
      100
  );

  if (!isFinite(value) || isNaN(value)) {
    return 0;
  }

  return value;
}

export const renameFile = (file: File, name: string) =>
  new File([file], name, {
    type: file.type,
    lastModified: file.lastModified,
  });

export const useArrayToMap = <T>(values: T[], property: string = "id") => {
  return useMemo(() => {
    return values.reduce((map, curr) => {
      const key = get(curr, property);

      if (typeof key == "string") {
        map.set(key, curr);
      }

      return map;
    }, new Map<string, T>());
  }, [values, property]);
};
