"use client";

import React, { useEffect } from "react";

type WireframeProps = {
  enabled: boolean;
};

export const Wireframe: React.FC<WireframeProps> = ({ enabled }) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const toggle = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "w") {
        document.documentElement.classList.toggle("wireframe");
      }
    };

    window.addEventListener("keydown", toggle);

    return () => window.removeEventListener("keydown", toggle);
  }, [enabled]);

  return null;
};
