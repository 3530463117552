import styled from "styled-components";

export const BlackLightText = styled.p`
  margin: 0;
  color: #303030;
  font-family: "Gilroy-Light";
`;

export const WhiteLightText = styled.p`
  margin: 0;
  color: #fff;
  font-family: "Gilroy-Light";
`;
