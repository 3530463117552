import { createAction, createReducer } from "redux-act";
import api from "api";
import { handleSessionExpire } from "../alpacaAuth/session";
import unionBy from "lodash/unionBy";
import { getAccountIdForProduct } from "selectors";

const REDUCER = "order";
const NS = `@@${REDUCER}/`;

const _setOrderHistory = createAction(`${NS}SET_ORDER_HISTORY`);
const _setOrderNotFound = createAction(`${NS}SET_ORDER_NOT_FOUND`);
const _setOrderStream = createAction(`${NS}SET_ORDER_STREAM`);
const _setCanceledOrders = createAction(`${NS}SET_CANCELED_ORDERS`);
const _requestOrderHistory = createAction(`${NS}REQUEST_ORDER_HISTORY`);
const _requestOrderStream = createAction(`${NS}REQUEST_ORDER_STREAM`);
const _clearOrderHistory = createAction(`${NS}CLEAR_ORDER_HISTORY`);
/**
 * streamOrders
 */
export const streamOrders = ({ product, status, limit, until, after }) => (
  dispatch,
  getState
) => {
  const { token } = api.getAccountIdAndJWT(getState());

  // Figure out the account id to use
  const accountId = getAccountIdForProduct(getState(), product);

  if (accountId) {
    const currentOrdersStream = (getState() && getState()[accountId]) || false;
    if (currentOrdersStream && currentOrdersStream.awaitingStream)
      return Promise.resolve();

    dispatch(_requestOrderStream({ accountId, limit, until, after }));

    const ordersEndPoint = product === "paper" ? "paperOrders" : "orders";
    return handleSessionExpire(
      api[ordersEndPoint]
        .list(accountId, token, { status, limit, until, after })
        .then((orders) => {
          const lastStreamTime = !orders
            ? null
            : orders.length
            ? orders[0].submitted_at
            : new Date().toJSON();
          dispatch(_setOrderStream({ accountId, orders, lastStreamTime }));
        }),
      dispatch
    );
  }
  return;
};

/**
 * listOrders
 *
 * @param {Object}
 *  - product     The product, paper or live
 *  - status      Is either "open", "closed", or "all" (defaults to all)
 *  - limit       Is by default 50, max 500
 *  - until       Is current date by default (can be specified as '2019-01-01')
 *  - after       Is a long time ago by default
 *  - keyByStatus Will take the response and put the orders into state keyed by status
 *                (useful when viewing multiple tables of orders by status, ie. open vs closed)
 */
export const listOrders = ({
  product,
  status,
  limit,
  until,
  after,
  keyByStatus = false,
  initializeOpen = false,
}) => (dispatch, getState) => {
  const accountId = getAccountIdForProduct(getState(), product);

  if (accountId) {
    const currentAccountOrders =
      (getState() && getState().order && getState().order[accountId]) || false;
    if (currentAccountOrders && currentAccountOrders.awaitingStream)
      return Promise.resolve();

    dispatch(_requestOrderHistory({ accountId, limit, until, after }));

    const ordersEndPoint = product === "paper" ? "paperOrders" : "orders";
    const endpoint = api[ordersEndPoint].list;

    const requests = [
      dispatch(endpoint({ accountId }, { status, limit, until, after })),
    ];
    if (initializeOpen) {
      // add request for open orders
      requests.push(
        dispatch(
          endpoint(
            { accountId },
            {
              status: "open",
              limit,
            }
          )
        )
      );
    }

    return handleSessionExpire(
      Promise.all(requests).then((resp) => {
        for (const orders of resp) {
          const hasMoreHistory = !!orders && orders.length === limit;
          dispatch(
            _setOrderHistory({ accountId, orders, hasMoreHistory, keyByStatus })
          );
        }
      }),
      dispatch
    );
  }
  return;
};

/**
 * cancelAllOrders will cancel all open orders.
 */
export const cancelAllOrders = (product) => (dispatch, getState) => {
  const { token } = api.getAccountIdAndJWT(getState());

  // Figure out the account id to use
  const accountId = getAccountIdForProduct(getState(), product);

  if (accountId) {
    const currentAccount =
      (getState() && getState().order && getState().order[accountId]) || false;
    if (currentAccount && currentAccount.awaitingStream)
      return Promise.resolve();

    const ordersEndPoint = product === "paper" ? "paperOrders" : "orders";
    return handleSessionExpire(
      api[ordersEndPoint].cancelAll(accountId, token).then((canceledOrders) => {
        dispatch(_setCanceledOrders({ accountId, canceledOrders }));
      }),
      dispatch
    );
  }
  return;
};

/**
 * cancelOrders will cancel provided open orders.
 */
export const cancelSelectedOrders = (product, orderIds) => (
  dispatch,
  getState
) => {
  // Figure out the account id to use
  const accountId = getAccountIdForProduct(getState(), product);

  if (accountId) {
    const currentAccount =
      (getState() && getState().order && getState().order[accountId]) || false;
    if (currentAccount && currentAccount.awaitingStream)
      return Promise.resolve();

    const ordersEndPoint = product === "paper" ? "paperOrders" : "orders";
    const requests = orderIds.map((orderId) =>
      dispatch(
        api[ordersEndPoint].cancelOrder({
          accountId,
          orderId,
        })
      )
    );

    return handleSessionExpire(
      Promise.all(requests).then(() => {
        dispatch(
          _setCanceledOrders({
            accountId,
            // UI checks status code on orders
            canceledOrders: orderIds.map((id) => ({ id, status: 204 })),
          })
        );
      }),
      dispatch
    );
  }
  return;
};

/**
 * replaceOrder will replace an order with new order details
 */
export const replaceOrder = (product, orderId, orderDetails) => (
  dispatch,
  getState
) => {
  const accountId = getAccountIdForProduct(getState(), product);

  if (accountId) {
    const ordersEndPoint = product === "paper" ? "paperOrders" : "orders";
    const request = api[ordersEndPoint].replaceOrder(
      {
        accountId,
        orderId,
      },
      orderDetails
    );

    return handleSessionExpire(dispatch(request), dispatch);
  }

  return new Promise();
};

/**
 * clearCanceledOrders clears the state of all previously canceled order UUIDs
 * for either paper or live account.
 */
export const clearCanceledOrders = (product) => (dispatch, getState) => {
  // Figure out the account id to use
  const accountId = getAccountIdForProduct(getState(), product);

  dispatch(_setCanceledOrders({ accountId, canceledOrders: [] }));
};

/**
 * gerOrder fetch details for a single order
 */
export const getOrder = (product, orderId) => (dispatch, getState) => {
  const accountId = getAccountIdForProduct(getState(), product);

  if (accountId) {
    const ordersEndPoint = api[
      product === "paper" ? "paperOrders" : "orders"
    ].getOrder({
      accountId,
      orderId,
    });

    return handleSessionExpire(
      ordersEndPoint()
        .then((order) => {
          dispatch(_setOrderHistory({ accountId, orders: [order] }));
        })
        .catch((e) => {
          if (e.status === 404)
            dispatch(_setOrderNotFound({ accountId, orderId }));
        }),
      dispatch
    );
  }
  return;
};

/**
 * clearOrderHistory
 */
export const clearOrderHistory = () => (dispatch) => {
  dispatch(_clearOrderHistory());
};

// Export this reducer
const initialState = {};

export default createReducer(
  {
    [_setOrderNotFound]: (state, payload) => {
      const { accountId, orderId } = payload;
      const newState = { ...state };
      newState[accountId] = newState[accountId] || {};
      newState[accountId].notFound = newState[accountId].notFound || [];
      newState[accountId].notFound.push(orderId);
      return newState;
    },

    [_setOrderHistory]: (state, payload) => {
      // since this will periodically be called, merge new orders to existing state (don't duplicate data)
      const existingOrders =
        (state[payload.accountId] && state[payload.accountId].orders) || [];
      const newOrders = (payload && payload.orders) || [];
      const concatOrders = unionBy(newOrders, existingOrders, "id");

      const newState = { ...state };
      newState[payload.accountId] = {
        ...newState[payload.accountId],
        awaitingStream: false,
        isInitialized: true,
        lastStreamTime: payload.lastStreamTime,
        hasMoreHistory: payload.hasMoreHistory,
      };

      const openStatusList = [
        "accepted",
        "new",
        "partially_filled",
        "calculated",
        "pending_new",
        "pending_cancel",
        "pending_replace",
        "accepted_for_bidding",
        "held",
        "pending_review",
      ];
      // optional, keeps state size down if not true
      if (payload.keyByStatus) {
        newState[payload.accountId].openOrders = concatOrders.filter((o) => {
          return openStatusList.includes(o.status);
        });
        newState[payload.accountId].closedOrders = concatOrders.filter((o) => {
          return !openStatusList.includes(o.status);
        });
      }

      // always needs to be set
      newState[payload.accountId].orders = concatOrders;
      return newState;
    },

    [_setOrderStream]: (state, payload) => {
      // since this will periodically be called, merge new orders to existing state (don't duplicate data)
      const existingOrders =
        (state[payload.accountId] && state[payload.accountId].orders) || [];
      const newOrders = (payload && payload.orders) || [];
      const concatOrders = unionBy(newOrders, existingOrders, "id");

      const newState = { ...state };
      newState[payload.accountId] = {
        ...newState[payload.accountId],
        awaitingStream: false,
        isInitialized: true,
        lastStreamTime: payload.lastStreamTime,
        orders: concatOrders,
      };
      return newState;
    },

    [_setCanceledOrders]: (state, { accountId, canceledOrders }) => {
      const newState = { ...state };
      newState[accountId].canceledOrders = canceledOrders;
      return newState;
    },

    [_requestOrderHistory]: (state, { accountId }) => {
      // ie. order.bfe87e76-5ad3-41d6-a12d-918142bb38df.positions
      const newState = { ...state };
      newState[accountId] = { ...newState[accountId], awaitingStream: true };
      return newState;
    },

    [_requestOrderStream]: (state, { accountId }) => {
      // ie. order.bfe87e76-5ad3-41d6-a12d-918142bb38df.positions
      const newState = { ...state };
      newState[accountId] = { ...newState[accountId], awaitingStream: true };
      return newState;
    },

    [_clearOrderHistory]: () => {
      return initialState;
    },
  },
  initialState
);
