import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Icon, Button } from "antd";
import cn from "classnames";

import ProfileMenu from "./ProfileMenu";
import SearchInput from "./SearchInput";
import styled from "styled-components";
import "./style.scss";
import AmplitudeProvider from "src/AmplitudeProvider";
import { initUser } from "src/reducers/auth/common";
import { isPaperOrOnboarding } from "src/selectors";
import { eventTracker } from "src/utils/eventTracker";

const DepositButton = styled(Button)`
  background-color: #fcdf55;
  border-color: #fcdf55;
  color: black;
  margin-right: 2.5rem;
  min-height: 40px;
  min-width: 60px;

  @media (max-width: 615px) {
    display: none;
  }

  &:hover {
    border-color: black;
    background-color: rgb(0, 0, 0, 0.4);
    color: black;
    background-color: #fcdf55;
  }
`;

const TopBarSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SearchBoxContainer = styled(TopBarSubContainer)`
  width: 60%;
`;

const mapStateToProps = (state) => {
  const { auth, account } = state;
  return {
    account,
    isLoggedIn: auth && auth.userState && auth.userState.username,
  };
};

@connect(mapStateToProps)
@withRouter
class TopBar extends React.Component {
  static defaultProps = {
    showBrandInHeader: false,
    showSearchInHeader: true,
    className: "",
  };

  componentDidMount() {
    if (!this.props.isLoggedIn) {
      this.props.dispatch(initUser(true));
    }
  }

  static propTypes = {
    showBrandInHeader: PropTypes.bool,
    showSearchInHeader: PropTypes.bool,
  };

  render() {
    const {
      product,
      isLoggedIn,
      showBrandInHeader,
      showSearchInHeader,
      className,
      account,
      history,
    } = this.props;
    const isLive = product === "live";
    const accNum = account?.account?.account_number;

    // show to 50% of ACTIVE users
    const halfAccounts = parseInt(accNum) % 2 === 0;
    const showFundAccountButton =
      halfAccounts && account?.account?.status === "ACTIVE";

    // if defined pass account to AmplitudeProvider
    if (account?.details && account?.account) {
      AmplitudeProvider.setAccount(account);
    }

    return (
      <div
        className={cn(
          className,
          isLive ? "topbar topbar-live" : "topbar topbar-paper"
        )}
      >
        <SearchBoxContainer>
          {showBrandInHeader && (
            <Link to="/">
              <img
                src="/resources/images/logo.png"
                alt="Alpaca"
                className="brand-logo"
              />
            </Link>
          )}
          {showSearchInHeader && (
            <>
              <Icon type="search" className="search-icon" />
              <SearchInput product={product} placeholder="Search symbols" />
            </>
          )}
        </SearchBoxContainer>
        <TopBarSubContainer>
          {
            <DepositButton
              amplitude-event-name="navbar_deposit_funds_button_clicked"
              onClick={() => {
                if (isPaperOrOnboarding(account)) {
                  history.push("/brokerage/new-account");
                  return;
                }

                eventTracker("Clicked on Fund Account from Top Bar", {
                  category: "Banking",
                  label: "Top Bar Fund Account Button",
                });

                history.push("/brokerage/banking");
              }}
            >
              {isPaperOrOnboarding(account)
                ? "Open Live Account"
                : "Deposit Funds"}
            </DepositButton>
          }
          <ProfileMenu isLoggedIn={isLoggedIn} />
        </TopBarSubContainer>
      </div>
    );
  }
}

export default TopBar;
