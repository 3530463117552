import useRequest, { QueryOptions } from "./useRequest";

import { getEliteNetDeposits } from "../rest/elite";

export const useGetEliteNetDeposits = (
  key: any | any[] = "elite-net-deposit",
  accountID: string,
  options?: QueryOptions<string>
) => {
  const { data } = useRequest(key, () => getEliteNetDeposits(accountID), {
    ...options,
  });

  return { netDeposit: data };
};

export default useGetEliteNetDeposits;
