import React from "react";

import { Input, InputProps } from "@chakra-ui/react";

export const MaskedDate = (props: InputProps) => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    // strip non-numeric characters or forward slash, from value
    event.currentTarget.value = event.currentTarget.value.replace(
      /[^0-9/]/g,
      ""
    );

    // since we have overriden the onChange event, we need to call the original
    props.onChange && props.onChange(event);
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.value;

    // handle user input of forward slash
    if (value.length === 2 || value.length === 5) {
      event.key !== "/" &&
        /[0-9]/.test(String.fromCharCode(event.which)) &&
        (event.currentTarget.value += "/");
    } else if (event.key === "/") {
      event.preventDefault();
    }
  };

  return (
    <Input
      {...props}
      onPaste={(e) => e.preventDefault()}
      size="lg"
      placeholder="dd/mm/yyyy"
      maxLength={10}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};

export default MaskedDate;
