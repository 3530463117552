import useRequest, { QueryOptions } from "./useRequest";

import { BankInstitution, getBankInstitution } from "../rest/banking";
import { QueryObserverResult } from "react-query";

type UseGetBankInstitution = {
  institution?: BankInstitution;
  refetch: () => Promise<QueryObserverResult<BankInstitution, unknown>>;
};

export const useGetBankInstitution = (
  institutionId: string,
  key: any | any[] = "",
  options?: QueryOptions<BankInstitution>
): UseGetBankInstitution => {
  const { data: institution, refetch } = useRequest(
    "bank-institution" + key,
    () => getBankInstitution(institutionId),
    {
      ...options,
      refetchInterval: false,
    }
  );

  return { institution, refetch };
};
