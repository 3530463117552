import useRequest, { QueryOptions } from "./useRequest";

import { Asset, getAsset } from "../rest";

export const useGetAsset = (
  key: any | any[] = "",
  symbol: string,
  options?: QueryOptions<Asset>
) => {
  const { data, refetch, isFetched } = useRequest(key, () => getAsset(symbol), {
    ...options,
    cacheTime: 9999999,
    refetchInterval: false,
  });

  return { asset: data, refetch, isFetched };
};

export default useGetAsset;
