import { createAction, createReducer } from "redux-act";
import { handleSessionExpire } from "../alpacaAuth/session";
import api from "api";
import { getAccountIdForProduct } from "selectors";

// access keys
const _setOAuthClients = createAction(`SET_OAUTH_CLIENTS`);

/**
 * listOAuthClients will retrieve all oauth application connections
 */
export const listOAuthClients = () => (dispatch, getState) => {
  const accountId = getAccountIdForProduct(getState(), "live");

  if (accountId) {
    return handleSessionExpire(
      api.oauth.client
        .list({ accountId })(dispatch, getState)
        .then((data) => dispatch(_setOAuthClients(data)))
        .catch(console.error),
      dispatch
    );
  }
  return;
};

const initialState = {
  activeClients: [],
};

// Export this reducer
export default createReducer(
  {
    // Account oauth clients
    [_setOAuthClients]: (state, payload) => {
      const oauthClients = state.oauthClients || payload;
      return { ...state, activeClients: oauthClients };
    },
  },
  initialState
);
