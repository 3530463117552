import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { pendingTasksReducer } from "react-redux-spinner";

import app from "./app";
import auth from "./auth/common";
import cognito from "./auth/cognito";
import accountList from "./auth/accountList";
import mfa from "./auth/mfa";
import profile from "./profile";
import userData from "./userData";
import oauthClients from "./oauth/clients";

import account from "./account";
import accountDocuments from "./accountDocuments";
import accessKeys from "./accessKeys";
import transfer from "./transfer";
import portfolio from "./portfolio/portfolio";
import position from "./position/position";
import order from "./order/order";
import profitloss from "./profitloss/profitloss";
import dataAgreements from "./data-agreements";
import trade from "./trade/trade";
import oauth from "./oauth";
import billing from "./billing";
import countryInfo from "./countryInfo";

// Adds redux forms
import { reducer as formReducer } from "redux-form";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    pendingTasks: pendingTasksReducer,
    app,
    auth,
    cognito,
    accountList,
    mfa,
    profile,
    userData,
    account,
    accountDocuments,
    accessKeys,
    transfer,
    trade,
    portfolio,
    position,
    order,
    profitloss,
    dataAgreements,
    form: formReducer,
    oauth,
    oauthClients,
    billing,
    countryInfo,
  });

export default createRootReducer;
