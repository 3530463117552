import { createAction, createReducer } from "redux-act";
import { notification } from "antd";
import Auth from "@aws-amplify/auth";
import { getCurrentAuthenticatedUser } from "../../utils";

export const REDUCER = "mfa";
const NS = `@@${REDUCER}/`;

const _setCurrentMFAMethod = createAction(`${NS}SET_CURRENT_MFA_METHOD`);
const _setTOTPQRCode = createAction(`${NS}SET_TOTP_QR_CODE`);
const _setTOTSetupVerificationError = createAction(
  `${NS}SET_TOTP_SETUP_VERIFICATION_ERROR`
);

// Helper to remove magic, note that `SOFTWARE_TOKEN_MFA` is newer than `TOTP` though both
// work for SETTING the MFA method with `setPreferredMFA()` but, `getPreferredMFA()`
// returns `SOFTWARE_TOKEN_MFA` - there was some deprecation and changes going on in Amplify.
// Similar for `SMS_MFA` vs. older `SMS`.
export const MFAMethods = {
  SOFTWARE_TOKEN_MFA: {
    method: "SOFTWARE_TOKEN_MFA",
    displayName: "time-based one-time password (TOTP)",
  },
  SMS_MFA: {
    method: "SMS_MFA",
    displayName: "text-message (SMS)",
  },
  NOMFA: {
    method: "NOMFA",
    displayName: "",
  },
};

/**
 * Enables MFA and shows a notification to the user.
 *
 * @param {string} method The 2FA method, "SMS_MFA", "SOFTWARE_TOKEN_MFA", "NOMFA"
 */
export const enableMFA = (method = MFAMethods["SMS_MFA"].method) => (
  dispatch
) => {
  getCurrentAuthenticatedUser().then((user) => {
    // Note: As of March 14th, 2019, AWS Amplify JS documentation shows
    // `SMS`, `TOTP`, or `NOMFA` as options. In their GitHub, it shows
    // either those OR the newer `SOFTWARE_TOKEN_MFA` and `SMS_MFA` options.
    // Our code will standardize on the newer values. However, this method
    // will temporarily need to catch this until AWS updates.
    if (method === MFAMethods["SMS_MFA"].method) {
      method = "SMS";
    }
    if (method === MFAMethods["SOFTWARE_TOKEN_MFA"].method) {
      method = "TOTP";
    }

    // NOTE: The same bug afflicting `getPreferredMFA()` below
    // https://github.com/aws-amplify/amplify-js/issues/2627
    user.getUserData(
      () => {
        // The part we care about
        Auth.setPreferredMFA(user, method)
          .then(() => {
            dispatch(currentMFAMethod());
          })
          .catch((err) => {
            console.error("error setting MFA method:", err);
          });
        //
      },
      { bypassCache: true }
    );
  });
};

/**
 * Disables MFA and shows a notification to the user.
 */
export const disableMFA = () => (dispatch) => {
  return getCurrentAuthenticatedUser().then((user) => {
    // NOTE: The same bug afflicting `getPreferredMFA()` below
    // https://github.com/aws-amplify/amplify-js/issues/2627
    return user.getUserData(
      () => {
        // The part we care about
        return Auth.setPreferredMFA(user, MFAMethods["NOMFA"].method).then(
          () => {
            notification.open({
              type: "success",
              message: "You have disabled MFA for your account.",
            });
            return dispatch(currentMFAMethod());
          }
        );
        //
      },
      { bypassCache: true }
    );
  });
};

/**
 * Gets the user's preferred MFA method.
 *
 * NOTE: There is currently a bug https://github.com/aws-amplify/amplify-js/issues/2627
 * An old, cached, MFA method will be returned when enabling/disabling MFA methods.
 * That is to say, changing MFA methods does not update the cache on AWS end.
 * Calling `getUserData()` with `byPassCache` param true will ensure it's fresh.
 * Once this is fixed, the getUserData call below can be removed.
 */
export const currentMFAMethod = () => (dispatch) => {
  return getCurrentAuthenticatedUser().then((user) => {
    // Temporary work around for known AWS bug
    return user.getUserData(
      () => {
        // The part we care about
        return Auth.getPreferredMFA(user)
          .then((response) => {
            dispatch(_setCurrentMFAMethod(response));
            return response;
          })
          .catch((err) => {
            console.debug(err);
          });
        //
      },
      { bypassCache: true }
    );
  });
};

/**
 * Gets the TOTP code from Cognito to allow a user to set up
 * software based MFA. Note that the code must be converted to
 * a QR code in order for it to be scanned.
 *
 * Note: https://github.com/aws-amplify/amplify-js/issues/1226
 * If a user goes to set up a new TOTP application, it will silently disable TOTP MFA.
 * We should first make the user disable TOTP MFA before setting up a new application.
 * This way, if a user abandons the set up process, they won't disable their existing TOTP MFA
 * without knowing. The user needs to first disable TOTP MFA, verify a new one, and that verification
 * will then re-enable the TOTP MFA.
 */
export const setupTOTP = () => (dispatch) => {
  getCurrentAuthenticatedUser().then((user) => {
    Auth.setupTOTP(user).then((code) => {
      const authCode = `otpauth://totp/${
        user.attributes.email || ""
      }?secret=${code}&issuer=Alpaca`;
      dispatch(_setTOTPQRCode(authCode));
    });
  });
};

/**
 * Verifies a TOTP token generated by an TOTP-generating application.
 * TOTP MFA Will then be enabled for the user upon success.
 */
export const verifyTOTPToken = (challengeAnswer) => (dispatch) => {
  getCurrentAuthenticatedUser().then((user) => {
    Auth.verifyTotpToken(user, challengeAnswer)
      .then(() => {
        // Note that there is a resonse, it's {Status: "SUCCESS"}
        // It should always be a succes, it will throw errors.
        dispatch(_setTOTPQRCode(null));
        dispatch(enableMFA(MFAMethods["SOFTWARE_TOKEN_MFA"].method));
      })
      .catch((err) => {
        console.error(err);
        dispatch(_setTOTSetupVerificationError(err));
      });
  });
};

const initialState = {
  method: null,
  TOTPQRCode: null,
  TOTPQRVerificationError: null,
};
export default createReducer(
  {
    [_setCurrentMFAMethod]: (state, method) => ({ ...state, method }),
    [_setTOTPQRCode]: (state, TOTPQRCode) => ({ ...state, TOTPQRCode }),
    [_setTOTSetupVerificationError]: (state, TOTPSetupVerificationError) => ({
      ...state,
      TOTPSetupVerificationError,
    }),
  },
  initialState
);
