import _ from "lodash";
import AmplitudeProvider from "src/AmplitudeProvider";
import useGetAssets from "../../../api/hooks/useGetAssets";

import {
  UIButton,
  UIIcon,
  UINavbar,
} from "../../../../../src/local_libraries/@alpacahq:ui-deprecated";

import {
  useGetAccount,
  useGetBillingOverview,
  useGetOptionsApprovalStatus,
} from "src/v2/api/hooks";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Icon, SidebarState, UIContext } from "@alpacahq/ui";
import { Box, BoxProps } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { isPaperOrOnboarding } from "src/v2/utils";
import { isFeatureAccessible, useFlag } from "../../../../v2/helpers/flags";
import { Asset } from "../../../api/rest";
import { getCryptoAssets } from "../../../api/rest/data";
import { AssetLike } from "../../../pages/dashboard/trade/Trade";
import { Path } from "../../../path";
import { isOptionsOnboardingAvailable } from "src/v2/pages/dashboard/optionsOptIn/util";
import { FUNDING_FEATURE_FLAG } from "../page";

const BANKING_AND_ONBOARDING_PATHS = [
  Path.ROUTE_BANKING,
  Path.ROUTE_BANKING_DEPOSIT,
  Path.ROUTE_NEW_ACCOUNT,
  Path.ROUTE_ENTITY_ONBOARDING_PROFILE,
  Path.ROUTE_ENTITY_ONBOARDING_ACCOUNT_OPENER,
  Path.ROUTE_ENTITY_ONBOARDING_AUTH_INDIVIDUALS_UBOS,
  Path.ROUTE_ENTITY_ONBOARDING_DOCUMENTS,
];

const SEARCH_RESULTS_LIMIT = 10;

// a map of assets sorted by first letter and then word groups for fast searching
type AssetMap = {
  // letter group
  [key: string]: {
    // word group
    [key: string]: Partial<Asset>;
  };
};

// Desktop Options Opt In button - shortens text on small screens, on top bar
const OptionsTopBarButton = ({ onClick }: { onClick: () => void }) => (
  <UIButton
    onClick={onClick}
    variant="skeleton"
    className="hidden min-[479px]:flex border-2 border-yellow-300"
  >
    <span className="hidden min-[600px]:inline pr-1">Get Started with</span>
    <span className="min-[600px]:hidden">
      <UIIcon name="Plus" className="h-4 w-4 mr-2" />
    </span>
    Options
  </UIButton>
);

// Mobile Options Opt In button - full width centered text, below top bar
const OptionsMobileButton = ({ onClick }: { onClick: () => void }) => (
  <UIButton
    onClick={onClick}
    variant="skeleton"
    className="min-[479px]:hidden mx-8 mb-6 border-2 border-yellow-300 justify-center"
  >
    Get Started with Options
  </UIButton>
);

export const TopBar = (props: BoxProps & { product: string }) => {
  const v2 = useFlag("uix-v2");
  const history = useHistory();

  const [assets, setAssets] = useState<AssetLike[]>([]);

  const { sidebarState, setSidebarState } = useContext(UIContext);
  const { account, isAuthenticated } = useGetAccount();
  const { billing } = useGetBillingOverview("billing", {
    enabled: isAuthenticated,
  });

  const { data: crypto } = useQuery("crypto", getCryptoAssets);

  const { assets: equities } = useGetAssets("equities", {
    enabled: !!isAuthenticated,
  });

  const { data: optionsApprovalStatus } = useGetOptionsApprovalStatus(
    ["options-opt-in", account?.id],
    account?.id,
    {
      enabled: !!account?.id,
    }
  );

  // normalize asset symbol and name for searching
  const normalize = useCallback(
    (value: string) =>
      String(value)
        .toUpperCase()
        .trim()
        .replace(/[^a-z\s-]/gi, ""),
    []
  );

  useEffect(() => {
    // filter out assets that are already in the list
    const filtered = (crypto || [])
      .concat(equities)
      .filter((asset) => !assets.some((a) => a.symbol === asset.symbol));

    // add new assets to the list
    if (filtered.length) {
      setAssets([...assets, ...filtered]);
    }
  }, [equities, crypto]);

  // memoize assets into index map for fast searching
  const assetMap = useMemo<AssetMap>(
    () =>
      assets
        .filter((asset) => asset.symbol)
        .map(({ symbol, name }) => ({ symbol, name }))
        .reduce((prev, next) => {
          const symbol = normalize(next.symbol);
          const name = normalize(next.name);
          const keyForSymbol = symbol[0];
          const keyForName = name[0];

          // create symbol character group
          if (!prev[keyForSymbol]) {
            prev[keyForSymbol] = {};
          }

          // create name character group
          if (!prev[keyForName]) {
            prev[keyForName] = {};
          }

          // add asset to symbol and name groups
          prev[keyForSymbol][symbol] = next;
          prev[keyForName][name] = next;

          return prev;
        }, {} as AssetMap),
    [assets]
  );

  const onSearch = useCallback(
    (query: string) => {
      // normalize query string for searching
      const normalized = normalize(query);

      // find and set match based on first letter
      const match = assetMap[normalized.charAt(0)];

      // if no match, set empty array
      if (!match) {
        return [];
      }

      // sort by symbol in descending alphabetic order
      const matched = Object.keys(match)
        .filter((key) => key.startsWith(normalized))
        .map((key) => match[key])
        .sort((a, b) => {
          const matchA = a.symbol?.startsWith(normalized);
          const matchB = b.symbol?.startsWith(normalized);

          // if both match, sort
          if (matchA && matchB) {
            return (a.symbol?.length || 0) - (b.symbol?.length || 0);
          }

          if (matchA) {
            // negative number means a comes first
            return -1;
          } else if (matchB) {
            // positive number means b comes first
            return 1;
          } else {
            // if neither match, return 0
            return 0;
          }
        });

      return (
        _.uniqBy(matched, (asset) => asset.symbol)
          // we only care about the first X results
          .slice(0, SEARCH_RESULTS_LIMIT)
          // filter out assets without symbol
          .filter(({ symbol }) => !!symbol)
          // map to suggestion object
          .map(
            // cast as string because we already filtered out assets without symbol or name
            ({ symbol, name }) => ({
              label: symbol as string,
              description: name || "no asset description found",
              href: Path.ROUTE_TRADE.replace(":symbol", symbol as string),
            }),
            []
          )
      );
    },
    [assetMap]
  );

  // handle the deposit or open account button click
  const onDepositOrOpenAccountClick = useCallback(() => {
    // track the event
    AmplitudeProvider.dispatch(
      props.product === "paper"
        ? "navbar_open_live_account_button_clicked"
        : "navbar_deposit_funds_button_clicked"
    );

    // if the account is paper or onboarding, go to the new account page
    if (props.product === "paper") {
      history.push(Path.ROUTE_NEW_ACCOUNT);
    } else {
      // if the account is not paper or onboarding, go to the deposit page
      useFlag("uix-v2")
        ? history.push(
            isFeatureAccessible(FUNDING_FEATURE_FLAG)
              ? Path.ROUTE_FUNDING
              : Path.ROUTE_BANKING_DEPOSIT
          )
        : history.push(Path.ROUTE_BANKING);
    }
  }, [history, props.product]);

  const onDataSubscriptionClick = useCallback(() => {
    history.push(
      (billing?.lifetimeSubCount || 0) > 0
        ? // if the user has a subscription, go to the subscription page
          Path.ROUTE_USER_SUBSCRIPTION
        : // if the user does not have a subscription, go to the new subscription page
          Path.ROUTE_USER_SUBSCRIPTION_NEW
    );
  }, [history, billing]);

  // handle state transition logic
  const handleSidebarState = (currentSidebarState: SidebarState) => {
    switch (currentSidebarState) {
      case SidebarState.HIDDEN:
        return SidebarState.MOBILE;
      case SidebarState.MOBILE:
        return SidebarState.HIDDEN;
      case SidebarState.EXPANDED:
        return SidebarState.COLLAPSED;
      case SidebarState.COLLAPSED:
        return SidebarState.EXPANDED;
      default:
        localStorage.removeItem("sidebarState");
        return null;
    }
  };

  const isInBankingOrOnboardingPath = BANKING_AND_ONBOARDING_PATHS.some(
    (path) => window.location.pathname.includes(path)
  );

  const isDepositOrOpenAccountBtnDisplayed =
    // hide button while onboarding
    !isInBankingOrOnboardingPath &&
    (isPaperOrOnboarding(account) || props.product === "live") &&
    // hide button if live account cannot add funds
    !(
      props.product === "live" &&
      account?.status !== "ACTIVE" &&
      account?.status !== "ACCOUNT_UPDATED"
    );

  // Only display the options opt-in button for
  // people who have not applied
  const isOptionsButtonDisplayed = useMemo(() => {
    if (optionsApprovalStatus) {
      return (
        isOptionsOnboardingAvailable(account, optionsApprovalStatus, {
          allowLegalEntities: true,
        }) && !optionsApprovalStatus?.OptionsRequestStatus
      );
    }

    return false;
  }, [optionsApprovalStatus]);

  const onOptionsClick = () => {
    if (account?.is_legal_entity) {
      history.push(Path.ROUTE_OPTIONS_OPT_IN_ENTITY_ACCOUNT);
      return;
    }

    history.push(Path.ROUTE_OPTIONS_OPT_IN_FINANCIAL_PROFILE);
  };

  return (
    <Box
      id="topbar"
      w="100%"
      zIndex={2}
      display="flex"
      flexDir="column"
      {...props}
    >
      <UINavbar>
        {sidebarState === SidebarState.HIDDEN && (
          <UIButton
            className="px-3"
            onClick={() => {
              const newState = handleSidebarState(sidebarState);
              if (newState !== null) {
                setSidebarState(newState);
                localStorage.setItem("sidebarState", newState);
              }
            }}
          >
            <Icon className="h-4 w-4" name="Bars3" />
          </UIButton>
        )}
        <UINavbar.Search
          placeholder="Search"
          onSearch={onSearch}
          shortcut="none"
        />
        {sidebarState !== SidebarState.MOBILE && (
          <>
            <UINavbar.Spacer />
            {isOptionsButtonDisplayed && (
              <UINavbar.Item>
                <OptionsTopBarButton onClick={onOptionsClick} />
              </UINavbar.Item>
            )}
            <UINavbar.Item>
              {isDepositOrOpenAccountBtnDisplayed && (
                <UIButton onClick={onDepositOrOpenAccountClick}>
                  {props.product === "paper" ? (
                    "Open Live Account"
                  ) : (
                    <>
                      <UIIcon name="Plus" className="h-4 w-4 mr-2" />
                      Add Funds
                    </>
                  )}
                </UIButton>
              )}
            </UINavbar.Item>
          </>
        )}
      </UINavbar>
      {isOptionsButtonDisplayed && (
        <OptionsMobileButton onClick={onOptionsClick} />
      )}
    </Box>
  );
};

export default TopBar;
