import { createAction, createReducer } from "redux-act";
import { pendingTask, begin, end } from "react-redux-spinner";

const REDUCER = "app";
const NS = `@@${REDUCER}/`;

const _setLoading = createAction(`${NS}SET_LOADING`);

export const setFormInvalid = createAction(`${NS}SET_FORM_INVALID`);
export const setUpdatingContent = createAction(`${NS}SET_UPDATING_CONTENT`);
export const setActiveDialog = createAction(`${NS}SET_ACTIVE_DIALOG`);
export const deleteDialogForm = createAction(`${NS}DELETE_DIALOG_FORM`);
export const addSubmitForm = createAction(`${NS}ADD_SUBMIT_FORM`);
export const deleteSubmitForm = createAction(`${NS}DELETE_SUBMIT_FORM`);
export const setLayoutState = createAction(`${NS}SET_LAYOUT_STATE`);
export const setOpenKeys = createAction(`${NS}SET_OPENKEYS_STATE`);

export const setLoading = (isLoading) => {
  const action = _setLoading(isLoading);
  action[pendingTask] = isLoading ? begin : end;
  return action;
};

const initialState = {
  // APP STATE
  from: "",
  isUpdatingContent: false,
  isLoading: false,
  activeDialog: "",
  dialogForms: {},
  submitForms: {},
  invalidForms: {},
  openKeys: [],

  // LAYOUT STATE
  layoutState: {
    isMenuTop: false,
    disableAppMenu: false,
    menuMobileOpened: false,
    menuCollapsed: false,
    menuShadow: true,
    showBrandInHeader: false,
    themeLight: false,
    settingsOpened: false,
  },
};

export default createReducer(
  {
    [_setLoading]: (state, isLoading) => ({ ...state, isLoading }),
    [setFormInvalid]: (state, param) => {
      // Sets forms (by id) invalid with a message or just boolean true.
      const invalidForms = {
        ...state.submitForms,
        [param.id || "all"]: param.message || true,
      };
      return { ...state, invalidForms };
    },
    [setUpdatingContent]: (state, isUpdatingContent) => ({
      ...state,
      isUpdatingContent,
    }),
    [setLayoutState]: (state, param) => {
      const layoutState = { ...state.layoutState, ...param };
      const newState = { ...state, layoutState };
      window.localStorage.setItem(
        "app.layoutState",
        JSON.stringify(newState.layoutState)
      );
      return newState;
    },
    [setOpenKeys]: (state, openKeys) => ({ ...state, openKeys }),
    [setActiveDialog]: (state, activeDialog) => {
      const result = { ...state, activeDialog };
      if (activeDialog !== "") {
        const id = activeDialog;
        result.dialogForms = { ...state.dialogForms, [id]: true };
      }
      return result;
    },
    [deleteDialogForm]: (state, id) => {
      const dialogForms = { ...state.dialogForms };
      delete dialogForms[id];
      return { ...state, dialogForms };
    },
    [addSubmitForm]: (state, id) => {
      const submitForms = { ...state.submitForms, [id]: true };
      return { ...state, submitForms };
    },
    [deleteSubmitForm]: (state, id) => {
      const submitForms = { ...state.submitForms };
      delete submitForms[id];
      return { ...state, submitForms };
    },
  },
  initialState
);
