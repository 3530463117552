// todo: support v2
export const getHasFinishedOnboarding = (details) =>
  details?.margin_agreement_signed_at || details?.customer_agreement_signed_at;

export const getAgreementPending = (details, cryptoEnabled) => {
  // if crypto enabled and agreement not yet signed
  if (cryptoEnabled && !details?.crypto_agreement_signed_at) {
    return true;
  }

  // if margin or customer agreement not yet signed
  if (!getHasFinishedOnboarding(details)) {
    return true;
  }

  // no pending agreements
  return false;
};

export const getScrolledToBottom = (id, element) => {
  const parent = element.target.getBoundingClientRect();
  const child = document.getElementById(id).getBoundingClientRect();

  return child.y <= parent.y + parent.height;
};
