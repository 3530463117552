import useRequest, { QueryOptions } from "./useRequest";
import { OwnerDocument, getOwnerDocuments } from "../rest/entityOnboarding";

export const useGetOwnerDocuments = (
  key: any | any[] = "",
  accountId: string,
  ownerId: string,
  options?: QueryOptions<OwnerDocument[]>
) => {
  const { data, refetch } = useRequest(
    key,
    () => getOwnerDocuments(accountId, ownerId),
    {
      refetchInterval: false,
      enabled: !!ownerId && !!accountId,
      ...options,
    }
  );

  return { documents: data ?? [], refetch };
};

export default useGetOwnerDocuments;
