import { Subject } from "rxjs";
import { getAccountIdForProduct, getApiToken } from "selectors/index";
import { actions } from "store";

const liveEndpoint = window.env.STREAM_ENDPOINT;
const paperEndpoint = window.env.PAPER_STREAM_ENDPOINT;

const subjects = {
  [liveEndpoint]: new Subject(),
  [paperEndpoint]: new Subject(),
};
const sockets = { [liveEndpoint]: null, [paperEndpoint]: null };

export function init() {
  let token = null;

  actions.subscribe(([_, state]) => {
    if (getApiToken(state) !== token) {
      token = getApiToken(state);
      const accountId = getAccountIdForProduct(state, "paper");

      if (token && accountId) {
        connect(liveEndpoint, token);
        connect(paperEndpoint, token);
      }
    }
  });
}

function connect(endpoint, token) {
  const authMsg = JSON.stringify({
    action: "authenticate",
    data: {
      cognito_token: token,
    },
  });

  // updated auth token
  if (sockets[endpoint]) {
    sockets[endpoint].send(authMsg);
    return;
  }

  const socket = new WebSocket(endpoint);
  sockets[endpoint] = socket;

  socket.onmessage = (message) => {
    const reader = new FileReader();
    reader.onload = function () {
      const message = JSON.parse(this.result);
      if (message.stream === "authorization") {
        if (message.data.status !== "authorized") {
          console.error("/stream unauthorized", message.data);
          return;
        }
        socket.send(
          JSON.stringify({
            action: "listen",
            data: {
              streams: ["trade_updates"],
            },
          })
        );
      } else if (message.stream === "trade_updates") {
        subjects[endpoint].next(message);
      }
    };
    reader.readAsText(message.data);
  };
  socket.onerror = (err) => {
    console.error("socket error", err);
  };
  socket.onclose = () => {
    console.error("socket closed, trying to reconnect in 15 second");
    setTimeout(() => connect(endpoint, token), 15000);
  };
  socket.onopen = () => socket.send(authMsg);
}

// Subscribe to both websockets, but only forward them to Elm if the stream matches the current product.
export default {
  bind(subscription, isLive, ports) {
    subscription.add(
      subjects[liveEndpoint].subscribe((message) => {
        if (isLive()) {
          ports.events_.send(message);
        }
      })
    );
    subscription.add(
      subjects[paperEndpoint].subscribe((message) => {
        if (!isLive()) {
          ports.events_.send(message);
        }
      })
    );
  },
};
