import * as auth from "./common";
import Auth from "@aws-amplify/auth";
import { eraseCookie } from "lib/cookies";
import { getCurrentAuthenticatedUser } from "../../utils";

/**
 * Logs the user out from Cognito (clears cookies, and wipes local storage if needed because
 * it's apparently too much to ask of Amplify rofl).
 */
export const logout = () => (dispatch) => {
  getCurrentAuthenticatedUser()
    .then((currentUser) => {
      // make sure to delete cookie for subdomain
      eraseCookie("dashboard.authtoken");
      // Try to get fresh data (setting bypassCache true)
      // https://github.com/aws-amplify/amplify-js/issues/2627
      currentUser.getUserData(() => dispatch(signOutGlobal()), {
        bypassCache: true,
      });
    })
    .catch((e) => {
      console.debug(
        "Error getting current user before trying to log out. Log out anyway.",
        e
      );
      dispatch(signOutGlobal());
    });
};

// By using `global: true`, all the auth tokens are revoked (id token, access token and refresh token)
// which means the user is signed out from all the devices
// Note: although the tokens are revoked, the AWS credentials will remain valid until they expire (which by default is 1 hour)
const signOutGlobal = () => (dispatch) => {
  Auth.signOut({ global: true })
    .then(() => dispatch(cleanUpAndRedirect()))
    .catch(() => dispatch(signOutLocal()));
};

// If signOut with `global: true` throws, then we're probably already logged out globally so just log out this session
const signOutLocal = () => (dispatch) => {
  Auth.signOut()
    .catch(() => dispatch(clearCookieStorage()))
    .finally(() => dispatch(cleanUpAndRedirect()));
};

/**
 * Friendlier cleanup tasks.
 */
export const cleanUpAndRedirect = () => (dispatch) => {
  dispatch(
    auth.setUserState({
      userState: {},
    })
  );
  const { pathname, search } = window.location;
  window.sessionStorage.setItem("requested_path", pathname + search);
  window.localStorage.setItem("user", "");
  window.location = "/login";
};

/**
 * Kill it all with fire. Sometimes its safer to wipe all cookies.
 * See: https://github.com/aws-amplify/amplify-js/issues/614
 * and: https://github.com/aws-amplify/amplify-js/issues/2605
 */
export const clearCookieStorage = () => {
  console.debug("Removing Cognito cookies.");
  try {
    const cookies = document.cookie.split(";");
    cookies
      .filter((value) => value.match(/CognitoIdentityService.*/))
      .forEach((value) => {
        eraseCookie(value.split("=")[0]);
      });
    // make sure to delete cookie for subdomain
    eraseCookie("dashboard.authtoken");
  } catch (e) {
    console.error(`An error occured clearing cookies: ${e}`);
  }
};
