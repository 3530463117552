import TetherComponent from "react-tether";

import React, { useState, useEffect, useContext, useCallback } from "react";

import { Icon } from "@alpacahq/ui";
import { Button } from "@alpacahq/ui";
import { UIContext, getHashCode } from "@alpacahq/ui";
import { SidebarItemProps, SidebarItem } from "./SidebarItem";

export interface SidebarSettingsProps {
  settings: SidebarItemProps[];
}

export const SidebarSettings: React.FC<SidebarSettingsProps> = ({
  settings,
}) => {
  const { setIsSidebarBlurred } = useContext(UIContext);

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpacity, setDropdownOpacity] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    // blur the sidebar when the dropdown is open to focus on the dropdown
    setIsSidebarBlurred(isOpen);
  }, [isOpen]);

  useEffect(() => {
    // close the dropdown when clicking outside of it
    // granted it may be a click on the dropdown itself, but
    // we want to close it anyway when you click an item
    const handleClickOutside = () => setIsOpen(false);
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // due to positioning issues with the dropdown, we need to force a re-render after a delay
  // the dropdown will not get cut-off from parent scroll containers this way
  const toggleOpen = (event: any) => {
    event.stopPropagation();

    setIsOpen(!isOpen);
    setDropdownOpacity(0);

    if (!isOpen) {
      setTimeout(() => {
        setForceUpdate(true);
        setDropdownOpacity(1);
      }, 100);
    }
  };

  const handleUpdate = useCallback(() => {
    // force a re-render after the first update
    if (forceUpdate) {
      setForceUpdate(false);
    }
  }, [forceUpdate]);

  return (
    <TetherComponent
      // @ts-ignore for some reason the types are not correct
      attachment="bottom left"
      // @ts-ignore for some reason the types are not correct
      targetAttachment="top left"
      // @ts-ignore for some reason the types are not correct
      offset="12px 0"
      onUpdate={handleUpdate}
      renderTarget={(ref) => (
        <Button ref={ref} variant="secondary" onClick={toggleOpen}>
          <Icon name="Cog6Tooth" />
        </Button>
      )}
      renderElement={(ref) =>
        isOpen && (
          <div
            ref={ref}
            style={{ opacity: dropdownOpacity }}
            className="z-50 min-w-[186.4px] space-y-0.5 rounded-md border border-gray-200 bg-white p-1"
          >
            {settings.map((item) => (
              <SidebarItem key={getHashCode(item)} {...item} unresponsive />
            ))}
          </div>
        )
      }
    />
  );
};
