// For more information see:
// https://alpaca.atlassian.net/wiki/spaces/ENG/pages/1612480730/Amplitude+Integration

import React from "react";

import { useFlag } from "src/v2/helpers/flags";

class AmplitudeProvider extends React.Component {
  eventTypes = ["click", "input", "submit", "load", "unload", "custom"];
  eventHistory = new Map();
  eventHistoryTimeout = 60000;
  account;

  constructor(props) {
    super(props);
  }

  static dispatch(name, data) {
    window.dispatchEvent(
      new CustomEvent("custom", {
        detail: {
          "amplitude-event-name": name,
          "amplitude-event-data": data,
        },
      })
    );
  }

  static dispatchPageVisit(eventData = {}) {
    if (!eventData.hasOwnProperty("url")) {
      eventData.url = window.location.pathname;
    }

    window.dispatchEvent(
      new CustomEvent("custom", {
        detail: {
          "amplitude-event-name": "algodash_page_visited",
          "amplitude-event-data": eventData,
        },
      })
    );
  }

  static setAccount = (acc) => {
    this.account = acc;
    getAmplitude().setUserId(acc?.details?.owner_id);
    getAmplitude().setUserProperties({
      country_of_tax_residence: acc?.details?.country_of_tax_residence,
      account_status: acc?.account?.status,
      account_number: acc?.account?.account_number,
      account_id: acc?.account?.id,
      algodash_v2: useFlag("uix-v2"),
    });
  };

  // WARNING: Do not remove! This class is a wrapper for the rest of the app.
  render = () => this.props.children;

  componentDidMount = () =>
    this.eventTypes.forEach((type) =>
      window.addEventListener(type, this.handleEvent.bind(this), false)
    );

  handleEvent = (event) => {
    const eventName = this.getEventName(event);
    const eventData = this.getEventData(event) || {};
    const isPaper = window.location.href.indexOf("paper") > -1;

    if (isPaper && !eventData.hasOwnProperty("is_paper")) {
      eventData.is_paper = true;
    }

    const key = `${eventName}-${JSON.stringify(eventData)}`;
    const rateExceeded =
      Date.now() - (this.eventHistory.get(key) ?? 0) < this.eventHistoryTimeout;

    // If we have an event name and data (optional)
    // If we have not exceeded the rate limit
    if (eventName && !rateExceeded) {
      getAmplitude().logEvent(eventName, eventData);
      this.eventHistory.set(key, Date.now());
    }

    // Clear expired events
    this.eventHistory.forEach((timestamp, key) => {
      if (Date.now() - timestamp > this.eventHistoryTimeout) {
        this.eventHistory.delete(key);
      }
    });
  };

  getEventName = (event) => {
    const { target, detail } = event;
    const attribute = "amplitude-event-name";

    if (target && "getAttribute" in target) {
      return target.getAttribute(attribute);
    }

    if (detail) {
      return detail[attribute];
    }

    return null;
  };

  getEventData = (event) => {
    const { target, detail } = event;

    if (target && "getAttribute" in target) {
      try {
        return JSON.parse(target.getAttribute("amplitude-event-data"));
      } catch {
        // ignore
      }
    }

    if (detail && "amplitude-event-data" in detail) {
      const data = detail["amplitude-event-data"];

      try {
        return JSON.parse(data);
      } catch {
        return data;
      }
    }

    return null;
  };
}

export const getAmplitude = () => window.amplitude?.getInstance();

export default AmplitudeProvider;
