import useRequest, { QueryOptions } from "./useRequest";

import {
  getPaperAccounts,
  Account,
  getPaperAccountsWithDetails,
  PaperAccount,
} from "../rest/account";

/**
 * Returns the paper accounts with all the information as Accounts, instead of the
 * minimum information of PaperAccounts
 * @param options
 * @returns
 */
export const useGetPaperAccountsWithDetails = (
  options?: QueryOptions<Account[] | PaperAccount[]>
) => {
  const {
    data: paper_accounts,
    refetch,
    isLoading,
  } = useRequest(["accounts", "paper"], () => getPaperAccounts(), {
    ...(options as QueryOptions<PaperAccount[]>),
  });

  const { data: paperAccountsWithDetails, isLoading: isDetailLoading } =
    useRequest(
      [
        "accounts",
        "paper",
        "detail",
        ...(paper_accounts?.map((p) => p.paper_account_id) ?? []),
      ],
      () =>
        getPaperAccountsWithDetails(
          paper_accounts?.map((p) => p.paper_account_id)
        ),
      {
        ...options,
      }
    );

  return {
    data: (paperAccountsWithDetails ?? []) as Account[],
    paper_accounts,
    isLoading: isLoading || isDetailLoading,
    refetch: refetch,
  };
};

export default useGetPaperAccountsWithDetails;
