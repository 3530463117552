import { getCashWithdrawable } from "../rest/banking";
import useRequest, { QueryOptions } from "./useRequest";

type UseGetCashWithdrawableProps = {
  accountID: string;
  key?: any | any[];
  options?: QueryOptions<number>;
};

export const useGetCashWithdrawable = ({
  accountID,
  key = "cash-withdrawable",
  options,
}: UseGetCashWithdrawableProps) => {
  const cashWithdrawable = useRequest(
    [key, accountID],
    () => getCashWithdrawable(accountID),
    {
      ...options,
    }
  );

  return cashWithdrawable;
};
