import React, { useEffect } from "react";
import { useGetAccount, useGetAccountDetails } from "./v2/api/hooks";
import { getIntercomUserHash } from "./v2/api/rest/account";
import { initializeIntercom } from "./utils/intercom";

type IntercomProps = {
  status: string;
};

export const Intercom = ({ status }: IntercomProps) => {
  const { account } = useGetAccount("intercom", {
    enabled: status === "loaded",
  });
  const { details } = useGetAccountDetails("details", account?.id, {
    enabled: !!account?.id,
  });

  useEffect(() => {
    if (details && account) {
      getIntercomUserHash()
        .then((userHash) => initializeIntercom({ account, details }, userHash))
        .catch((err) => console.error("Intercom Error", err));
    }
  }, [account, details]);

  return null;
};
