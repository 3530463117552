export const initializeIntercom = (account, userHash) => {
  window.Intercom("boot", {
    app_id: "p7a9jdob",
    user_id: account.details.owner_id,
    status: account.details.status,
    firstName: account.details.given_name,
    lastName: account.details.family_name,
    portfolioValue: account.account.equity,
    tradingBlocked: account.account.trading_blocked,
    country: account.details.country_of_tax_residence,
    state: account.details.state,
    clearing_broker: account.account.clearing_broker,
    created_at: account.account.created_at,
    id: account.details.owner_id,
    name: account.account.name,
    user_hash: userHash,
  });
};

export const updateIntercom = (ownerId, data) => {
  window.Intercom("update", {
    user_id: ownerId,
    ...data,
  });
};
