// parameterizeUrl takes a url descriptor like:
// "xyz/:param1/abc/:myParam", and { param1, myParam }
// and injects the params to return a completed url string
export const parameterizeUrl = (urlDescriptor) => (params) => {
  const url = Object.keys(params).reduce((url, key) => {
    return url.replace(`:${key}`, params[key]);
  }, urlDescriptor);
  if (url.indexOf("/:") !== -1) {
    throw new TypeError(`Insufficient url params specified: ${url}`);
  }
  return url;
};
