import { useRequest } from ".";
import { getPosition } from "../rest/account";

export const useGetPosition = (
  accountID?: string,
  symbol?: string,
  product?: string,
  options = {}
) => {
  const { data } = useRequest(
    ["position", symbol, accountID],
    () =>
      getPosition({
        accountID: accountID || "",
        symbol: (symbol || "").replace("/", ""),
        paper: product === "paper",
      }),
    {
      ...options,
      onError: (error) => {
        // do nothing
      },
    }
  );
  return { position: data };
};

export default useGetPosition;
