import styled from "@emotion/styled";

const Label = styled.div`
  font-size: 10px;
  background-color: #fde483 !important;
  color: rgba(0, 0, 0, 0.79);
  border-radius: 4px;
  width: 50px;
  height: 20px;
  margin-left: 8px;
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
  font-weight: 800;
  text-transform: uppercase;
`;

export default Label;
