import { createAction, createReducer } from "redux-act";
import api from "api";
import { handleSessionExpire } from "../alpacaAuth/session";
import "fast-text-encoding";
import { getAccountIdForProduct } from "selectors";
import { getSessionJWT } from "../../utils";

const REDUCER = "position";
const NS = `@@${REDUCER}/`;

const _setPositions = createAction(`${NS}SET_POSITIONS`);
const _setPlacedLiquidationOrders = createAction(
  `${NS}SET_PLACED_LIQUIDATION_ORDERS`
);

/**
 * listPositions
 */
export const listPositions = (product) => async (dispatch, getState) => {
  const token = await getSessionJWT();

  // Figure out the account id to use
  const accountId = getAccountIdForProduct(getState(), product);
  // TODO: Move some logic for getting the account info out from all of the components
  // and into these actions. If there is no accountId, attempt to get it.
  // That way, it covers the case where a user loads a dashboard page directly
  // and does not yet have account info (opposed to when they navigate from another
  // page and already had account info in state).
  // Though there may be more needed than just the accountId. Many places require
  // owner details, and all accounts. So initUser() seems to be the minimum needed.

  const positionsEndPoint =
    product === "paper" ? "paperPositions" : "positions";
  if (accountId) {
    return handleSessionExpire(
      api[positionsEndPoint].list(accountId, token).then((positions) => {
        return dispatch(_setPositions({ accountId, positions }));
      }),
      dispatch
    );
  }
  return;
};

/**
 * liquidate
 */
export const liquidate = ({ product, symbols, qty }) => (
  dispatch,
  getState
) => {
  const accountId = getAccountIdForProduct(getState(), product);
  const ordersEndPoint = product === "paper" ? "paperPositions" : "positions";
  let endpoint = api[ordersEndPoint].liquidate(
    { accountId },
    { symbols: symbols || [] }
  );

  // If a single position is specified with quantity
  if (qty && symbols && symbols.length === 1) {
    endpoint = api[ordersEndPoint].liquidatePosition({
      accountId,
      symbol: symbols[0],
      qty,
    });
  }

  return handleSessionExpire(
    dispatch(endpoint)
      .then((res) => {
        // Set the response (a list of placed orders) on the state to be displayed to the user for confirmation
        dispatch(
          _setPlacedLiquidationOrders({
            accountId,
            // liquide returns list of orders while liquidatePosition returns the order
            placedLiquidationOrders: res.orders || [res],
          })
        );
        // Refresh current list of positions
        dispatch(listPositions());

        // Handling errors in 200 response.
        // Note that responses are not consistent. Due to this being a batch operation,
        // an array of errors could be returned. Alternatively, if there was some sort of
        // other error preventing the batch operation from even ocurring, the error response
        // could take on a different format commonly seen from other API methods.
        // Note: catch() below because of the status code.

        // In the event of a partial liquidate:
        // Not sure what to display. The feedback is already presented to the user as they
        // will see the table data update.
        // if (res.errors && res.errors.length > 0) {
        //   throw new Error('There was an error with one or more liquidation order.')
        // }
        // Example error: {"errors":[{"code":40310000,"message":"insufficient qty (0 \u003c 10)"}],"orders":[]}
        // When there's existing orders. That's %3C or < ... So: "qty (0 < 10)"
        // It's not descript enough to say which symbol so can't be used in a summary screen or anything. Just yet.
        if (res.errors && res.errors.length > 0) {
          res.errors.forEach((err) => {
            if (
              err.message ===
              "trade denied due to pattern day trading protection"
            ) {
              throw new Error(
                "One or more positions could not be liquidated due to pattern day trading protection."
              );
            }
          });
          throw new Error("One or more positions could not be liquidated.");
        } else {
          return res;
        }
      })
      .catch((e) => {
        // Regardless of error, refresh current list of positions and ensure placed liquidation orders state is empty
        dispatch(
          _setPlacedLiquidationOrders({
            accountId,
            placedLiquidationOrders: [],
          })
        );
        dispatch(listPositions());

        // In the event the market is closed:
        // 'market is closed' is a common erorr message returned by the gobroker API in other places too.
        // Example error: {"code":40310000,"message":"market is closed"}
        // A friendlier message has been created here.
        if (e.message && e.message === "market is closed") {
          e.message = "The market is closed right now.";
        }
        throw e;
      }),
    dispatch
  );
};

// Resets the list of orders created by a previous liquidate
export const resetLiquidatedOrders = (product) => (dispatch, getState) => {
  const accountId = getAccountIdForProduct(getState(), product);
  if (accountId) {
    return dispatch(
      _setPlacedLiquidationOrders({ accountId, placedLiquidationOrders: [] })
    );
  }
};

// Export this reducer
const initialState = {};
export default createReducer(
  {
    [_setPositions]: (state, { accountId, positions }) => {
      // ie. position.bfe87e76-5ad3-41d6-a12d-918142bb38df.positions
      const newState = { ...state };
      if (newState[accountId] === undefined) {
        newState[accountId] = { positions };
      } else {
        newState[accountId].positions = positions;
      }
      return newState;
    },
    [_setPlacedLiquidationOrders]: (
      state,
      { accountId, placedLiquidationOrders }
    ) => {
      const newState = { ...state };
      if (newState[accountId] === undefined) {
        newState[accountId] = { placedLiquidationOrders };
      } else {
        newState[accountId].placedLiquidationOrders = placedLiquidationOrders;
      }
      return newState;
    },
  },
  initialState
);
