import { useContext, useMemo } from "react";
import {
  BankingContext,
  PendingRelationshipType,
} from "../../pages/dashboard/banking";

export const useGetAchRelationshipsPending = () => {
  const ctx = useContext(BankingContext);

  return useMemo(() => {
    const item = localStorage.getItem("relationship:pending");
    const queued = ctx.relationships?.[0]?.status.includes("QUEUED");
    const cancelled = ctx.relationships?.[0]?.status.includes("CANCEL");
    const len = (ctx.relationships || []).length;

    // guaranteed pending
    if (queued || cancelled) {
      return true;
    }

    if (!item) {
      return false;
    }

    // no longer pending due to expiration
    const didExpire = () => {
      ctx.setRelationshipPending(false);
      localStorage.removeItem("relationship:pending");
      return false;
    };

    const meta = JSON.parse(item);

    if (meta.expires < Date.now()) {
      return didExpire();
    } else if (meta.type === PendingRelationshipType.ADDED) {
      if (len > 0) {
        return didExpire();
      }
    } else if (meta.type === PendingRelationshipType.DELETED) {
      if (len === 0) {
        return didExpire();
      }
    }

    return true;
  }, [ctx.relationshipPending, ctx.relationships]);
};

export default useGetAchRelationshipsPending;
