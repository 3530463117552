import { useCallback, useState } from "react";

export const useLock = ({
  initialLocked = true,
}: {
  initialLocked?: boolean;
} = {}) => {
  // initially unlocked
  const [locked, setLocked] = useState(false);

  // a function that locks
  const lock = useCallback(() => setLocked(initialLocked), []);

  // a function that unlocks
  const unlock = useCallback(() => setLocked(false), []);

  // a function that toggles
  const toggle = useCallback(() => setLocked(!locked), [locked]);

  // a function that locks for a given duration
  const lockFor = useCallback(
    (duration: number) => {
      setLocked(true);
      setTimeout(unlock, duration);
    },
    [unlock]
  );

  // a function that locks until a given date
  const lockUntil = useCallback(
    (until: number) => {
      setLocked(true);
      setTimeout(unlock, until - Date.now());
    },
    [unlock]
  );

  return {
    locked,
    lock,
    unlock,
    toggle,
    lockFor,
    lockUntil,
  };
};

export default useLock;
