import useRequest, { QueryOptions } from "./useRequest";
import { getDetails, OwnerDetails } from "../rest/account";

export const useGetAccountDetails = (
  key: any | any[] = "",
  accountID: string = "",
  options?: QueryOptions<OwnerDetails>
) => {
  const { data: details, refetch } = useRequest(
    key,
    () => getDetails(accountID),
    { refetchInterval: false, enabled: !!accountID, ...options }
  );

  return { details, refetch };
};

export default useGetAccountDetails;
