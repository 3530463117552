import styled from "@emotion/styled";

import React, { useContext } from "react";

import { Box, Text } from "@chakra-ui/react";
import { BankingContext } from "../../";
import { CurrencyCloud as CC } from "src/pages/Dashboard/Product/BrokerageAccount/Banking/BankingContainer/CurrencyCloud";

const Container = styled(Box)`
  & > div {
    margin: 0 !important;
  }

  & > div > div {
    padding: 0 !important;
  }

  & > div > div > div {
    border: none !important;
    background: var(--chakra-colors-gray-20) !important;
    padding: 1rem !important;
  }

  [class*="constants__CCModalContainer"] {
    max-width: 100% !important;
  }

  [class*="constants__CopyButton"] {
    display: none !important;
  }

  [class*="constants__CCTextBold"] {
    display: none !important;
  }

  [class*="constants__DepositInfoRow"] > [class*="constants__DepositInfo"] {
    flex-direction: row;
    text-align: right;
  }

  [class*="constants__DepositInfo"] > span {
    white-space: nowrap;
    margin-right: 1rem;
    text-align: left;
    flex-grow: 1;
  }

  [class*="constants__CCTextNormal"]:last-of-type {
    margin-bottom: 0 !important;
  }

  [class*="constants__ButtonRow"] {
    background: transparent !important;
    padding: 0 !important;
  }

  .ant-input-affix-wrapper {
    margin-top: 2rem;
    margin-bottom: 2rem !important;
  }
`;

export const CurrencyCloud = () => {
  const { account, transferDirection } = useContext(BankingContext);

  return (
    <Container>
      <Text fontSize="xl" fontWeight="600" mb="2.8rem">
        Local Transfer (powered by CurrencyCloud)
      </Text>
      <CC
        compact={false}
        accountId={account?.id}
        accountStatus={account?.status}
        currency="USD"
        nonModalMode={true}
        singleOption={transferDirection}
      />
    </Container>
  );
};

export default CurrencyCloud;
