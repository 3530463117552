import { z } from "zod";
import {
  FILE_ACCEPTED_TYPES,
  FILE_MAX_SIZE,
  FILE_SIZE_ERR_MSG,
  FILE_TYPE_ERR_MSG,
  RANGES,
  REQUIRED_ERR_MSG,
} from "./constants";
import axios, { AxiosError } from "axios";
import AmplitudeProvider from "src/AmplitudeProvider";

export const getMinMaxFromRangeOptions = (
  rangeOption: keyof typeof RANGES | string
) => {
  if (!RANGES.hasOwnProperty(rangeOption)) {
    return { min: 0, max: 0 };
  }
  return RANGES[rangeOption as keyof typeof RANGES];
};

// Range Key is used to render correct values for min and max in select component
export const getRangeKeyFromValues = ({
  min,
  max,
}: {
  min: number;
  max: number;
}): keyof typeof RANGES | "" =>
  (Object.keys(RANGES).find((key) => {
    const range = RANGES[key as keyof typeof RANGES];

    return range.min === min && range.max === max;
  }) as keyof typeof RANGES | undefined) ?? "";

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export type Transform<T> = {
  keys: Partial<keyof T>[] | "ALL_FIELDS";
  condition: (value: any) => boolean;
  transform: (value: any) => any;
};

export function getDefaultFormValues<
  T extends { [K in keyof T]: T[K] },
  A extends { [K in keyof A]: A[K] }
>(
  obj: T | undefined,
  transforms: Transform<T>[] | Transform<any>[],
  defaultObj: A
) {
  return Object.fromEntries(
    Object.entries(defaultObj).map(([k, defaultValue]) => {
      const key = k as keyof T;
      const value = obj?.hasOwnProperty(key) ? obj[key] : defaultValue;

      for (const { keys, condition, transform } of transforms) {
        if (Array.isArray(keys) && keys.includes(key) && condition(value)) {
          return [key, transform(value)];
        }
      }

      const transformAll = transforms.find((t) => t.keys === "ALL_FIELDS");

      if (transformAll && transformAll.condition(value)) {
        return [key, transformAll.transform(value)];
      }

      return [key, value];
    })
  );
}

export function transformObject<T extends { [K in keyof T]: T[K] }, ReturnType>(
  obj: T,
  transforms: Transform<T>[]
) {
  return Object.fromEntries(
    Object.entries(obj).map(([k, value]) => {
      const key = k as keyof T;

      for (const { keys, condition, transform } of transforms) {
        if (Array.isArray(keys) && keys?.includes(key) && condition(value)) {
          return [key, transform(value)];
        }
      }

      const transformAll = transforms.find((t) => t.keys === "ALL_FIELDS");

      if (transformAll && transformAll.condition(value)) {
        return [key, transformAll.transform(value)];
      }

      return [key, value];
    })
  ) as ReturnType;
}

export const zAffiliationsRefinement = (
  data: { [key: string]: any },
  refinementContext: z.RefinementCtx
) => {
  const {
    is_affiliated_exchange_or_finra,
    affiliated_firm,
    is_control_person,
    controlling_firms,
  } = data;

  if (is_affiliated_exchange_or_finra && !affiliated_firm) {
    refinementContext.addIssue({
      code: z.ZodIssueCode.custom,
      message: REQUIRED_ERR_MSG,
      path: ["affiliated_firm"],
    });
  }

  if (is_control_person && !controlling_firms) {
    refinementContext.addIssue({
      code: z.ZodIssueCode.custom,
      message: REQUIRED_ERR_MSG,
      path: ["controlling_firms"],
    });
  }
};

export const zNonEmptyString = z
  .string()
  .refine((s) => !!s?.trim(), { message: REQUIRED_ERR_MSG });

export const zFileUpload = z
  .any()
  .refine((files) => files?.length >= 1, "Image is required.")
  .refine((files) => files?.[0]?.size <= FILE_MAX_SIZE, FILE_SIZE_ERR_MSG)
  .refine(
    (files) => FILE_ACCEPTED_TYPES.includes(files?.[0]?.type),
    FILE_TYPE_ERR_MSG
  );

export const getErrorMessage = (err: Error | AxiosError): string => {
  if (axios.isAxiosError(err)) {
    const resp = err.response?.data;
    return resp?.debug || resp?.message;
  }
  return err.message;
};

export const logAmplitudeEvent = {
  onboardingStarted: () => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_started");
  },
  onboardingCompleted: (duration?: number) => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_completed", {
      duration,
    });
  },
  nextClicked: () => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_next_clicked", {
      url: window.location.pathname,
    });
  },
  backClicked: () => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_back_clicked", {
      url: window.location.pathname,
    });
  },
  invalidInput: (message: string) => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_invalid_input", {
      message,
      url: window.location.pathname,
    });
  },
  addOwnerClicked: (type: "ubo" | "authorized-individual") => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_add_owner_clicked", {
      type,
      url: window.location.pathname,
    });
  },
  editOwnerClicked: (type: "ubo" | "authorized-individual") => {
    AmplitudeProvider.dispatch(
      "algodash_entity_onboarding_edit_owner_clicked",
      {
        type,
        url: window.location.pathname,
      }
    );
  },
  pageCompleted: (duration?: number) => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_page_completed", {
      duration,
      url: window.location.pathname,
    });
  },
};
