import { createAction, createReducer } from "redux-act";
import { getAccountIdForProduct } from "selectors";
import api from "api";

const _setOAuthClients = createAction("@@oauth/SET_OAUTH_CLIENTS");

export const listOAuthClients = () => (dispatch, getState) => {
  const accountId = getAccountIdForProduct(getState(), "live");

  dispatch(api.oauth.list({ accountId })).then((payload) =>
    dispatch(_setOAuthClients(payload))
  );
};

const initState = {};

export default createReducer(
  {
    [_setOAuthClients]: (state, payload) => {
      return {
        ...state,
        clients: payload,
      };
    },
  },
  initState
);
