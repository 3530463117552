import useRequest, { QueryOptions } from "./useRequest";

import { ElitePerks, getElitePerks } from "../rest/elite";

export const useGetElitePerks = (
  key: any | any[] = "elite-perks",
  accountID: string,
  options?: QueryOptions<ElitePerks>
) => {
  const { data, refetch } = useRequest(key, () => getElitePerks(accountID), {
    ...options,
  });

  return { perks: data, refetch };
};

export default useGetElitePerks;
