import React, { useMemo, useContext, useEffect } from "react";

import {
  useGetAccountDetails,
  useGetCountryInfos,
  useGetAchRelationshipsPending,
} from "src/v2/api/hooks";

import { Spinner } from "../../../../components/common/spinner";
import { Wire, Ach, CurrencyCloud } from "./methods";
import { BankingContext, TransferDirection } from "../";
import { Box, Button, Text, Flex, Checkbox } from "@chakra-ui/react";

export const TransferType = {
  Ach: "ach",
  CurrencyCloud: "currency-cloud",
  Wire: "wire",
} as const;

export type TransferType = (typeof TransferType)[keyof typeof TransferType];

type Option = {
  value: TransferType;
  label: string;
  subLabel: string;
  disabled?: boolean;
};

export const Wizard = () => {
  const ctx = useContext(BankingContext);
  const isAchRelationshipsPending = useGetAchRelationshipsPending();

  const { infos } = useGetCountryInfos();

  // we need these for currency cloud
  const { details } = useGetAccountDetails(
    ["banking", ctx.account?.id],
    ctx.account?.id,
    { enabled: !!ctx.account?.id }
  );

  const depositTransferTypes: Record<TransferType, Option> = useMemo(
    () => ({
      [TransferType.Ach]: {
        value: TransferType.Ach,
        label: isAchRelationshipsPending
          ? "US Bank Account"
          : `${
              !!ctx.relationships?.length ? "Linked" : "Link"
            } US Bank Account`,
        subLabel: isAchRelationshipsPending
          ? "Pending bank relationship update, please wait..."
          : "Alpaca partners with Plaid to allow you to link your bank. Alpaca does not charge for ACH deposits. ACH deposits takes 1-3 business days.",
      },
      [TransferType.CurrencyCloud]: {
        value: TransferType.CurrencyCloud,
        label: "Local Transfer (Powered by CurrencyCloud)",
        subLabel:
          "Send funds to Alpaca’s bank account from your local country. Local transfers can reflect on your account same day.",
      },
      [TransferType.Wire]: {
        value: TransferType.Wire,
        label: "Send Funds to Alpaca",
        subLabel:
          "Send funds directly to Alpaca from your bank via wire transfer. Domestic Wire transfers may take 2-3 business days. Your bank may charge you for outgoing wires.",
      },
    }),
    [
      isAchRelationshipsPending,
      ctx.relationships,
      ctx.relationships?.[0]?.status,
    ]
  );

  const withdrawalTransferTypes: Record<TransferType, Option> = useMemo(
    () => ({
      [TransferType.Ach]: {
        value: TransferType.Ach,
        label: isAchRelationshipsPending
          ? "US Bank Account"
          : `${
              !!ctx.relationships?.length ? "Linked" : "Link"
            } US Bank Account`,
        subLabel: isAchRelationshipsPending
          ? "Pending bank relationship update, please wait..."
          : "Alpaca partners with Plaid to allow you to link your bank. Alpaca does not charge for ACH withdrawals. ACH withdrawals takes 1-3 business days.",
      },
      [TransferType.CurrencyCloud]: {
        value: TransferType.CurrencyCloud,
        label: "Local Transfer (Powered by CurrencyCloud)",
        subLabel:
          "Withdraw funds to the bank account in your local country. Local transfers can reflect on your account same day.",
      },
      [TransferType.Wire]: {
        value: TransferType.Wire,
        label: "Via Wire Transfer",
        subLabel:
          "Receive your funds via a wire transfer from Alpaca. ETA 1-7 business days. Intermediary and receiving banks may charge extra fees.",
      },
    }),
    [
      isAchRelationshipsPending,
      ctx.relationships,
      ctx.relationships?.[0]?.status,
    ]
  );

  const options = useMemo<Option[]>(
    () =>
      Object.values(
        ctx.transferDirection === TransferDirection.INCOMING
          ? depositTransferTypes
          : withdrawalTransferTypes
      ).filter((option) => {
        // Filter out ACH if entity account and no relationship exists
        if (
          option.value === TransferType.Ach &&
          ctx.account?.is_legal_entity &&
          ctx.relationships.length === 0
        ) {
          return false;
        }

        const isCurrencyCloudEnabled =
          infos[details?.country_of_tax_residence || ""]
            ?.currencycloud_enabled ?? false;

        // Filter out currency cloud if not available
        if (
          option.value === TransferType.CurrencyCloud &&
          !isCurrencyCloudEnabled
        ) {
          return false;
        }

        return true;
      }),
    [
      infos,
      details,
      depositTransferTypes,
      withdrawalTransferTypes,
      ctx.transferDirection,
    ]
  );

  // clear form id when transfer type changes
  useEffect(() => {
    if (!(ctx.transferType !== null && ctx.nextStep)) {
      ctx.setWizardFormId(null);
    }
  }, [ctx.transferType, ctx.nextStep]);

  // handle user going to previous step
  const onBack = () => {
    const back = ctx.transferType !== null && ctx.nextStep;

    ctx.setNextStep(false);
    ctx.setTransferType(null);

    if (!back) {
      ctx.disclosure.onClose();
      window.history.replaceState({}, "", window.location.pathname);
    }
  };

  return (
    <Box
      mx="auto"
      display="flex"
      py={["0", "8vh"]}
      overflowY="scroll"
      alignItems="center"
      justifyContent="center"
      width="100%"
      maxWidth="496px"
    >
      <Box
        w="500px"
        mx="auto"
        minW="300px"
        maxW="500px"
        borderRadius="md"
        border={[
          "2px solid transparent",
          "2px solid var(--chakra-colors-gray-10)",
        ]}
        bg="#FFF"
      >
        {!ctx.relationships || !(details && Object.keys(infos).length) ? (
          <Spinner />
        ) : (
          <Box p="1.4rem">
            {!ctx.nextStep && (
              <Text fontSize="xl" fontWeight="600" mb="2.8rem">
                Choose your preferred{" "}
                {ctx.transferDirection === TransferDirection.INCOMING
                  ? "deposit"
                  : "withdrawal"}{" "}
                method
              </Text>
            )}
            {ctx.transferType !== null && ctx.nextStep ? (
              <Box>
                {ctx.transferType === TransferType.Ach && <Ach />}
                {ctx.transferType === TransferType.Wire && <Wire />}
                {ctx.transferType === TransferType.CurrencyCloud && (
                  <CurrencyCloud />
                )}
              </Box>
            ) : (
              options.map((option) => (
                <Flex
                  key={option.value}
                  mb="1.4rem"
                  cursor="pointer"
                  onClick={() => ctx.setTransferType(option.value)}
                >
                  <Box>
                    <Checkbox
                      mr="1rem"
                      isChecked={option.value === ctx.transferType}
                    />
                  </Box>
                  <Flex flexDir="column" justifyItems="start">
                    <Text
                      mb=".5rem"
                      mt="-.24rem"
                      fontSize="md"
                      fontWeight="500"
                    >
                      {option.label}
                    </Text>
                    <Text>{option.subLabel}</Text>
                  </Flex>
                </Flex>
              ))
            )}
            <Flex justifyContent="space-between" mt="2.8rem">
              <Button variant="skeleton" onClick={onBack}>
                Back
              </Button>
              {!!ctx.wizardFormId && (
                <Button type="submit" form={ctx.wizardFormId}>
                  Submit
                </Button>
              )}
              {!(ctx.transferType !== null && ctx.nextStep) && (
                <Button
                  variant="primary"
                  onClick={() => ctx.setNextStep(true)}
                  disabled={
                    (isAchRelationshipsPending &&
                      ctx.transferType === TransferType.Ach) ||
                    !(!ctx.nextStep && ctx.transferType !== null)
                  }
                >
                  Next
                </Button>
              )}
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Wizard;
