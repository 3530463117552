import useRequest, { QueryOptions } from "./useRequest";

import { getAssets } from "../rest";
import { Asset } from "../rest/account";

export const useGetAssets = (
  key: any | any[] = "",
  options?: QueryOptions<any[]>
) => {
  const { data, refetch, isFetched } = useRequest(key, () => getAssets(), {
    cacheTime: 9999999,
    staleTime: Infinity,
    ...options,
  });

  return { assets: data || [], refetch, isFetched };
};

export default useGetAssets;
