import { useRequest } from ".";
import { getOrders } from "../rest/account";

export const useGetOrders = (
  accountID: string,
  product: string,
  symbols?: string[],
  options?: any,
  status?: "open" | "closed" | "all",
  side?: "buy" | "sell" | "sell_short"
) => {
  const { data, refetch, isLoading } = useRequest(
    ["orders", accountID, symbols, status, side],
    () =>
      getOrders({
        accountID: accountID || "",
        paper: product === "paper",
        symbols: [],
        status: status ? status : "all",
        side: side,
      }),
    { ...(options || {}) }
  );

  return { orders: data || [], refetch, isLoading };
};

export default useGetOrders;
