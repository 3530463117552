import { useRequest } from ".";
import { getOrderbooks } from "../rest/data";

export const useGetOrderbooks = (symbols: string[]) => {
  const { data } = useRequest(
    [symbols, "orderbook"],
    () => getOrderbooks(symbols),
    {
      staleTime: 1000,
      refetchInterval: 1000,
    }
  );
  return { orderbooks: data?.orderbooks };
};

export default useGetOrderbooks;
