import styled from "styled-components";

export const Link = styled.a`
  margin: ${(props) => (props.margin ? props.margin : "5px")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1em")};
`;

export const SubtitleLink = styled.a`
  text-decoration: none;
  font-size: 20px;
  color: #303030;
  white-space: nowrap;
  font-family: "Gilroy-Light";
  padding: 0 25px;

  :hover {
    color: #fff;
  }
`;
