import { createAction, createReducer } from "redux-act";
import { notification } from "antd";
import { getAlpacaAccountId } from "selectors";
import { listTransfers } from "./account";
import api from "api";
import AmplitudeProvider from "src/AmplitudeProvider";
import { eventTracker } from "src/utils/eventTracker";

const REDUCER = "transfer";
const NS = `@@${REDUCER}/`;

const _upsertTransfer = createAction(`${NS}UPSERT_TRANSFER`);
const _setStatus = createAction(`${NS}SET_STATUS`);
export const clearTransferRequest = createAction(`${NS}CLEAR_TRANSFER_REQUEST`);

const transferCreator = (direction) => ({
  relationshipId,
  bankId,
  amount,
  type,
}) => (dispatch, getState) => {
  const accountId = getAlpacaAccountId(getState());
  return dispatch(
    api.transfers.create(
      { accountId },
      {
        relationship_id: relationshipId,
        bank_id: bankId,
        transfer_type: type,
        direction,
        amount,
      }
    )
  )
    .then((transfer) => {
      if (transfer) {
        eventTracker("Deposited Funds", {
          category: "Banking",
          label: window.location.pathname,
          value: amount,
        });

        AmplitudeProvider.dispatch(
          direction == "INCOMING"
            ? "deposit_submitted"
            : "withdrawal_submitted",
          JSON.stringify({ amount })
        );

        dispatch(_upsertTransfer(transfer));
        dispatch(listTransfers());
      }
    })
    .catch((err) => {
      eventTracker("Failed to Deposit Funds", {
        category: "Banking",
        label: window.location.pathname,
        value: amount,
      });
      console.error("Failed transfer request (deposit):", err);
      notification.open({
        type: "error",
        message: "Failed transfer request (deposit)",
        description: err.message,
      });
      dispatch(_setStatus("FAILED"));
    });
};

export const deposit = transferCreator("INCOMING");
export const withdraw = transferCreator("OUTGOING");

export const cancelTransfer = (transferId) => (dispatch, getState) => {
  const accountId = getAlpacaAccountId(getState());
  return dispatch(api.transfers.delete({ accountId, transferId })).then(() =>
    dispatch(listTransfers())
  );
};

const initialState = {};
export default createReducer(
  {
    [_upsertTransfer]: (state, transfer) => ({
      ...state,
      ...transfer,
    }),
    [_setStatus]: (state, status) => ({
      ...state,
      status,
    }),
    [clearTransferRequest]: () => ({}),
  },
  initialState
);
