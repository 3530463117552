import useRequest, { QueryOptions } from "./useRequest";

import { getCryptoFeeStructure, CryptoFeeStructure } from "../rest/billing";

export const useGetCryptoFeeStructure = (
  key: any | any[] = "crypto-fee-structure",
  options?: QueryOptions<CryptoFeeStructure>
) => {
  const { data } = useRequest(key, () => getCryptoFeeStructure(), {
    ...options,
  });

  return { ...data };
};

export default useGetCryptoFeeStructure;
