import { useRequest } from ".";
import { getPositions } from "../rest/account";

// todo: parameter-ize this hook
export const useGetPositions = (
  accountID?: string,
  product?: string,
  options = {}
) => {
  const { data, ...rest } = useRequest(
    ["position", accountID],
    () => getPositions({ accountID: accountID || "", product }),
    {
      ...options,
      // todo: revisit this override of default behavior (duplicate error handling)
      onError: () => null,
    }
  );

  return { positions: data || [], ...rest };
};

export default useGetPositions;
