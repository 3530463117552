import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import LoadingSpinner from "components/LoadingSpinner";
import { initUser } from "reducers/auth/common";

const mapStateToProps = (state) => ({
  accountState: state.account,
});

@connect(mapStateToProps)
class RootRedirect extends React.Component {
  load() {
    this.props.dispatch(initUser(false));
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate() {
    this.load();
  }

  render() {
    const { accountState } = this.props;
    const { account, details } = accountState;
    if (account && details) {
      switch (account.status) {
        case "PAPER_ONLY":
        case "ONBOARDING":
          return <Redirect to="/brokerage/new-account" />;
        default:
          return <Redirect to="/brokerage/dashboard/overview" />;
      }
    }
    return <></>;
  }
}
export default RootRedirect;
