import styled from "@emotion/styled";

export const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 0px;
  border: 0.111rem solid rgba(231, 234, 243, 0.8);
  box-shadow: 0 0.375rem 0.75rem rgba(140, 152, 164, 0.04);
`;

export const CardHeader = styled.div`
  font-family: Carnas;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 0.1rem solid rgba(231, 234, 243, 0.7);
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

export const CardBody = styled.div`
  padding: 1.25rem;
  padding-top: 1.1rem;
  font-size: 14px;
`;

export const CardFooter = styled.div`
  font-size: 16px;
  font-weight: 600;
  border-top: 0.1rem solid rgba(231, 234, 243, 0.7);
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
`;
