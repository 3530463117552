import instance from "..";

export type GetCurrencyCloudAccountResponse = {
  account_number: string;
  account_holder_name: string;
  bank_name: string;
  bank_address: string;
  routing_code: string;
  routing_code_type: string;
};

export const getCurrencyCloudAccount = async (
  accountId: string
): Promise<GetCurrencyCloudAccountResponse> => {
  const res = await instance.get<GetCurrencyCloudAccountResponse>(
    `/currency_cloud/${accountId}/funding_account`
  );
  return res.data;
};

export const createCurrencyCloudAccount = async (accountId: string) => {
  await instance.post(`/currency_cloud/${accountId}/funding_account`);
};

export type GetCurrencyCloudStatusResponse = {
  sub_account_exists: string;
  beneficiary_exists: string;
};

export const getCurrencyCloudStatus = async (
  accountId: string
): Promise<GetCurrencyCloudStatusResponse> => {
  const res = await instance.get<GetCurrencyCloudStatusResponse>(
    `/currency_cloud/${accountId}/status`
  );
  return res.data;
};

export type CurrencyCloudTransfer = {
  id: string;
  account_id: string;
  amount: string;
  direction: string;
  status: string;
  created_at: string;
  updated_at: string;
};

export type GetCurrencyCloudTransfersResponse = CurrencyCloudTransfer[];

export const getCurrencyCloudTransfers = async (
  accountId: string
): Promise<GetCurrencyCloudTransfersResponse> => {
  const res = await instance.get<GetCurrencyCloudTransfersResponse>(
    `/currency_cloud/${accountId}/transfers`
  );
  return res.data;
};

export type CreateCurrencyCloudTransferRequest = {
  amount: string;
};

export const createCurrencyCloudTransfer = async (
  accountId: string,
  data: CreateCurrencyCloudTransferRequest
) => {
  const res = await instance.post(
    `/currency_cloud/${accountId}/transfers`,
    data
  );
  return res.data;
};

export type CurrencyCloudBeneficiary = {
  account_number: string;
  iban: string;
  bic_swift: string;
  sort_code: string;
  aba: string;
  bsb_code: string;
  bank_code: string;
  branch_code: string;
  clabe: string;
  cnaps: string;
  ifsc: string;
};

export type GetCurrencyCloudBeneficiaryResponse = {
  required_details: CurrencyCloudBeneficiary[];
};

export const getCurrencyCloudBeneficiaryRequiredDetails = async (
  accountId: string
) => {
  const res = await instance.get<GetCurrencyCloudBeneficiaryResponse>(
    `/currency_cloud/${accountId}/beneficiary_required_details`
  );
  return res.data;
};

export type CreateCurrencyCloudBeneficiaryRequest = CurrencyCloudBeneficiary;

export const createCurrencyCloudBeneficiary = async (
  accountId: string,
  data: CreateCurrencyCloudBeneficiaryRequest
) => {
  const res = await instance.post(
    `/currency_cloud/${accountId}/beneficiary`,
    data
  );
  return res.data;
};
