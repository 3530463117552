import { useToast } from "@chakra-ui/toast";
import { defaultToastSettings, getErrorMessage } from ".";
import { useMutation, MutationFunction, UseMutationOptions } from "react-query";

export function useMutationRequest<T, U>(
  request: MutationFunction<T, U>,
  options?: UseMutationOptions<T, Error, U, unknown>
) {
  const toast = useToast();
  const mutation = useMutation<T, Error, U>(request, {
    onError: (e) => {
      const key = "errorToastKey";
      const message = getErrorMessage(e);
      toast({
        id: key,
        description: message,
        ...defaultToastSettings,
      });
    },
    ...options,
  });

  return mutation;
}

export default useMutationRequest;
