import { CountryInfo, Nullable } from "src/v2/types";

type CountryInfoMap = {
  [key: string]: CountryInfo;
};

// we use a hook in-case we want to fetch backend data in the future
export const useGetCryptoEnabled = (
  country: Nullable<string>,
  state: Nullable<string>,
  infoMap: Nullable<CountryInfoMap>
) => {
  if (country && country !== "USA") {
    return Boolean(infoMap?.[country]?.crypto_enabled);
  }

  // need both country and state to be defined for U.S.
  if (!country || !state) {
    return false;
  }

  const supported = window.env.SUPPORTED_CRYPTO_STATES || "";
  return supported.split(",").includes(state?.toUpperCase());
};

export default useGetCryptoEnabled;
