import { getQuotes, Quote } from "../rest/data";
import useRequest, { QueryOptions } from "./useRequest";

export const useGetQuotes = (
  key: any | any[] = "",
  symbols: string[] = [],
  options?: QueryOptions<Quote[]>,
  source?: string
) => {
  const {
    data: quotes,
    refetch,
    isFetched,
  } = useRequest(key, () => getQuotes(symbols, source), {
    ...options,
    refetchInterval: false,
  });

  return { quotes, refetch, isFetched };
};

export default useGetQuotes;
