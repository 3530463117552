import loadable from "@loadable/component";
import enGB from "antd/lib/locale-provider/en_GB";
import React, { useCallback } from "react";
import CookieConsent from "react-cookie-consent";
import ReactDOM from "react-dom";
import AmplitudeProvider from "./AmplitudeProvider";
import AuthCheck from "./AuthCheck";
import Layout from "./components/AlpacaComponents/Layout";
import RootRedirect from "./pages/RootRedirect";

import {
  checkLogin,
  requireCrytpoActive,
  requireLiveActive,
  requireLogin,
} from "./authMethods";

import { ConfigProvider } from "antd";
import { ConnectedRouter } from "connected-react-router";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { connect, Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { version } from "../package.json";
import { useFlag } from "../src/v2/helpers/flags";
import { Intercom } from "./Intercom";
import { init as initSentry } from "./Sentry";
import { Wireframe } from "./Wireframe";
import { Link } from "./components/AlpacaComponents/Text";
import { initAmplify } from "./reducers/auth/common";
import { store } from "./store";
import { history } from "./utils";
import { init as initWebsocket } from "./websocket";

import "antd/dist/antd.css";
import "resources/AlpacaStyles/alpaca.scss";
import "resources/AntStyles/AntDesign/antd.override.scss";
import AccountProvider from "./v2/providers/AccountProvider";

initSentry();

// @ts-ignore adds version() command to global scope
window.version = () => {
  return process.env.REACT_APP_VERSION || version;
};

const AuthCheckRoute = (props: any) => {
  const component = useCallback(
    (routeProps) => (
      <AuthCheck
        routeProps={routeProps}
        redirector={props.redirector}
        component={props.component}
      />
    ),
    [props.redirector, props.component]
  );
  return <Route path={props.path} component={component} />;
};

// super gross but really easy way to disable our stupid debug logs in prod
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

initWebsocket();

const queryClient = new QueryClient();

const render = () => {
  const hasCognitoCookies =
    document.cookie &&
    document.cookie
      .split(";")
      .filter((value) => value.match(/CognitoIdentityService.*/)).length > 0;
  if (hasCognitoCookies) {
    initAmplify();
  }

  ReactDOM.render(
    <AmplitudeProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider locale={enGB}>
            <ConnectedRoutes />
          </ConfigProvider>
        </QueryClientProvider>
      </Provider>
      <Wireframe enabled={process.env.NODE_ENV === "development"} />
    </AmplitudeProvider>,
    document.getElementById("root")
  );
};

const Routes = ({
  dispatch,
  cognitoStatus,
}: {
  dispatch: any;
  cognitoStatus: string;
}) => {
  const NewAccountV2Importer = () => import("./v2/pages/dashboard/onboarding");

  const NewAccount = () => (
    <Layout
      product="paper"
      showSearchInHeader={true}
      noHeader={false}
      importer={NewAccountV2Importer}
    />
  );

  return (
    <>
      <ConnectedRouter history={history}>
        <AccountProvider dispatch={dispatch} status={cognitoStatus}>
          <div>
            <Toaster />
            <CookieConsent
              location="bottom"
              buttonText="Accept"
              cookieName="gdpr_accepted"
              style={{ background: "#2B373B" }}
              buttonStyle={{
                color: "#4e503b",
                fontSize: "13px",
                marginRight: "7em",
              }}
            >
              Alpaca and selected third parties use cookies on this website as
              specified in the
              <Link
                href="https://files.alpaca.markets/disclosures/CookiePolicy.pdf"
                style={{ color: "#fcd305" }}
              >
                cookie policy
              </Link>
              . You accept the use of these cookies or other identifiers by
              pressing ‘Accept’, closing this notice, or logging into your
              account.
            </CookieConsent>
            <Switch>
              <Route
                path="/account/login"
                component={loadable(() => import("./v2/pages/auth"))}
              />
              <AuthCheckRoute
                path="/exchange_questions"
                component={loadable(() => import("./pages/ExchangeQuestions"))}
              />
              <AuthCheckRoute
                path="/logout"
                component={loadable(() => import("./pages/LogoutPage"))}
                redirector={checkLogin}
              />
              <AuthCheckRoute
                path="/signup"
                component={loadable(() => import("./v2/pages/signup"))}
                redirector={checkLogin}
              />
              <AuthCheckRoute
                path="/verify"
                component={loadable(() => import("./v2/pages/signup"))}
                redirector={checkLogin}
              />
              <AuthCheckRoute
                path="/signup/pending"
                component={loadable(() => import("./v2/pages/signup"))}
                redirector={checkLogin}
              />
              <AuthCheckRoute
                path="/signup/success"
                component={loadable(() => import("./v2/pages/signup"))}
              />
              <AuthCheckRoute
                path="/onboarding/step/one"
                component={loadable(
                  () => import("./v2/pages/dashboard/onboarding")
                )}
              />
              <AuthCheckRoute
                path="/onboarding/step/two"
                component={loadable(
                  () => import("./v2/pages/dashboard/onboarding")
                )}
              />
              <AuthCheckRoute
                path="/onboarding/step/three"
                component={loadable(
                  () => import("./v2/pages/dashboard/onboarding")
                )}
              />
              <AuthCheckRoute
                path="/onboarding/kyc"
                component={loadable(
                  () => import("./v2/pages/dashboard/onboarding")
                )}
              />
              <AuthCheckRoute
                path="/onboarding/bank"
                component={loadable(
                  () => import("./v2/pages/dashboard/onboarding")
                )}
              />
              <Route
                path="/dashboard/portfolio"
                component={() => (
                  <Redirect push to="/brokerage/dashboard/portfolio" />
                )}
              />
              <Route
                path="/login"
                component={() => <Redirect push to="/account/login" />}
              />
              <Route
                path="/brokerage/dashboard/portfolio"
                render={() => <Redirect push to="/account/positions" />}
              />
              <Route
                path="/paper/dashboard/portfolio"
                render={() => <Redirect push to="/account/positions" />}
              />
              <AuthCheckRoute
                path="/sign/W8-BEN"
                component={loadable(() => import("./v2/pages/sign/W8-BEN"))}
              />
              <Route path="/.well-known/apple-app-site-association" />
              {/* OAuth */}
              <AuthCheckRoute
                path="/oauth/authorize"
                component={() => (
                  <Layout
                    noHeader={true}
                    disableAppMenu={true}
                    showBrandInHeader={true}
                    showSearchInHeader={false}
                    importer={() => import("./pages/OAuth/AuthorizationPage")}
                  />
                )}
                redirector={requireLogin}
              />
              {/* USER & ALPACA ACCOUNT ROUTES */}
              <AuthCheckRoute
                path="/user/edit-profile"
                component={() => (
                  <Layout
                    disableAppMenu={true}
                    showBrandInHeader={true}
                    showSearchInHeader={false}
                    importer={() => import("./pages/User/EditProfilePage")}
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/user/profile"
                component={() => (
                  <Layout
                    product="dynamic"
                    showSearchInHeader={true}
                    importer={() =>
                      useFlag("uix-v2")
                        ? import("./v2/pages/dashboard/profile")
                        : import("./pages/User/ProfilePage")
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/user/subscription"
                component={() => (
                  <Layout
                    product="live"
                    showSearchInHeader={true}
                    importer={() => import("./pages/User/SubscriptionPage")}
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/user/trading-plan"
                component={() => (
                  <Layout
                    product="live"
                    showSearchInHeader={true}
                    importer={() => import("./v2/pages/dashboard/tradingPlan")}
                  />
                )}
                redirector={requireLogin}
              />

              <AuthCheckRoute
                path="/user/high-yield-cash-enrollment"
                component={() => (
                  <Layout
                    product="live"
                    showSearchInHeader={true}
                    importer={() =>
                      import("./v2/pages/dashboard/highYieldAgreement")
                    }
                  />
                )}
                redirector={requireLogin}
              />
              {/* PAPER ROUTES */}

              <AuthCheckRoute
                path="/paper/dashboard/order/:orderId"
                component={() => (
                  <Layout
                    product="paper"
                    importer={() => import("./v2/pages/order")}
                  />
                )}
                redirector={requireLogin}
              />

              <AuthCheckRoute
                path="/paper/dashboard/balances"
                component={() => (
                  <Layout
                    product="paper"
                    importer={() => import("./v2/pages/balances")}
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/paper/dashboard/account-activities"
                component={() => (
                  <Layout
                    product="paper"
                    importer={() =>
                      import("./v2/pages/dashboard/account/activities")
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/document-requests"
                component={() => (
                  <Layout
                    product="live"
                    showSearchInHeader={false}
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/DocumentRequests"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/paper/dashboard/overview"
                component={() => (
                  <Layout
                    product="paper"
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/Overview"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/dashboard/overview"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/Overview"
                      )
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/paper/dashboard/overview"
                component={() => (
                  <Layout
                    product="paper"
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/Overview"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/dashboard/overview"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/Overview"
                      )
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path={["/paper/trade/:symbol*", "/paper/pairs/:symbol*"]}
                component={() => (
                  <Layout
                    product="paper"
                    importer={() =>
                      import("./pages/Dashboard/Product/PaperTrading/StockPage")
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path={[
                  "/brokerage/stocks/:symbol*",
                  "/brokerage/pairs/:symbol*",
                ]}
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/StockPage"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              {/* BROKERAGE ROUTES */}
              <AuthCheckRoute
                path="/data-agreement/:agreement"
                component={() => (
                  <Layout
                    product="live"
                    showSearchInHeader={false}
                    importer={() => import("./pages/DataAgreementPage")}
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/new-account/:step"
                component={NewAccount}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                exact
                path="/brokerage/new-account"
                component={NewAccount}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/new-entity-account/entity-profile"
                component={() => (
                  <Layout
                    product="paper"
                    showSearchInHeader={true}
                    noHeader={false}
                    importer={() =>
                      import(
                        "./v2/pages/dashboard/entityOnboarding/pages/entityProfile"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/new-entity-account/account-opener"
                component={() => (
                  <Layout
                    product="paper"
                    showSearchInHeader={true}
                    noHeader={false}
                    importer={() =>
                      import(
                        "./v2/pages/dashboard/entityOnboarding/pages/accountOpener"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/new-entity-account/authorized-individuals-ubos"
                component={() => (
                  <Layout
                    product="paper"
                    showSearchInHeader={true}
                    noHeader={false}
                    importer={() =>
                      import(
                        "./v2/pages/dashboard/entityOnboarding/pages/authIndividualsUBOs"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/new-entity-account/documents"
                component={() => (
                  <Layout
                    product="paper"
                    showSearchInHeader={true}
                    noHeader={false}
                    importer={() =>
                      import(
                        "./v2/pages/dashboard/entityOnboarding/pages/documents"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/new-entity-account/documents"
                component={() => (
                  <Layout
                    product="paper"
                    showSearchInHeader={true}
                    noHeader={false}
                    importer={() =>
                      import(
                        "./v2/pages/dashboard/entityOnboarding/pages/documents"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/dashboard/order/:orderId"
                component={() => (
                  <Layout
                    product="live"
                    importer={() => import("./v2/pages/order")}
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/dashboard/balances"
                component={() => (
                  <Layout
                    product="live"
                    importer={() => import("./v2/pages/balances")}
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/positions"
                component={() => <Redirect push to="/account/positions" />}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/account/positions"
                component={() => (
                  <Layout
                    product="dynamic"
                    importer={() => import("./v2/pages/dashboard/positions")}
                  />
                )}
                redirector={requireLogin}
              />

              <AuthCheckRoute
                path="/account/activities"
                component={() => (
                  <Layout
                    product="dynamic"
                    importer={({ product }: any = {}) => {
                      return import("./v2/pages/dashboard/account/activities");
                    }}
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/orders"
                component={() => <Redirect push to="/account/orders" />}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/account/orders"
                component={() => (
                  <Layout
                    product="dynamic"
                    importer={() => import("./v2/pages/dashboard/orders")}
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/account/configuration"
                component={() => (
                  <Layout
                    product="dynamic"
                    importer={() =>
                      import(
                        "./v2/pages/dashboard/account/configurations/index"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/account/documents"
                component={() => (
                  <Layout
                    product="dynamic"
                    importer={() =>
                      import("./v2/pages/dashboard/account/documents")
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/account/plans-and-features"
                component={() => (
                  <Layout
                    product="dynamic"
                    importer={() => import("./v2/pages/dashboard/plans")}
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/dashboard/balances"
                component={() => (
                  <Layout
                    product="live"
                    importer={() => import("./v2/pages/balances")}
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/banking"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/Banking"
                      )
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding/deposit/wire"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./v2/pages/dashboard/funding/deposit/wire/index")
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding/deposit/ach"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./v2/pages/dashboard/funding/deposit/ach")
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding/deposit/currencycloud"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import(
                        "./v2/pages/dashboard/funding/deposit/currencyCloud"
                      )
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding/deposit"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./v2/pages/dashboard/funding/deposit")
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding/withdraw/ach"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./v2/pages/dashboard/funding/withdraw/ach")
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding/withdraw/wire"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./v2/pages/dashboard/funding/withdraw/wire")
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding/withdraw/currencycloud"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import(
                        "./v2/pages/dashboard/funding/withdraw/currencyCloud"
                      )
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding/withdraw"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./v2/pages/dashboard/funding/withdraw")
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding/history"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./v2/pages/dashboard/funding/history")
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding/link/ach"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./v2/pages/dashboard/funding/link/ach")
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding/link/wire"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./v2/pages/dashboard/funding/link/wire")
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding/link/currencycloud"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./v2/pages/dashboard/funding/link/currencyCloud")
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/funding"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./v2/pages/dashboard/funding/index")
                    }
                  />
                )}
                redirector={requireLiveActive}
              />

              <AuthCheckRoute
                path="/brokerage/dashboard/account-activities"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./v2/pages/dashboard/account/activities")
                    }
                  />
                )}
                redirector={requireLiveActive}
              />
              <AuthCheckRoute
                path="/brokerage/agreement"
                component={() => (
                  <Layout
                    product="live"
                    showSearchInHeader={false}
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/Agreement"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path={["/trade/:base/:quote"]}
                component={() => (
                  <Layout
                    product="dynamic"
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/StockPage"
                      )
                    }
                  />
                )}
              />
              <AuthCheckRoute
                path={["/trade/:symbol"]}
                component={() => (
                  <Layout
                    product="dynamic"
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/StockPage"
                      )
                    }
                  />
                )}
              />
              <AuthCheckRoute
                path="/brokerage/apps/manage"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/OauthApps/OauthAppGrid"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/connect/submit"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./pages/Dashboard/Product/Connect/SubmitApp")
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/connect/edit/:clientId"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import("./pages/Dashboard/Product/Connect/SubmitApp")
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/connect/:clientId"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      useFlag("uix-v2")
                        ? import("./v2/pages/connect/AppDetailsPage")
                        : import("./pages/Dashboard/Product/Connect/App")
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/connect"
                component={() => (
                  <Layout
                    product="dynamic"
                    importer={() =>
                      useFlag("uix-v2")
                        ? import("./v2/pages/connect/AppPage")
                        : import("./pages/Dashboard/Product/Connect/Apps")
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/apps/edit/agreement"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/OauthApps/OauthAppGrid/OauthAppAgreement"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/apps/edit/:clientId"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/OauthApps/OauthContainer"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/dashboard/crypto-transfers"
                component={() => (
                  <Layout
                    product="live"
                    importer={() =>
                      import(
                        "./pages/Dashboard/Product/BrokerageAccount/CoinTransfers"
                      )
                    }
                  />
                )}
                redirector={requireCrytpoActive}
              />
              <AuthCheckRoute
                path="/brokerage/options/opt-in/tier"
                component={() => (
                  <Layout
                    product="live"
                    showSearchInHeader={true}
                    noHeader={false}
                    importer={() =>
                      import("./v2/pages/dashboard/optionsOptIn/pages/tier")
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/options/opt-in/financial-profile"
                component={() => (
                  <Layout
                    product="live"
                    showSearchInHeader={true}
                    noHeader={false}
                    importer={() =>
                      import(
                        "./v2/pages/dashboard/optionsOptIn/pages/financialProfile"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/options/opt-in/investment-experience"
                component={() => (
                  <Layout
                    product="live"
                    showSearchInHeader={true}
                    noHeader={false}
                    importer={() =>
                      import(
                        "./v2/pages/dashboard/optionsOptIn/pages/investmentExperience"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/options/opt-in/options-agreement"
                component={() => (
                  <Layout
                    product="live"
                    showSearchInHeader={true}
                    noHeader={false}
                    importer={() =>
                      import(
                        "./v2/pages/dashboard/optionsOptIn/pages/agreement"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/options/opt-in/status"
                component={() => (
                  <Layout
                    product="live"
                    showSearchInHeader={true}
                    noHeader={false}
                    importer={() =>
                      import("./v2/pages/dashboard/optionsOptIn/pages/status")
                    }
                  />
                )}
                redirector={requireLogin}
              />
              <AuthCheckRoute
                path="/brokerage/options/opt-in/entity-account"
                component={() => (
                  <Layout
                    product="live"
                    showSearchInHeader={true}
                    noHeader={false}
                    importer={() =>
                      import(
                        "./v2/pages/dashboard/optionsOptIn/pages/entityAccount"
                      )
                    }
                  />
                )}
                redirector={requireLogin}
              />
              {/* OPTIONS REDIRECT ROUTE */}
              <Route
                path={"/options"}
                component={loadable(() => import("./pages/OptionsRedirect"))}
              />
              {/* TODO: redirect based on account status */}
              <AuthCheckRoute
                path="/"
                component={() => <RootRedirect />}
                redirector={requireLogin}
              />
              {/* REDIRECTS */}
              <Route
                path="/account"
                render={() => (
                  <Redirect push to="/brokerage/dashboard/profile" />
                )}
              />
              <Route
                path="/docs"
                render={() => (
                  <Redirect push to="/brokerage/dashboard/documents" />
                )}
              />
              <Route
                path="/dashboard/overview"
                render={() => (
                  <Redirect push to="/brokerage/dashboard/overview" />
                )}
              />
              <Route
                path="/dashboard/onboarding"
                render={() => (
                  <Redirect push to="/brokerage/dashboard/onboarding" />
                )}
              />
              <Route
                path="/new-account"
                render={() => <Redirect push to="/brokerage/new-account" />}
              />
              <Route
                path="/brokerage/international/new-account/:step"
                render={() => <Redirect to="/brokerage/new-account" />}
              />
            </Switch>
          </div>
        </AccountProvider>
      </ConnectedRouter>
      <Intercom status={cognitoStatus} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  cognitoStatus: state.cognito.status,
});

const ConnectedRoutes = connect(mapStateToProps)(Routes);

render();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("components/AlpacaComponents/Layout", () => {
    render();
  });
}

export default history;
