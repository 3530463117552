import querystring from "query-string";
import { logout } from "reducers/auth/logout";
import { parameterizeUrl } from "lib/urls";
import { getSessionJWT } from "../utils";
import { clearUnusedCookies } from "src/utils";
import * as Sentry from "@sentry/browser";

/**
 * apiActions are a more streamlined api method declaration.
 * We export handy predefined method types (GET, POST, etc).
 * I recommend using these instead of the more cumbersome REST method.
 *
 * It allows you to define api methods like this:
 *
 * createNote = POST('accounts/:accountId/notebook/:notebookId/notes')
 *
 * And dispatch it like this:
 * dispatch(createNote(
 *   { accountId, notebookId },
 *   { text: 'Remember to feed Fluffykins' }
 * ))
 *
 * This makes it so that outside code doesn't need to supply a token,
 * and just has a simple action creator which is always called
 * in the same format of (urlParams, payload, headers).
 *
 * NOTE: There is something incomplete about this newer function.
 * There was an idea to abstract REST() and migrate everything to use
 * this wrapper, but your mileage will vary based on the request you need
 * to make and response you're looking for.
 */
const apiAction =
  (method) =>
  (url, options = { public: false }) => {
    const populateUrl = typeof url === "function" ? url : parameterizeUrl(url);

    return (urlParams = {}, payload = {}, headers = {}) =>
      async (dispatch) => {
        let token;
        if (!options.public) {
          try {
            token = await getSessionJWT();
          } catch (err) {
            console.error(err);
            return dispatch(logout());
          }
        }
        const populatedUrl = populateUrl(urlParams);
        return REST(method, populatedUrl)(
          payload,
          token,
          headers,
          null,
          options
        );
      };
  };

export const GET = apiAction("GET");
export const POST = apiAction("POST");
export const PATCH = apiAction("PATCH");
export const PUT = apiAction("PUT");
export const DELETE = apiAction("DELETE");

class InvalidCredentialException extends Error {
  constructor(message) {
    super(message || "invalid credentials");
    this.name = "InvalidCredentialException";
    this.status = 401;
  }
}

class NotFoundException extends Error {
  constructor(message) {
    super(message || "data not found");
    this.name = "NotFoundException";
    this.status = 404;
  }
}

class ApiException extends Error {
  name = "ApiException";

  constructor(props) {
    super(props.message || "Unknown");
    Object.keys(props).forEach((key) => {
      this[key] = props[key];
    });
  }
}

const REST =
  (method, path) =>
  async (
    params = {},
    token = null,
    headers = {},
    endpointOverride = null,
    options = { public: false }
  ) => {
    if (!options.public) {
      token = await getSessionJWT();
    }

    // config is set globally in the build process
    const endpoint =
      endpointOverride == null ? window.env.API_URL : endpointOverride;

    const requestHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Dashboard-Version": process.env.REACT_APP_VERSION || "dev",
      ...headers,
    };

    if (token) {
      requestHeaders.Authorization = `Bearer ${token}`;
    }

    let url = endpoint + path;

    const request = {
      method,
      headers: requestHeaders,
    };

    if (/GET/i.test(method)) {
      const query = querystring.stringify(params);
      if (query) {
        url += `?${query.toString()}`;
      }
    } else if (request.headers["Content-Type"].includes("json")) {
      request.body = JSON.stringify(params);
    } else {
      request.body = params;
      if (request.headers["Content-Type"].includes("multipart/form-data")) {
        delete request.headers["Content-Type"];
      }
    }

    return fetch(url, request)
      .then((resp) => {
        if (resp?.status === 401) throw new InvalidCredentialException();
        if (resp?.status === 404) throw new NotFoundException();
        if (resp?.status === 204) return null;

        // status not in the range 200-299
        if (resp && !resp.ok) {
          throw resp;
        }

        if (resp.headers.get("content-type")?.startsWith("application/pdf")) {
          // pdfs are blob data, not json data
          return resp.blob();
        }

        return resp.json();
      })
      .catch((e) => {
        if (e instanceof Error) {
          throw e;
        }

        const errValues = {
          status: e.status,
          method,
          path,
          params,
        };

        return e
          .json()
          .then((data) => {
            errValues.response = data;
            errValues.message = data.message;
          })
          .catch((err) => {
            errValues.message = "error parsing JSON response";
            console.error("api error: ", errValues);
            console.log(err);
          })
          .finally(() => {
            Sentry.withScope((scope) => {
              scope.setExtras(errValues);
              Sentry.captureException(
                `api returned status ${errValues.status}`
              );
            });

            throw new ApiException(errValues);
          });
      });
  };

export default REST;
