import React from "react";
import cn from "classnames";

import { Text } from "@alpacahq/ui";

export interface CardProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  title?: string;
  tools?: React.ReactNode;
}

export const Card = ({
  title,
  tools,
  children,
  className,
  ...more
}: CardProps) => {
  return (
    <div
      className={cn(
        "space-y-6 p-6 rounded-lg border border-gray-200 bg-white",
        className
      )}
      {...more}
    >
      {(title !== undefined || tools !== undefined) && (
        <div className="flex space-x-2 justify-between items-start">
          {title && <Text className="text-lg font-medium">{title}</Text>}
          {tools}
        </div>
      )}

      {children}
    </div>
  );
};

export default Card;
