import {
  useQuery,
  QueryFunction,
  UseQueryResult,
  UseQueryOptions,
  QueryKey,
} from "react-query";

import { useToast } from "@chakra-ui/toast";
import { defaultToastSettings, getErrorMessage } from ".";

export type QueryOptions<T> = UseQueryOptions<T, Error, T, QueryKey>;

export function useRequest<T>(
  queryKey: QueryKey,
  queryFn: QueryFunction<T>,
  options: QueryOptions<T> = {}
): UseQueryResult<T, unknown> {
  const toast = useToast();

  const defaultQueryOptions: QueryOptions<T> = {
    onError: (err) => {
      // todo: suppress account errors for /trade auth check (for now)
      if (queryKey?.includes("account")) {
        return;
      }
      const key = "errorToastKey";
      if (toast.isActive(key)) return;
      const message = getErrorMessage(err);

      toast({ id: key, description: message, ...defaultToastSettings });
    },
  };

  const data = useQuery(queryKey, queryFn, {
    ...defaultQueryOptions,
    ...options,
  });

  return data;
}

export default useRequest;
