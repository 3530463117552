import React from "react";

import {
  BoxProps,
  Spinner as ChakraSpinner,
  SpinnerProps,
} from "@chakra-ui/react";

export const Spinner = ({
  size,
  thickness,
  ...more
}: BoxProps & SpinnerProps) => (
  <div
    className="flex w-screen h-screen justify-center items-center z-[999999] bg-white absolute py-4 top-0 left-0 bg-opacity-50 backdrop-filter backdrop-blur-sm"
    {...more}
  >
    <ChakraSpinner
      thickness={thickness || "4px"}
      speed="0.65s"
      emptyColor="gray.20"
      color="#f7c911"
      size={size || "xl"}
    />
  </div>
);

export default Spinner;
