import useRequest, { QueryOptions } from "./useRequest";

import { getClock, Clock } from "../rest/data";

export const useGetClock = (
  key: any | any[] = "",
  options?: QueryOptions<Clock>
) => {
  const { data: clock } = useRequest(key, () => getClock(), { ...options });

  return { clock };
};

export default useGetClock;
