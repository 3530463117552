import { createAction, createReducer } from "redux-act";
import api from "api";

/**
 * ACTIONS
 */
const _setCountryInfo = createAction("COUNTRY_CONFIG");

/**
 * API CALLS
 */
export const getCountryInfos = () => (dispatch) => {
  dispatch(api.countries.getCountryInfos())
    .then((infoMap) => dispatch(_setCountryInfo({ infoMap })))
    .catch(() => console.error("error fetching country info map"));
};

/**
 * REDUCER
 */
const initialState = {};
export default createReducer(
  {
    [_setCountryInfo]: (state, { infoMap }) => ({ ...state, infoMap }),
  },
  initialState
);
