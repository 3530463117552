import { createAction, createReducer } from "redux-act";
import { handleSessionExpire } from "./alpacaAuth/session";
import { billing } from "api";

const REDUCER = "billing";
const NS = `@@${REDUCER}/`;

const _setPlans = createAction(`${NS}SET_PLANS`);
const _billingCreated = createAction(`${NS}BILLING_CREATED`);
const _billingCanceled = createAction(`${NS}BILLING_CANCELED`);
const _billingUpdated = createAction(`${NS}BILLING_UPDATED`);
const _getOverview = createAction(`${NS}BILLING_OVERVIEW`);

const _processStripeResponse = async (stripe, overview) => {
  const { paymentIntentSecret, setupIntentSecret } = overview;

  if (paymentIntentSecret) {
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      paymentIntentSecret
    );

    if (error) {
      return Promise.reject(error);
    }

    if (paymentIntent.status === "succeeded") {
      return Promise.resolve(overview);
    } else if (paymentIntent.status === "requires_payment_method") {
      return Promise.reject(paymentIntent.last_payment_error);
    }
  }

  if (setupIntentSecret) {
    const { error, setupIntent } = await stripe.confirmCardSetup(
      setupIntentSecret
    );

    if (error) {
      return Promise.reject(error);
    }

    if (setupIntent.status === "succeeded") {
      return Promise.resolve(overview);
    } else if (setupIntent.status === "requires_payment_method") {
      return Promise.reject(setupIntent.last_setup_error);
    }
  }

  return Promise.resolve(overview);
};

/**
 * getPlans will retrieve a list of all plans
 */
export const getPlans = () => (dispatch) =>
  dispatch(billing.plans()).then((plans) => dispatch(_setPlans({ plans })));

/**
 * createStripeSubscription will create a stripe subscription for the user
 */
export const createStripeSubscription = (
  stripe,
  token,
  plan,
  address,
  coupon
) => (dispatch, getState) => {
  return handleSessionExpire(
    billing.stripe
      .create({}, { token, plan, address, coupon })(dispatch, getState)
      .then((overview) => _processStripeResponse(stripe, overview))
      .then((overview) => dispatch(_billingCreated({ overview }))),
    dispatch
  );
};

/**
 * createAlpacaSubscription will create a subscription via brokerage for the user
 */
export const createAlpacaSubscription = (plan, coupon) => (
  dispatch,
  getState
) => {
  return handleSessionExpire(
    billing.alpaca
      .create({}, { plan, coupon })(dispatch, getState)
      .then(() => dispatch(getBillingOverview())),
    dispatch
  );
};

/**
 * cancelstripeSubscription will cancel the subscription via brokerage for the user
 */
export const cancelStripeSubscription = (reason) => (dispatch, getState) => {
  return handleSessionExpire(
    billing.stripe
      .cancel({}, { reason })(dispatch, getState)
      .then(async (overview) => dispatch(_billingCanceled({ overview }))),
    dispatch
  );
};

/**
 * cancelAlpacaSubscription will cancel the subscription via brokerage for the user
 */
export const cancelAlpacaSubscription = (reason) => (dispatch, getState) => {
  return handleSessionExpire(
    billing.alpaca
      .cancel({}, { reason })(dispatch, getState)
      .then(async (overview) => dispatch(_billingCanceled({ overview }))),
    dispatch
  );
};

/**
 * updateStripeSubscription will update the subscription via stripe for the user
 */
export const updateStripeSubscription = (stripe, token) => (
  dispatch,
  getState
) => {
  return handleSessionExpire(
    billing.stripe
      .update({}, { token })(dispatch, getState)
      .then((overview) => _processStripeResponse(stripe, overview))
      .then((overview) => dispatch(_billingUpdated({ overview }))),
    dispatch
  );
};

/**
 * getBillingOverview will retrieve the billing overview for the user
 */
export const getBillingOverview = () => (dispatch, getState) => {
  return handleSessionExpire(
    billing
      .overview({})(dispatch, getState)
      .then(async (overview) => dispatch(_getOverview({ overview })))
      .catch(console.error),
    dispatch
  );
};

// Export this reducer
const initialState = {};
export default createReducer(
  {
    [_setPlans]: (state, { plans }) => {
      return { ...state, plans };
    },
    [_billingCreated]: (state, { overview }) => {
      return { ...state, overview };
    },
    [_getOverview]: (state, { overview }) => {
      return { ...state, overview };
    },
    [_billingCanceled]: (state, { overview }) => {
      return { ...state, overview };
    },
    [_billingUpdated]: (state, { overview }) => {
      return { ...state, overview };
    },
  },
  initialState
);
