import { createAction as baseCreateAction, createReducer } from "redux-act";
import api from "api";

const REDUCER = "accountList";
const NS = `@@${REDUCER}`;
const createAction = (name) => baseCreateAction(`${NS}/${name}`);

export const _receive = createAction("RECEIVE");
export const _fetch = createAction("FETCH");
export const _error = createAction("ERROR");
export const _invalidate = createAction("INVALIDATE");
export const _reset = createAction("RESET_ACCOUNTS");

const initialState = {
  status: "invalid",
  payload: null,
  error: null,
};

export const load = () => (dispatch, getState) => {
  const state = getState().accountList;
  switch (state.status) {
    case "invalid":
      dispatch(_fetch());
      return dispatch(api.account.list())
        .then((accounts) => {
          dispatch(_receive(accounts));
          return accounts;
        })
        .catch((error) => {
          dispatch(_error(error));
          throw error;
        });

    case "error":
      return Promise.reject(state.error);

    default:
      return Promise.resolve(state.payload);
  }
};

export const reload = () => (dispatch) => {
  return dispatch(api.account.list())
    .then((accounts) => {
      dispatch(_receive(accounts));
      return accounts;
    })
    .catch((error) => {
      dispatch(_error(error));
      throw error;
    });
};

export const resetState = () => (dispatch) => {
  dispatch(_reset());
};

export default createReducer(
  {
    [_receive]: (state, param) => ({
      ...state,
      status: "loaded",
      payload: param,
    }),
    [_fetch]: (state) => ({
      ...state,
      status: "loading",
      payload: null,
      error: null,
    }),
    [_error]: (state, param) => ({
      ...state,
      status: "error",
      error: param,
    }),
    [_invalidate]: (state) => ({
      ...state,
      status: "invalid",
      payload: null,
      error: null,
    }),
    [_reset]: () => initialState,
  },
  initialState
);
