import useRequest, { QueryOptions } from "./useRequest";
import { Account, getLiveAccounts } from "../rest/account";

export const useGetLiveAccounts = (options?: QueryOptions<Account[]>) => {
  const { data, isLoading, refetch } = useRequest<Account[]>(
    ["accounts", "live"],
    () => getLiveAccounts(),
    { refetchInterval: false, ...options }
  );

  return { data, refetch, isLoading };
};

export default useGetLiveAccounts;
