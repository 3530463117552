import { useMemo } from "react";
import { useRequest } from "src/v2/api/hooks";
import {
  getAchTransfers,
  getRapydTransfers,
  Transfer,
} from "src/v2/api/rest/banking";
import { getCurrencyCloudTransfers } from "src/v2/api/rest/currencyCloud";

type UseGetAllTransfersProps = {
  accountID: string;
  isCurrencyCloudEnabled?: boolean;
  refetchOnMount?: boolean | "always";
};

export type UseGetAllTransfersReturn = {
  achAndWire: {
    transfers: Transfer[];
    isLoading: boolean;
    refetch: () => void;
  };
  rapyd: {
    transfers: Transfer[];
    isLoading: boolean;
    refetch: () => void;
  };
  currencyCloud: {
    transfers: Transfer[];
    isLoading: boolean;
    refetch: () => void;
  };
};

export const useGetAllTransfers = ({
  accountID,
  isCurrencyCloudEnabled,
  refetchOnMount,
}: UseGetAllTransfersProps): UseGetAllTransfersReturn => {
  // This actually includes wire transfers too
  const achAndWire = useRequest(
    ["ach-transfers", accountID],
    () => getAchTransfers(accountID),
    {
      enabled: !!accountID,
      refetchOnMount,
    }
  );

  const rapyd = useRequest(
    ["rapyd-transfers", accountID],
    () => getRapydTransfers(accountID),
    {
      enabled: !!accountID,
      refetchOnMount,
    }
  );

  const currencyCloud = useRequest(
    "cc-transfers",
    () => getCurrencyCloudTransfers(accountID),
    {
      enabled: !!accountID && isCurrencyCloudEnabled,
      refetchOnMount,
    }
  );

  const ccTransfers: Transfer[] = useMemo(
    () =>
      currencyCloud.data?.map((cc) => ({
        id: cc.id,
        status: cc.status,
        amount: parseFloat(cc.amount),
        created_at: cc.created_at,
        type: "CurrencyCloud",
        direction: cc.direction,
      })) ?? [],
    [currencyCloud.data]
  );

  return {
    achAndWire: {
      transfers: achAndWire.data || [],
      isLoading: achAndWire.isLoading,
      refetch: achAndWire.refetch,
    },
    rapyd: {
      transfers: rapyd.data || [],
      isLoading: rapyd.isLoading,
      refetch: rapyd.refetch,
    },
    currencyCloud: {
      transfers: ccTransfers || [],
      isLoading: currencyCloud.isLoading,
      refetch: currencyCloud.refetch,
    },
  };
};
