import React from "react";
import { createAction, createReducer } from "redux-act";
import { notification, Avatar } from "antd";
import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";
import { setUserState } from "reducers/auth/common";
import { getCurrentAuthenticatedUser } from "../utils";

export const REDUCER = "profile";
const NS = `@@${REDUCER}/`;

const _setProfilePic = createAction(`${NS}SET_PROFILE_PIC`);
const _setProfilePicUrl = createAction(`${NS}SET_PROFILE_PIC_URL`);
const _setPhoneNumberToVerify = createAction(`${NS}SET_PHONE_NUMBER_TO_VERIFY`);
const _setPhoneNumberVerificationError = createAction(
  `${NS}SET_PHONE_NUMBER_VERIFICATION_ERROR`
);

export const submit = (values) => (dispatch) => {
  // Upload profile pic to the user's directory in S3 bucket
  if (values.profile_picture) {
    dispatch(uploadProfilePic(values.profile_picture));
  }

  dispatch(updateInCognito(values));
};

// Please keep in mind that when extending the capabilities of this function that cognito has a permisive
// scope for the AccessToken that they are returning and there is no way of configuring that. This in turn
// helps hackers exploit the AccessToken to change sensitive information such as email and phone number of the cognito account.
// We want to avoid changing attributes using aws-amplify in the future and just direct the calls to gobroker.
// This would help maintain the consistency of the data and as well as prevent the exploit.
// More details of the exploit over here: https://alpaca.atlassian.net/browse/ENG-9903
const updateInCognito = (values) => (dispatch, getState) => {
  const state = getState();

  // If the user stored a phone number, then they will be sent a verification code.
  // Ensure state updates so that the form page knows to display a form to allow verification.
  if (
    values.phone_number &&
    values.phone_number !== state.auth.userState.attributes.phone_number
  ) {
    dispatch(_setPhoneNumberToVerify(true));
  }

  // If the user also sent fields to reset their password, do that too.
  if (values.old_password && values.new_password) {
    // Only the current user can change their password of course
    // Even if someone else stole a user's password, logged in, and changed it...
    // The original user could use "forgot password" and reset it from the login screen.
    // IF we also handled the API to change a username/e-mail, the attacker wouldn't be
    // able to change the e-mail either because a verification code is sent for that API method.
    getCurrentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          values.old_password,
          values.new_password
        );
      })
      .then(() => {
        // TODO: It would be nice to also send an e-mail notifying the user that someone, hopefully them,
        // changed their password. If it was them, ignore, if it wasn't, forgot password, etc. etc.

        notification.open({
          type: "success",
          message: "Your password was changed",
        });
        dispatch(refreshUserAttributes());
      })
      .catch((err) => {
        switch (err.code) {
          case "NotAuthorizedException": {
            notification.open({
              type: "error",
              message: "Entered old password is incorrect",
            });
            break;
          }
          default: {
            notification.open({
              type: "error",
              message: err.message,
            });
            break;
          }
        }
        console.debug(err);
      });
  } else {
    // Just a profile update, no password change
    notification.open({
      type: "success",
      message: "Your profile was updated",
    });
    dispatch(refreshUserAttributes());
  }
};

/**
 * Verifies the current user's phone_number attribute in Cognito.
 *
 * @param {string} code
 */
export const verifyPhoneNumber = (code) => (dispatch) => {
  if (code) {
    Auth.verifyCurrentUserAttributeSubmit("phone_number", code)
      .then(() => {
        // The response is just going to be "SUCCESS" string like account verification
        // This either works or it fails and can be caught
        notification.open({
          type: "success",
          message: "Your phone number was verified successfully",
        });
        dispatch(_setPhoneNumberToVerify(false));
        // Update the data fresh
        dispatch(refreshUserAttributes());
      })
      .catch((err) => {
        console.debug("Failed to verify phone number", err);
        dispatch(_setPhoneNumberVerificationError(err));
      });
  }
};

/**
 * Resends a code to verify an attribute.
 */
export const resendAttributeVerification = (attribute = "phone_number") => (
  dispatch
) => {
  return Auth.verifyCurrentUserAttribute(attribute).then(() => {
    if (attribute === "phone_number") {
      return dispatch(_setPhoneNumberToVerify(true));
    }
  });
};

/**
 * Refreshes user attributes (and bypasses any cache).
 */
export const refreshUserAttributes = () => (dispatch) => {
  // Update the data fresh
  return getCurrentAuthenticatedUser().then((user) => {
    user.getUserData(
      () => {
        // Now we can get fresh
        return getCurrentAuthenticatedUser().then((freshUser) => {
          return dispatch(
            setUserState({
              userState: freshUser,
            })
          );
        });
        //
      },
      { bypassCache: true }
    );
  });
};

export const uploadProfilePic = (file) => (dispatch) => {
  // It's probably better to use file.name
  // then contentType: file.type
  // But this makes for a predictable path to the user's profile picture.
  // That means if user ids are stored somewhere (DynamoDB, S3, etc.) and accessed,
  // the profile picture can be found without an API call. There would be no lookup
  // for a potentially changed filename. User attributes also need not be set.
  Storage.put("profile.png", file, {
    level: "protected",
    region: "us-east-1",
    contentType: "image/png",
  }).catch((err) => {
    console.error(err);
    notification.open({
      type: "error",
      message: "Could not upload profile picture, try again",
    });
  });
};

export const getProfilePic = () => (dispatch) => {
  // Hack around limitation in Storage.get()
  // https://github.com/aws-amplify/amplify-js/issues/1145
  // This always makes for two requests...May even move to leaving profile pictures as public.
  // Just prefix the keys with the user id. Then, maybe using axois?, it would be to just load
  // the profile pic from S3 (where it wouldn't need temporary credentials) and if 404, then
  // show the default avatar. That would make for one request.
  // On one hand, there's a desire to keep these protected - only authenticated users can
  // see (or perhaps really need to see) the profile pictures. On the other hand, is it
  // universally assumed a profile picture is public domain?
  //
  // Note: use region where bucket is (otherwise 400 error)
  Storage.list("profile.png", { level: "protected", region: "us-east-1" })
    .then((result) => {
      if (result.length > 0) {
        // Use region where bucket is located
        Storage.get("profile.png", { level: "protected", region: "us-east-1" })
          .then((result) => {
            dispatch(
              _setProfilePic(
                <img
                  alt="profile pic"
                  className="profile-picture"
                  src={result}
                />
              )
            );
            dispatch(_setProfilePicUrl(result));
          })
          .catch(() => {
            // It's ok if not found.
          });
      }
    })
    .catch((err) => {
      // Also ok if error in the response, the default avatar component will be used.
      console.debug(err);
    });
};

const initialState = {
  pic: (
    <Avatar
      className="topbar__avatar"
      shape="square"
      size="large"
      icon="user"
    />
  ),
  picUrl: "",
  phoneNumberToVerify: false,
};

export default createReducer(
  {
    [_setProfilePic]: (state, component) => ({ ...state, pic: component }),
    [_setProfilePicUrl]: (state, url) => ({ ...state, picUrl: url }),
    [_setPhoneNumberToVerify]: (state, phoneNumberToVerify) => ({
      ...state,
      phoneNumberToVerify,
    }),
    [_setPhoneNumberVerificationError]: (
      state,
      phoneNumberVerificationError
    ) => ({ ...state, phoneNumberVerificationError }),
  },
  initialState
);
