import { createAction as baseCreateAction, createReducer } from "redux-act";
import * as Sentry from "@sentry/browser";
import { getCurrentAuthenticatedUser, UserNotAuthenticated } from "../../utils";

const REDUCER = "cognito";
const NS = `@@${REDUCER}`;
const createAction = (name) => baseCreateAction(`${NS}/${name}`);

export const _receive = createAction("RECEIVE");
export const _fetch = createAction("FETCH");
export const _error = createAction("ERROR");
export const _invalidate = createAction("INVALIDATE");

const initialState = {
  status: "invalid",
  payload: null,
  error: null,
};

export const load = () => (dispatch, getState) => {
  const state = getState().cognito;
  if (state.status === "invalid") {
    dispatch(_fetch());
    return getCurrentAuthenticatedUser()
      .then((data) => {
        Sentry.setUser({ email: data.attributes?.email });
        dispatch(_receive(data));
        return data;
      })
      .catch((error) => {
        if (error instanceof UserNotAuthenticated) {
          dispatch(_receive(null));
          return null;
        }
        dispatch(_error(error));
        throw error;
      });
  } else if (state.status === "error") {
    return Promise.reject(state.error);
  } else if (state.status === "loaded") {
    return Promise.resolve(state.payload);
  }
  // } else if (state.status === 'loading') {
  //     // TODO:
  //     return Promise.resolve(state.payload)
  // }
};

export default createReducer(
  {
    [_receive]: (state, param) => ({
      ...state,
      status: "loaded",
      payload: param,
    }),
    [_fetch]: (state) => ({
      ...state,
      status: "loading",
      payload: null,
      error: null,
    }),
    [_error]: (state, param) => ({
      ...state,
      status: "error",
      error: param,
    }),
    [_invalidate]: (state) => ({
      ...state,
      status: "invalid",
      payload: null,
      error: null,
    }),
  },
  initialState
);
