import { isEmpty } from "lodash";

// @todo mocked until flag-service is deprecated
export const setFlag = (flag: string, value: boolean) => {
  localStorage.setItem(flag, value ? "true" : "false");
};

export const getFlag = (flag: string) => localStorage.getItem(flag);

// @todo mocked until flag-service is deprecated
export const useFlag = (flag: string) => {
  const value = localStorage.getItem(flag);

  // again, mocked until flag-service is deprecated
  if (flag === "uix-v2") {
    return true;
  }

  return value === "true";
};

export const featureFlagManager = (flag: string, percentage: number) => {
  const flagString = getFlag(flag) ?? "{}";
  let flagObject = null;

  try {
    // Parse the flag string, or initialize an empty object if the string is invalid or null
    flagObject = JSON.parse(flagString ?? "{}");
  } catch (e) {
    console.error("Error parsing JSON for flag:", flag, e);
  }

  // If feature flag was never set or percentage increases then we will determine
  // if the user is eligible to be the testing group
  if (
    isEmpty(flagObject) ||
    (flagObject?.percentage < percentage && flagObject?.status === false)
  ) {
    if (Math.random() * 100 <= percentage) {
      localStorage.setItem(
        flag,
        JSON.stringify({ status: true, percentage: percentage })
      );
    } else {
      localStorage.setItem(
        flag,
        JSON.stringify({ status: false, percentage: percentage })
      );
    }
  }
};

export const isFeatureAccessible = (flag: string) => {
  const flagObject = JSON.parse(getFlag(flag) ?? "{}");
  const isDebugMode = getFlag("debug") === "true";

  if (isDebugMode) {
    return true;
  } else {
    return flagObject?.status ?? false;
  }
};
