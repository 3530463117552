import styled from "styled-components";

const LargeParagraph = styled.p`
  font-size: 1.5em;

  @media screen and (max-height: 600px) {
    font-size: 1.2em;
  }
`;

export default LargeParagraph;
