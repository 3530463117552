import { useRequest } from ".";
import { getActivities, GetActivitiesParams } from "../rest/account";

export const useGetActivities = (
  { accountID, paper, activityTypes, after, date, until }: GetActivitiesParams,
  options = {}
) => {
  const { data, ...rest } = useRequest(
    [
      "activity",
      accountID,
      paper,
      activityTypes?.join(","),
      after,
      date,
      until,
    ],
    () =>
      getActivities({
        accountID: accountID || "",
        paper,
        activityTypes,
        after,
        date,
        until,
      }),
    {
      ...options,
      // todo: revisit this override of default behavior (duplicate error handling)
      onError: () => null,
    }
  );

  return { activities: data || [], ...rest };
};

export default useGetActivities;
