import { createAction, createReducer } from "redux-act";
import api from "api";
import { getAccountIdForProduct } from "selectors";

const REDUCER = "portfolio";
const NS = `@@${REDUCER}/`;

const _setPortfolioHistory = createAction(`${NS}SET_PORTFOLIO_HISTORY`);

// section options are: intraday, 1M, 1A, all
export const loadPortfolioHistory = (product, section) => (
  dispatch,
  getState
) => {
  // Figure out the account id to use
  const accountId = getAccountIdForProduct(getState(), product);
  const portfolioEndPoint =
    product === "paper" ? "paperPortfolio" : "portfolio";

  // check if user holds crypto
  const crypto = getState()?.position?.[accountId]?.positions?.find(
    (p) => p.asset_class === "crypto"
  );

  const params = { period: section, extended_hours: true };

  if (accountId) {
    return dispatch(api[portfolioEndPoint].get({ accountId }, params)).then(
      (perf) =>
        dispatch(
          _setPortfolioHistory({
            accountId,
            section,
            data: perf,
          })
        )
    );
  }

  return Promise.resolve();
};

const initialState = {};
export default createReducer(
  {
    [_setPortfolioHistory]: (state, { accountId, section, data }) => {
      // e.g. portfolio.bfe87e76-5ad3-41d6-a12d-918142bb38df.1M
      const newState = { ...state };
      newState[accountId] = {
        ...newState[accountId],
        [section]: data,
      };
      return newState;
    },
  },
  initialState
);
