import { createAction as baseCreateAction, createReducer } from "redux-act";
import api from "api";
import { handleSessionExpire } from "./alpacaAuth/session";
import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";
import "fast-text-encoding";
import {
  getAccountIdAndJWT,
  getAlpacaAccountId,
  getAccountIdForProduct,
} from "selectors";
import { unsetAccessKeys } from "reducers/accessKeys";
import { notification } from "antd";
import { push } from "connected-react-router";
import { setEmailUpdateVerificationRequired } from "../reducers/auth/common";
import { getCookie } from "src/lib/cookies";
import { getCurrentAuthenticatedUser } from "../utils";
import { getSessionJWT, weakEncrypt } from "../utils";
import { eventTracker } from "src/utils/eventTracker";

const REDUCER = "account";
const NS = `@@${REDUCER}/`;
const createAction = (name) => baseCreateAction(NS + name);

// accounts
export const SET_ACCOUNT = "SET_ACCOUNT";
const _setAccount = createAction(SET_ACCOUNT);
const _setAccountDetails = createAction("SET_DETAILS");
const _setTrustedContact = createAction("SET_TRUSTED_CONTACT");
const _setPaperAccount = createAction("SET_PAPER_ACCOUNT");
export const unsetPaperAccount = createAction("UNSET_PAPER_ACCOUNT");
const _setPaperAccounts = createAction("SET_PAPER_ACCOUNTS");
const _setSyncAccounts = createAction("SET_SYNC_ACCOUNTS");

// relationships
const _setRelationships = createAction("SET_RELATIONSHIPS");
const _setWireBanks = createAction("SET_WIRE_BANKS");
const _setInstitution = createAction("SET_INSTITUTION");
const _setCreateRelationshipStatus = createAction(
  "SET_CREATE_RELATIONSHIP_STATUS"
);
const _setCreateWireBankStatus = createAction("SET_CREATE_WIRE_BANK_STATUS");
export const clearCreateRelationshipStatus = createAction(
  "CLEAR_CREATE_RELATIONSHIP_STATUS"
);
export const clearCreateWireBankStatus = createAction(
  "CLEAR_CREATE_WIRE_BANK_STATUS"
);
const _setPlaidToken = createAction("SET_PLAID_TOKEN");

// transfers
const _setTransfers = createAction("SET_TRANSFERS");

// activities
const _setActivities = createAction("SET_ACTIVITIES");

// documents
const _setDocuments = createAction("SET_DOCUMENTS");

// affiliates
const _setAffiliates = createAction("SET_AFFILIATES");
// onfido sdk token
const _setOnfidoSDKToken = createAction("SET_ONFIDO_SDK_TOKEN");
// onfido applicant id
const _setOnfidoApplicantID = createAction("SET_ONFIDO_APPLICANT_ID");

// configs
const _reqConfigs = createAction("REQ_CONFIGS");
const _setConfigs = createAction("SET_CONFIGS");

// "props" stored to S3 as JSON
const _setOwnerProps = createAction(`SET_OWNER_PROPS`);

const onboardingApiAction =
  (fn) =>
  (...args) =>
  async (dispatch, getState) => {
    const { token } = await getSessionJWT();
    let accountId = args["accountId"];

    // This might occur on page reload, the account is not retrieved all the time.
    // It is passed through props. So not coming back to the onboarding flow is fine,
    // but when coming back (hard browser reload, etc.), the minimum amount of info we
    // need is the account ID.
    if (!accountId) {
      return dispatch(getAccount()).then(() => {
        accountId = getAlpacaAccountId(getState()) || null;
        return handleSessionExpire(
          fn(dispatch, getState, accountId, token, ...args).then(() => ({
            ok: true,
          })),
          dispatch
        ).catch((error) => ({ error }));
      });
    } else {
      return handleSessionExpire(
        fn(dispatch, getState, accountId, token, ...args).then(() => ({
          ok: true,
        })),
        dispatch
      ).catch((error) => ({ error }));
    }
  };

export const getAccount = () => (dispatch, getState) => {
  const state = getState();

  const userState = (state && state.auth && state.auth.userState) || {};

  const token =
    (userState.signInUserSession && userState.signInUserSession.idToken) || {};

  const associatedAccountId =
    (userState.attributes && userState.attributes["custom:account_id"]) ||
    false;

  const accountList = getState().accountList.payload;
  if (!accountList) {
    throw Error("accountList not available yet");
  }
  return Promise.resolve(accountList)
    .then((accounts) => {
      if (accounts && accounts[0]) {
        // If there was no associated account id yet, update the Cognito user attribute
        if (!associatedAccountId) {
          getCurrentAuthenticatedUser()
            .then((cognitoUser) => {
              Auth.updateUserAttributes(cognitoUser, {
                "custom:account_id": accounts[0].id || "",
              });
            })
            .catch((e) =>
              console.error(`Error updating cognito attributes: ${e}`)
            );
        }
        // if account is activated remove it from kyc localstore
        if (accounts[0].status === "ACTIVE") {
          let kyc = JSON.parse(window.localStorage.getItem("kyc"));
          if (kyc) {
            if (accounts[0].id in kyc) delete kyc?.[accounts[0].id];
            window.localStorage.setItem("kyc", JSON.stringify(kyc));
          }
        }

        // Set the account info in state, then get the account details and load the trade account
        return dispatch(
          _setAccount({
            account: accounts[0] || {},
          })
        );
      }
      return;
    })
    .catch((err) => {
      console.error("error getting list of accounts:", err);

      // If no account for the Cognito user, it will return a 401
      // Create an account in gobroker
      const email = (token.payload && token.payload.email) || "";
      const clearing_broker =
        (token.payload && token.payload["custom:clearing_broker"]) || "";
      const source =
        (token.payload && token.payload["custom:signup_source"]) || null;

      return api.account
        .create({ email, clearing_broker, source }, token)
        .then((details) => {
          // Update Cognito user attribute to associate account id
          return Auth.updateUserAttributes(state.auth.userState, {
            "custom:account_id": details.id || "",
          }).then(() => {
            // success, try getting the account again now
            return api.account.list({}, token).then((accounts) => {
              return dispatch(
                _setAccount({
                  account: accounts[0] || {},
                })
              );
            });
          });
        })
        .catch((err) => {
          // It could fail to create an account if the email already exists.
          // In that case, it's possible something is wrong with the JWT or it's expired.
          // Should not happen, but if so, ensure the user is logged out. they can login again.
          console.error("failed to create account:", err);
          // return dispatch(logout())
        });
    });
};

export const loadAccountDetails = () => async (dispatch, getState) => {
  const token = await getSessionJWT();
  const accountId = getAlpacaAccountId(getState());

  if (accountId) {
    return handleSessionExpire(
      api.ownerDetails
        .get(accountId, token)
        .then((details) => dispatch(_setAccountDetails({ details }))),
      dispatch
    );
  }
  return;
};

export const patchAccountDetail = onboardingApiAction(
  (dispatch, getState, accountId, token, params) =>
    api.ownerDetails
      .patch(accountId, params, token)
      .then(() => dispatch(loadAccountDetails()))
);

export const resetOnboarding = onboardingApiAction(
  (dispatch, getState, accountId, token) =>
    api.ownerDetails
      .resetOnboarding(accountId, token)
      .then(() => dispatch(loadAccountDetails()))
      .then(() => dispatch(api.relationships.list({ accountId })))
      .then((relationships) => {
        if (relationships.length === 0) return;
        return Promise.all(
          relationships.map((relationship) =>
            dispatch(
              api.relationships.delete({
                accountId,
                relationshipId: relationship.id,
              })
            )
          )
        );
      })
      .then(() => dispatch(api.transfers.list({ accountId })))
      .then((transfers) => {
        if (transfers.length === 0) return;
        return Promise.all(
          transfers.map((transfer) =>
            dispatch(
              api.transfers.delete({
                accountId,
                transferId: transfer.id,
              })
            )
          )
        );
      })
      .then(() =>
        api.accessKeys.list(accountId, token).then((accessKeys) => {
          if (accessKeys.length === 0) return;
          return Promise.all(
            accessKeys.map((key) => api.accessKeys.delete(accountId, key.id))
          );
        })
      )
      .then(() => dispatch(push("/brokerage/new-account")))
);

export const postTrustedContact = onboardingApiAction(
  (dispatch, getState, accountId, token, params) =>
    api.trustedContact
      .post(accountId, token, params)
      .then((result) => dispatch(_setTrustedContact(result)))
);

export const listAffiliates = onboardingApiAction(
  (dispatch, getState, accountId, token) =>
    api.affiliates
      .list(accountId, token)
      .then((result) => dispatch(_setAffiliates(result)))
);

export const getOnfidoSDKToken = onboardingApiAction(
  (dispatch, getState, accountId, token) =>
    api.onfidoSdkToken
      .get(accountId, token)
      .then((result) => dispatch(_setOnfidoSDKToken(result)))
      .catch((e) => {
        notification.open({
          type: "error",
          message: "Could not create Token for KYC",
          description: "Please contact support.",
        });
        throw e;
      })
);

export const patchOnfidoOutcome = onboardingApiAction(
  (dispatch, getState, accountId, _, body) =>
    dispatch(api.onfidoSdkToken.patch({ accountId }, body)).catch((e) => {
      notification.open({
        type: "error",
        message: "Could not save Onfido SDK outcome",
        description: "Please contact support.",
      });
    })
);

export const createOnfidoApplicant = onboardingApiAction(
  (dispatch, getState, accountId, _) =>
    dispatch(api.onfidoApplicant.post({ accountId }))
      .then((result) => {
        const kyc = JSON.parse(window.localStorage.getItem("kyc")) || {};
        window.localStorage.setItem(
          "kyc",
          JSON.stringify({
            ...kyc,
            [accountId]: result.onfido_details.applicant_id,
          })
        );
        dispatch(_setOnfidoApplicantID(result));
      })
      .catch((e) => {
        notification.open({
          type: "error",
          message: "Could not create Applicant for KYC",
          description: "Please contact support.",
        });
        throw e;
      })
);

export const updateOnfidoApplicant = onboardingApiAction(
  (dispatch, getState, accountId, _) =>
    dispatch(api.onfidoApplicant.patch({ accountId })).catch((e) => {
      notification.open({
        type: "error",
        message: "Could not update Applicant for KYC",
        description: "Please contact support.",
      });
      throw e;
    })
);

export const postAffiliate = onboardingApiAction(
  (dispatch, getState, accountId, token, params) =>
    api.affiliates
      .post(accountId, token, params)
      .then(() => dispatch(listAffiliates()))
);

export const patchAffiliate = onboardingApiAction(
  (dispatch, getState, accountId, token, affiliateId, params) =>
    api.affiliates
      .patch(accountId, affiliateId, token, params)
      .then(() => dispatch(listAffiliates()))
);

//user configurations
export const listConfigs = (product) => (dispatch, getState) => {
  let { accountId, paperAccountId } = getAccountIdAndJWT(getState());

  if (!accountId) {
    dispatch(getAccount()).then(() => {
      accountId = getAlpacaAccountId(getState()) || null;
    });
  }

  if (product === "paper") {
    dispatch(_reqConfigs());
    return dispatch(api.paperConfigs.list({ paperAccountId }))
      .then((result) => {
        result.accounts = { paper: paperAccountId, live: accountId };
        dispatch(_setConfigs(result));
      })
      .catch((e) => {
        console.error(e);
        notification.open({
          type: "error",
          message: "Could not load configurations",
          description:
            "There was a problem trying to load your account configurations. Please try again.",
        });
      });
  }

  dispatch(_reqConfigs());
  return dispatch(api.configs.list({ accountId }))
    .then((result) => {
      result.accounts = { paper: paperAccountId, live: accountId };
      dispatch(_setConfigs(result));
    })
    .catch((e) => {
      console.error(e);
      notification.open({
        type: "error",
        message: "Could not load configurations",
        description:
          "There was a problem trying to load your account configurations. Please try again.",
      });
    });
};

export const patchConfig = (params, product) => (dispatch, getState) => {
  let { accountId, paperAccountId } = getAccountIdAndJWT(getState());

  if (product === "paper") {
    dispatch(_reqConfigs());
    return dispatch(api.paperConfigs.patch({ paperAccountId }, params)).then(
      () => {
        dispatch(listConfigs("paper"));
      },
      (err) => {
        console.log(err);
        notification.open({
          type: "error",
          message: "Could not save configurations",
          description:
            "There was a problem trying to change your account configurations. Please try again.",
        });
      }
    );
  }

  dispatch(_reqConfigs());
  return dispatch(api.configs.patch({ accountId }, params))
    .then(() => dispatch(listConfigs("live")))
    .catch((err) => {
      console.error(err);
      notification.open({
        type: "error",
        message: "Could not save configurations",
        description:
          "There was a problem trying to change your account configurations. Please try again.",
      });
    });
};

export const deleteAffiliate = onboardingApiAction(
  (dispatch, getState, accountId, token, affiliateId) =>
    api.affiliates
      .delete(accountId, token, affiliateId)
      .then(() => dispatch(listAffiliates()))
);

export const loadDataAgreement = (agreementName) => (dispatch, getState) => {
  const { accountId, token } = getAccountIdAndJWT(getState());
  return api.dataAgreement
    .get(accountId, agreementName, token)
    .then((agreement) => {
      dispatch({
        type: "app/RECEIVE_DATA_AGREEMENT",
        payload: {
          name: agreementName,
          agreement,
        },
      });
    });
};

export const postDataAgreement = onboardingApiAction(
  (dispatch, getState, accountId, token, agreementName, agreementText) =>
    api.dataAgreement
      .post(accountId, agreementName, token, agreementText)
      .then(() =>
        dispatch({
          type: "app/AGREEMENT_ACCEPTED",
          payload: { name: agreementName },
        })
      )
);

export const postTaxForm = onboardingApiAction(
  (dispatch, getState, accountId, token, formName) =>
    api.taxForm.post(accountId, formName, token).then(() =>
      dispatch({
        type: "app/TAXFORM_ACCEPTED",
        payload: { name: formName },
      })
    )
);

export const postApiAgreement =
  (agreementName, agreementText) => (dispatch, getState) => {
    const { accountId, token } = getAccountIdAndJWT(getState());
    return api.dataAgreement
      .post(accountId, agreementName, token, agreementText)
      .then(() =>
        api.ownerDetails
          .get(accountId, token)
          .then((details) =>
            Promise.all([dispatch(_setAccountDetails({ details }))])
          )
      );
  };

/**
 * Updates account info such as phone number and address.
 * Note: This action will trigger a review for the account owner.
 *
 * @param {Object} params
 */
export const updateAccountDetails = (params) => async (dispatch, getState) => {
  const { accountId } = getAccountIdAndJWT(getState());
  const token = await getSessionJWT();

  return api.ownerDetails.patch(accountId, params, token).then(() => {
    return api.ownerDetails.get(accountId, token).then((details) =>
      Promise.all([
        dispatch(_setAccountDetails({ details })),
        // also get new account info (this is where the email comes in)
        dispatch(getAccount()),
      ])
    );
  });
};

/**
 * Updates owner attributes such as email
 * Note: any update will set account status to ACCOUNT_UPDATED
 * Fully-Disclosed Correspondents can modify email without status change
 *
 * @param {Object} params
 */
export const updateAccountOwner = (params) => async (dispatch, getState) => {
  const { accountId } = getAccountIdAndJWT(getState());
  const token = await getSessionJWT();

  return api.owner.patch(params, token).then(() => {
    return api.owner.get(accountId, token).then((owner) =>
      Promise.all([
        dispatch(_setOwnerProps(owner)),
        // also get new account info (this is where the email comes in)
        dispatch(getAccount()),
      ])
    );
  });
};

/**
 * Updates account with an additional e-signature record.
 *
 * @param {Object} params
 */
export const addESignDetail = (params) => (dispatch, getState) => {
  const { accountId, token } = getAccountIdAndJWT(getState());
  return api.ownerDetails.patchESign(accountId, params, token).then(() => {
    return api.ownerDetails
      .get(accountId, token)
      .then((details) =>
        Promise.all([dispatch(_setAccountDetails({ details }))])
      );
  });
};

/**
 * Loads owner props from S3. These are useful for persisting dashboard/UX needs.
 * This information is not stored in gobroker.
 */
export const loadOwnerProps = () => (dispatch) => {
  return handleSessionExpire(
    Storage.get("props.json", {
      level: "private",
      region: "us-east-1",
      download: true,
    })
      .then((result) => {
        let ownerProps = {};
        if (result && result.Body) {
          ownerProps = result.Body;
        }

        return dispatch(_setOwnerProps(ownerProps));
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          // If the key did not exist, create it for the first time. It'll be empty for now.
          // This will remove 404 errors in the console for subsequent requests.
          // This file could be missing for existing users that have been migrated. Others should have the file.
          Storage.put("props.json", JSON.stringify({}), {
            level: "private",
            region: "us-east-1",
          });
        }
        // If file does not exist or there was some other problem.
        return dispatch(_setOwnerProps({}));
      }),
    dispatch
  );
};

/**
 * createWireBank will create a wire bank
 */
export const createWireBank = (metadata) => (dispatch, getState) => {
  dispatch(
    api.wireBanks.create(
      {
        accountId: getAlpacaAccountId(getState()),
      },
      metadata
    )
  )
    .then(() => {
      eventTracker("Linked Bank Account", {
        category: "Banking",
        label: window.location.pathname,
      });
      dispatch(clearCreateWireBankStatus());
      dispatch(_setCreateWireBankStatus({ error: null, metadata }));
      return dispatch(loadWireBanks());
    })
    .catch((error) => {
      dispatch(_setCreateWireBankStatus({ error, metadata }));
    });
};
/**
 * createRelationship will link a new bank account
 * given metadata from Plaid
 */
export const createRelationship = (metadata) => (dispatch, getState) => {
  const accountId = getAlpacaAccountId(getState());

  return dispatch(api.relationships.create({ accountId }, metadata))
    .then(() => {
      eventTracker("Linked Bank Account", {
        category: "Banking",
        label: window.location.pathname,
      });

      dispatch(_setCreateRelationshipStatus({ error: null, metadata }));
      return dispatch(loadRelationships());
    })
    .catch((error) => {
      dispatch(_setCreateRelationshipStatus({ error, metadata }));
    });
};

/**
 * verifyRelationship will verify a new bank account
 * given metadata from Plaid
 */
export const verifyRelationship =
  (relationshipId, metadata) => (dispatch, getState) => {
    const accountId = getAlpacaAccountId(getState());

    return dispatch(
      api.relationships.verify({ accountId, relationshipId }, metadata)
    )
      .then(() => {
        eventTracker("Linked Bank Account", {
          category: "Banking",
          label: window.location.pathname,
        });

        dispatch(_setCreateRelationshipStatus({ error: null, metadata }));
        return dispatch(loadRelationships());
      })
      .catch((error) => {
        dispatch(_setCreateRelationshipStatus({ error, metadata }));
      });
  };

/**
 * loadRelationships will load all linked bank accounts for the account
 */
export const loadRelationships = () => (dispatch, getState) =>
  dispatch(
    api.relationships.list({
      accountId: getAlpacaAccountId(getState()),
    })
  ).then((relationships) => {
    dispatch(_setRelationships(relationships));
    if (relationships.length === 0) return;
    return Promise.all(
      relationships.map((relationship) =>
        dispatch(loadInstitution(relationship.plaid_institution))
      )
    );
  });

/**
 * loadWireBanks will load all wire bank for the account
 */
export const loadWireBanks = () => (dispatch, getState) =>
  dispatch(
    api.wireBanks.list({
      accountId: getAlpacaAccountId(getState()),
    })
  ).then((wireBanks) => {
    dispatch(_setWireBanks(wireBanks));
  });

/**
 * listActivities will load all account activities for the account
 */
export const listActivities =
  (product, date = "", types = [], after = "", until = "") =>
  (dispatch, getState) => {
    const accountId = getAccountIdForProduct(getState(), product);
    const endpoint = product === "paper" ? "paperActivities" : "activities";

    return dispatch(
      api[endpoint].list(
        { accountId },
        { date: date, activity_types: types.join(","), after, until }
      )
    ).then((activities) => dispatch(_setActivities({ activities, accountId })));
  };

/**
 * TODO: This is deprecated. Will remove once it's confirmed there is no longer
 * any need to request the public token on the API.
 * getPlaidToken will get the Plaid public token for the relationship
 */
export const getPlaidToken =
  (relationshipId = "") =>
  async (dispatch, getState) => {
    return await dispatch(
      api.relationships.token({
        accountId: getAlpacaAccountId(getState()),
        relationshipId,
      })
    ).then((result) => dispatch(_setPlaidToken(result)));
  };

/**
 * deleteWireBank will remove a linked bank account
 */
export const deleteWireBank =
  (bankId = "") =>
  (dispatch, getState) =>
    dispatch(
      api.wireBanks.delete({
        accountId: getAlpacaAccountId(getState()),
        bankId,
      })
    ).then(() => {
      eventTracker("Unlinked Bank Account", {
        category: "Banking",
        label: window.location.pathname,
      });

      return dispatch(loadWireBanks());
    });

/**
 * deleteRelationship will remove a linked bank account
 */
export const deleteRelationship =
  (relationshipId = "") =>
  (dispatch, getState) =>
    dispatch(
      api.relationships.delete({
        accountId: getAlpacaAccountId(getState()),
        relationshipId,
      })
    ).then(() => {
      eventTracker("Unlinked Bank Account", {
        category: "Banking",
        label: window.location.pathname,
      });

      return dispatch(loadRelationships());
    });

export const loadInstitution = (institutionId) => (dispatch) => {
  if (!institutionId || institutionId == "micro_deposit") {
    return Promise.resolve();
  }
  return dispatch(api.institutions.get({ institutionId }))
    .then(({ institution }) => {
      if (institution) {
        dispatch(_setInstitution(institution));
      }
    })
    .catch(console.error);
};

/**
 * listTransfers will return a list of user's ACH or WIRE transfers.
 */
export const listTransfers = () => (dispatch, getState) => {
  const accountId = getAlpacaAccountId(getState());
  return dispatch(api.transfers.list({ accountId }))
    .then((xfers) => dispatch(_setTransfers(xfers)))
    .catch(console.error);
};

/**
 * loadPaperAccounts will return a list of the user's paper trading accounts.
 */
export const loadPaperAccounts = () => async (dispatch, getState) => {
  const { accountId } = getAccountIdAndJWT(getState());
  const token = await getSessionJWT();

  return handleSessionExpire(
    api.paperAccounts.list(accountId, token).then((resp) => {
      // If there are no paper accounts yet, create the first one for the user.
      if (resp && resp.length === 0) {
        return Promise.all([dispatch(createPaperAccount())]).then(() => {
          return Promise.all([dispatch(_setPaperAccounts(resp))]);
        });
      }

      return Promise.all([dispatch(_setPaperAccounts(resp))]);
    }),
    dispatch
  );
};

// A way for AccountProvider to sync account store state
export const syncAccounts = (newAccountState) => (dispatch) => {
  dispatch(_setSyncAccounts(newAccountState));
};

/**
 * createPaperAccount will create a new paper account using the user's current live account balance.
 * Only one can be created for now, so ensure there weren't any already.
 * Default cash is $100,000 unless otherwise specified.
 */
export const createPaperAccount =
  (recreate = false, cash = 100000) =>
  async (dispatch, getState) => {
    const { accountId } = getAccountIdAndJWT(getState());
    const token = await getSessionJWT();

    const account = getState().account;
    const paperAccounts =
      (account && account.paper && account.paper.accounts) || false;

    const cashInput = cash > 1000000000 ? 1000000000 : cash;

    // If no paper accounts exist or we are recreating one, send the request.
    return !paperAccounts || paperAccounts.length === 0 || recreate
      ? handleSessionExpire(
          api.paperAccounts
            .create(accountId, { cash: Number.parseFloat(cashInput) }, token)
            .then(
              () =>
                Promise.all[
                  (dispatch(setPaperAccount()), dispatch(unsetAccessKeys()))
                ]
            ),
          dispatch
        )
      : null;
  };

/**
 * deletePaperAccount will delete a paper account. If no id is provided, all
 * will be removed. There is currently only one paper account, so this is most common.
 */
export const deletePaperAccount =
  (paperAccountId) => async (dispatch, getState) => {
    const { accountId } = api.getAccountIdAndJWT(getState());
    const token = await getSessionJWT();

    if (paperAccountId) {
      return handleSessionExpire(
        api.paperAccounts
          .delete(accountId, { paperId: paperAccountId }, token)
          .then(() => dispatch(loadPaperAccounts())),
        dispatch
      );
    }
    // Remove all
    const account = getState().account;
    if (account.paper && account.paper.accounts) {
      let deleteOperations = [];

      account.paper.accounts.forEach((paperAccount) => {
        if (paperAccount.paper_account_id) {
          deleteOperations.push(
            api.paperAccounts.delete(
              accountId,
              { paperId: paperAccount.paper_account_id },
              token
            )
          );
        }
      });

      return handleSessionExpire(
        Promise.all(deleteOperations).then(() => dispatch(loadPaperAccounts())),
        dispatch
      );
    }
    return null;
  };

/**
 * resetPaperAccount will delete the old account associated with the request and create a new one
 * in its place - this consolidates the old frontend paper reset flow and does it all in gobroker now.
 */

export const resetPaperAccount =
  (cash = 100000) =>
  (dispatch, getState) => {
    const { accountId } = api.getAccountIdAndJWT(getState());

    const account = getState().account;
    const paperAccount = account.paper.accounts[0];
    const paperAccountId = paperAccount.paper_account_id;

    const cashInput = cash > 1000000000 ? 1000000000 : cash;

    return dispatch(
      api.paperAccounts.reset(
        { accountId, paperAccountId },
        { cash: cashInput }
      )
    ).then(() => dispatch(loadPaperAccounts()));
  };

/**
 * setPaperAccount will set the paper account to use (only one for now).
 * If the user has not set up a paper account yet, it will guide them in doing so.
 * Default amount is $100,000. Pass cash value to change.
 */
export const setPaperAccount =
  (cash = 100000) =>
  (dispatch, getState) => {
    return dispatch(loadPaperAccounts()).then(() => {
      const account = getState().account;
      let paperAccountId;

      if (
        !(account.paper && account.paper.accounts) ||
        account.paper.accounts.length === 0
      ) {
        return dispatch(createPaperAccount(false, cash)).then(() =>
          dispatch(setPaperAccount())
        );
      }
      paperAccountId =
        (account.paper.accounts[0] &&
          account.paper.accounts[0].paper_account_id) ||
        null;

      const p = [dispatch(_setPaperAccount(paperAccountId))];

      return Promise.all(p);
    });
  };

/**
 * listDocuments
 */
export const listDocuments =
  (params = {}) =>
  (dispatch, getState) => {
    // Ok to use live account id (paper accounts have no documents)
    const { token, accountId } = getAccountIdAndJWT(getState());

    if (accountId) {
      handleSessionExpire(
        api.documents
          .list(accountId, token, params)
          .then((documents) => dispatch(_setDocuments(documents))),
        dispatch
      );
    }
  };

/**
 * getDocumentUrl will get a presigned S3 temporary URL for a document
 */
export const getDocumentUrl =
  (documentId = "") =>
  async (dispatch, getState) => {
    const { accountId } = getAccountIdAndJWT(getState());
    return await dispatch(
      api.documents.url({ accountId, documentId }, null, { Accept: "*/*" })
    );
  };

export const resubmitOnfido = () => (dispatch, getState) => {
  const { accountId } = getAccountIdAndJWT(getState());
  return dispatch(api.onfidoResubmission.resubmit({ accountId }))
    .then((account) => dispatch(_setAccount({ account })))
    .catch((e) => {
      notification.open({
        type: "error",
        message: "Could not resubmit account",
        description: "Please contact support.",
      });
      throw e;
    });
};

// Export this reducer
const initialState = {};
export default createReducer(
  {
    [_setAccount]: (state, { account }) => ({ ...state, account }),
    [_setAccountDetails]: (state, { details }) => ({ ...state, details }),

    [_setOwnerProps]: (state, payload) => {
      return { ...state, props: payload };
    },

    [_setTrustedContact]: (state, payload) => {
      return { ...state, trustedContact: payload };
    },

    // Paper account
    [_setPaperAccounts]: (state, payload) => {
      const current =
        (payload && payload[0] && payload[0].paper_account_id) || null;
      const paper = {
        current,
        accounts: payload,
      };
      return { ...state, paper };
    },
    [_setPaperAccount]: (state, id) => {
      const paper = { ...state.paper, current: id || null };
      return { ...state, paper };
    },
    [unsetPaperAccount]: (state) => {
      // simply sets the current field to null
      const paper = { ...state.paper, current: null };
      return { ...state, paper };
    },

    // Sync Account store to AccountProvider
    [_setSyncAccounts]: (state, payload) => {
      return { ...state, ...payload };
    },

    // Documents
    [_setDocuments]: (state, payload) => {
      // Documents may have been previously loaded, additional API requests could load more.
      // For example, pagination or "load more" features. Append to the list of documents,
      // but remove any duplicates.
      // TODO: Think about this because if it is for pagination, is it even necessary?
      // Makes more sense for "load more" scenario.
      const mergeDocuments = (payload) => {
        const docs = state.documents || [];
        payload.map((x) => {
          let dup = false;
          docs.map((y) => {
            if (y.date === x.date && y.type === x.type) {
              dup = true;
            }
            return docs;
          });
          if (!dup) {
            docs.push(x);
          }
          return docs;
        });

        return docs;
      };

      return { ...state, documents: mergeDocuments(payload) };
    },

    // WireBanks
    [_setWireBanks]: (state, payload) => {
      return { ...state, wireBanks: payload };
    },
    // Relationships
    [_setRelationships]: (state, payload) => {
      return { ...state, relationships: payload };
    },
    [_setCreateRelationshipStatus]: (state, payload) => {
      return { ...state, createRelationshipStatus: payload };
    },
    [_setCreateWireBankStatus]: (state, payload) => {
      return { ...state, createWireBankStatus: payload };
    },
    [clearCreateRelationshipStatus]: (state) => {
      return { ...state, createRelationshipStatus: null };
    },
    [clearCreateWireBankStatus]: (state) => {
      return { ...state, createWireBankStatus: null };
    },
    [_setPlaidToken]: (state, payload) => {
      return { ...state, plaidToken: payload };
    },
    [_setInstitution]: (state, payload) => {
      if (!payload) return state;

      return {
        ...state,
        institutions: {
          ...state.institutions,
          [payload.institution_id]: {
            ...payload,
            logoUrl: payload.logo
              ? `data:image/png;base64,${payload.logo}`
              : null,
          },
        },
      };
    },
    [_setTransfers]: (state, payload) => {
      return { ...state, transfers: payload };
    },
    [_setActivities]: (state, payload) => {
      const activities = state.activities || {};
      activities[payload.accountId] = payload.activities;
      return { ...state, activities };
    },

    // User Configs
    [_setConfigs]: (state, payload) => {
      payload.is_fetching = false;
      return { ...state, configs: payload };
    },
    [_reqConfigs]: (state) => {
      let configs = Object.assign({}, state.configs);
      configs.is_fetching = true;
      return { ...state, configs };
    },

    // Affiliates
    [_setAffiliates]: (state, payload) => {
      return { ...state, affiliates: payload };
    },
    // Onfido SDK Token
    [_setOnfidoSDKToken]: (state, payload) => {
      return { ...state, onfido_sdk_token: payload.onfido_sdk_token };
    },
    // Onfido Applicant ID
    [_setOnfidoApplicantID]: (state, payload) => ({
      ...state,
      onfido_applicant_id: payload.onfido_details?.applicant_id,
    }),
  },
  initialState
);

export const requestEmailChange = (params) => (dispatch) => {
  const req = {
    email: params.email.toLowerCase(), // lowercase email
    // Better than nothing 😐
    a: weakEncrypt(params.password),
  };
  return dispatch(api.owner.requestEmail({}, req)).then(() => {
    dispatch(setEmailUpdateVerificationRequired(true));
  });
};

export const verifyEmailChange = (params) => async (dispatch) => {
  const token = await getSessionJWT();

  const req = {
    code: params.code,
  };

  return dispatch(api.owner.verifyEmail({}, req)).then(() => {
    return Promise.all([
      dispatch(setEmailUpdateVerificationRequired(false)),
      dispatch(api.account.list({}, token)).then((accounts) => {
        return dispatch(
          _setAccount({
            account: accounts[0] || {},
          })
        );
      }),
    ]);
  });
};
