import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Checkbox, Row, Input, notification, Table } from "antd";
import AntPhoneInput from "src/components/Utilities/AntPhoneInput";
import Markdown from "components/Utilities/Markdown";
import QRCode from "@alpacahq/qrcode.react";
import { recovery } from "src/api";
import { Card, CardBody } from "src/components/common";
import { Icon } from "@alpacahq/ui";
import copy from "copy-to-clipboard";
import { useToast } from "@chakra-ui/react";

export const BigCard = styled.div`
  background-color: #ffffff;

  &::after {
    content: "";
    clear: both;
    display: table;
  }
`;

export const SmallCard = styled.div`
  box-sizing: border-box;
  padding: 16px;
  border-radius: 4px;
  border: 1px rgba(0, 0, 0, 0.15) solid;
  margin-bottom: 1em;
`;

export const SecurityLink = styled.div`
  float: right;
  cursor: pointer;
  text-decoration: underline;
  height: 16px;
  font-family: Gilroy-Light;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
`;

export const Caption = styled.div`
  font-family: Carnas;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;
export const Text = styled.div`
  font-family: Gilroy-Light;
  height: 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303030;
`;

export const EditBtn = styled.button`
  float: right;
  background-color: rgba(48, 48, 48, 0.5);
  border-color: rgba(48, 48, 48, 0.5);
  border: none;
  color: white;
  width: 60px;
  height: 29px;
  border-radius: 4px;
`;

export const ConfigContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormContainer = styled.div`
  input::placeholder {
    color: #303030 !important;
    font-family: Gilroy-Light;
  }
`;

export const StatusIndicator = styled.div`
  float: right;
  display: flex;
  justify-content: space-between;
`;

export const RadioWrapper = styled.div`
  font-family: Gilroy-Light;
  height: 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: #303030;
  width: 100%;
  .ant-radio-group {
    display: flex;
  }

  .ant-radio-inner {
    border-color: #f0f0f0 !important;
  }

  .ant-radio-inner::after {
    transition-duration: 0.1s !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #979797 !important;
  }

  .ant-radio:hover .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(144, 144, 144, 0.08) !important;
  }

  .ant-radio:focus-within .ant-radio-inner {
    border-color: #979797 !important;
    box-shadow: 0 0 0 3px rgba(144, 144, 144, 0.08) !important;
  }

  .ant-radio-inner:after {
    background-color: #ffd700;
    border-color: #f0f0f0;
  }
`;

export const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 16px;
    border: solid 1px rgba(0, 0, 0, 0.15);
  }

  span:before {
    background-color: #979797;
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 50%;
  }

  input:checked + span:before {
    background-color: #ffd700;
  }

  input:checked + span:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
`;

export const EditIcon = (props) => {
  return (
    <i
      style={{ marginLeft: "12px", fontSize: "13px", cursor: "pointer" }}
      onClick={props.onClick}
      className="fas fa-pencil-alt"
    />
  );
};

export const LoadIcon = () => {
  return (
    <img
      style={{ height: "35px" }}
      src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTBweCIgIGhlaWdodD0iNTBweCIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiBjbGFzcz0ibGRzLXJvbGxpbmciIHN0eWxlPSJiYWNrZ3JvdW5kOiBub25lOyI+PGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgZmlsbD0ibm9uZSIgbmctYXR0ci1zdHJva2U9Int7Y29uZmlnLmNvbG9yfX0iIG5nLWF0dHItc3Ryb2tlLXdpZHRoPSJ7e2NvbmZpZy53aWR0aH19IiBuZy1hdHRyLXI9Int7Y29uZmlnLnJhZGl1c319IiBuZy1hdHRyLXN0cm9rZS1kYXNoYXJyYXk9Int7Y29uZmlnLmRhc2hhcnJheX19IiBzdHJva2U9IiNmY2Q1MDMiIHN0cm9rZS13aWR0aD0iMTAiIHI9IjM1IiBzdHJva2UtZGFzaGFycmF5PSIxNjQuOTMzNjE0MzEzNDY0MTUgNTYuOTc3ODcxNDM3ODIxMzgiIHRyYW5zZm9ybT0icm90YXRlKDI4Ny45NiA1MCA1MCkiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjAgNTAgNTA7MzYwIDUwIDUwIiBrZXlUaW1lcz0iMDsxIiBkdXI9IjJzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIj48L2FuaW1hdGVUcmFuc2Zvcm0+PC9jaXJjbGU+PC9zdmc+"
      alt=""
    />
  );
};

export const Loading = styled.div`
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTBweCIgIGhlaWdodD0iNTBweCIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiBjbGFzcz0ibGRzLXJvbGxpbmciIHN0eWxlPSJiYWNrZ3JvdW5kOiBub25lOyI+PGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgZmlsbD0ibm9uZSIgbmctYXR0ci1zdHJva2U9Int7Y29uZmlnLmNvbG9yfX0iIG5nLWF0dHItc3Ryb2tlLXdpZHRoPSJ7e2NvbmZpZy53aWR0aH19IiBuZy1hdHRyLXI9Int7Y29uZmlnLnJhZGl1c319IiBuZy1hdHRyLXN0cm9rZS1kYXNoYXJyYXk9Int7Y29uZmlnLmRhc2hhcnJheX19IiBzdHJva2U9IiNmY2Q1MDMiIHN0cm9rZS13aWR0aD0iMTAiIHI9IjM1IiBzdHJva2UtZGFzaGFycmF5PSIxNjQuOTMzNjE0MzEzNDY0MTUgNTYuOTc3ODcxNDM3ODIxMzgiIHRyYW5zZm9ybT0icm90YXRlKDI4Ny45NiA1MCA1MCkiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjAgNTAgNTA7MzYwIDUwIDUwIiBrZXlUaW1lcz0iMDsxIiBkdXI9IjJzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIj48L2FuaW1hdGVUcmFuc2Zvcm0+PC9jaXJjbGU+PC9zdmc+");
`;

export const DropdownWrapper = styled.div`
  margin-right: 5px;
  .ant-select-selection {
    width: 182px;
    border-radius: 4px;
    border: solid 2px #f0f0f0 !important;
    font-family: Gilroy-Medium;
    font-size: 14px;
    line-height: 40px;
    color: #303030;
    box-shadow: none !important;
  }
  svg {
    fill: #ffd700;
  }
`;

export const Agreement = styled(Markdown)`
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #152430;
  height: 280px;
  overflow-y: scroll;
  padding: 10px;
  margin-bottom: 20px;
  ${(props) =>
    props.onboarding ? "background: #f4f4f4; margin-top: 5rem;" : ""}
`;

export const CryptoCard = styled(SmallCard)`
  padding: 4px;
  ${(props) => (props.highlightCrypto ? "border-color: #F2C53D;" : "")}
`;

export const CardTitle = styled.div`
  font-family: "Gilroy-Medium", sans-serif;
  margin: 10px 0px -10px 15px;
  font-size: 15px;
  font-weight: 600;
  color: #000000;
`;

export const CollapseText = styled.span`
  font-family: Gilroy-Light;
  font-size: 14px;
`;

export const AgreeBtn = styled(Button)`
  float: right;
  margin: 2rem;
`;

export const AgreeCheckbox = styled(Checkbox)`
  float: right;
  margin: 2rem;
  margin-top: 3rem;
`;

export const FloatText = styled.span`
  float: ${(props) => (props.right ? "right" : "left")};
`;

export const BoldText = styled.span`
  font-family: sans-serif;
`;

export const AddressConfirmRow = styled(Row)`
  margin-top: 75px;
  opacity: 80%;
  justify-content: center;
`;

export const AgreeBox = styled(Checkbox)`
  margin-top: 10px;
`;

export const FeeTable = styled(Table)`
  th,
  td {
    text-align: center !important;
  }
`;

const MFACard = styled.div`
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #979797;
  border-radius: 4px;
  align-items: center;
`;

const MFASubCard = styled.div`
  display: flex;
  align-items: center;
`;

const MFAText = styled.span`
  font-size: 14px;
  color: #000;
`;

const ErrorText = styled(MFAText)`
  color: red;
  margin-top: 0.5rem;
`;

const MFATitleCard = styled(MFASubCard)`
  flex-direction: column;
  margin-left: 1rem;
  align-items: baseline;
`;

const SecurityLevelText = styled.span`
  color: ${(props) => props.color};
  font-size: 13px;
`;

const ModalScreenCard = styled(MFASubCard)`
  flex-direction: column;
  text-align: center;
  max-width: 450px;
  padding: 15px;
`;

const MFACodeInput = styled(Input)`
  max-width: 110px;
  border-color: black;
  width: 84px;
  margin-top: 2rem;
`;

const MFAModalTitle = styled.span`
  font-size: 24px;
  font-weight: 400;
  color: black;
  margin-bottom: 1rem;
`;

const MFAModalSubtitle = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: black;
`;

const ModalButtonRow = styled.span`
  width: 60%;
  margin-top: 3rem;
`;

const ModalButton = styled(Button)`
  width: 50%;
`;

const ModalButtonPrev = styled(ModalButton)`
  border: none;
`;

const CompletionCheck = styled.img`
  filter: ${(props) => (props.disabled ? "grayscale(100%)" : "none")};
  max-width: 128px;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const RecoveryCode = styled.div`
  background: #f7f7f7;
  border-radius: 4px;
  font-weight: 700;
  padding: 12px 24px;
  margin-top: 1.5rem;
`;

const RecoveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;

const getIconName = (type) => {
  switch (type) {
    case "sms":
      return "ChatBubbleBottomCenterText";
    case "totp":
      return "DevicePhoneMobile";
    default:
      return "DevicePhoneMobile";
  }
};

export const ProfileMFA = ({
  type,
  title,
  securityLevel,
  onClick,
  disabled,
  current,
}) => {
  return (
    <Card style={{ boxShadow: "none", border: "0px" }}>
      <CardBody
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px",
        }}
      >
        <MFASubCard>
          <Icon name={getIconName(type)} className="max-h-8" />
          <MFATitleCard>
            <MFAText>{title}</MFAText>
            <SecurityLevelText
              color={type === "sms" ? "rgb(240, 192, 2)" : "rgb(6, 157, 72)"}
            >
              {securityLevel}
            </SecurityLevelText>
          </MFATitleCard>
        </MFASubCard>
        <Button disabled={current !== "NOMFA" && disabled} onClick={onClick}>
          {disabled ? "Activate" : "Disable"}
        </Button>{" "}
      </CardBody>
    </Card>
  );
};

const getTOTPPlainTextCode = (totpQRCode) => {
  try {
    const secret = totpQRCode.split("secret=")[1].split("&issuer=")[0];
    return secret;
  } catch (e) {
    return "No code available, please try again.";
  }
};

// 2fa modal contents
export const TOTPScreen = ({ totpQRCode, onCancel, onNext }) => {
  const toast = useToast();
  const [isText, setIsText] = useState(false);

  const textCode = getTOTPPlainTextCode(totpQRCode);

  const onToggleText = () => setIsText((isText) => !isText);

  const onCopyTextClicked = () => {
    copy(textCode);
    toast({
      title: "Code copied to clipboard!",
      status: "success",
    });
  };

  return (
    <ModalScreenCard>
      <MFAModalTitle>Activate 2FA via authenticator app</MFAModalTitle>
      <MFAText>
        {isText
          ? "Enter code into authenticator app"
          : "Scan this QR code with the authenticator app"}
      </MFAText>
      <div className="my-10 max-w-sm">
        {isText ? (
          <span
            className="text-wrap cursor-pointer"
            onClick={onCopyTextClicked}
          >
            {textCode}
          </span>
        ) : (
          <QRCode value={totpQRCode || ""} />
        )}
      </div>
      <Text>
        {isText ? "Want to scan QR code?" : "Can't scan QR code?"}{" "}
        <button className="underline" onClick={onToggleText}>
          Click here to see {isText ? "QR code" : "text code"}
        </button>
        .
      </Text>
      <ModalButtonRow style={{ display: "flex" }}>
        <ModalButtonPrev
          size="large"
          style={{ marginRight: "1rem" }}
          onClick={onCancel}
        >
          Cancel
        </ModalButtonPrev>
        <ModalButton onClick={onNext} type="primary" size="large">
          Next
        </ModalButton>
      </ModalButtonRow>
    </ModalScreenCard>
  );
};

export const SMSScreen = ({ onNext, onCancel, onSubmit }) => {
  const [phoneNumer, setPhoneNumber] = useState("");
  return (
    <ModalScreenCard>
      <MFAModalTitle>Enable 2FA via SMS Code</MFAModalTitle>
      <MFAText>
        To enable SMS 2FA, you need to have a phone number capable of receiving
        text messages.
      </MFAText>
      <AntPhoneInput
        placeholder="+1 XXX-XXX-XXXX"
        onChange={setPhoneNumber}
        style={{ marginTop: "2.5rem" }}
      />
      <ModalButtonRow style={{ display: "flex" }}>
        <ModalButtonPrev
          size="large"
          onClick={onCancel}
          style={{ marginRight: "1rem" }}
        >
          Cancel
        </ModalButtonPrev>
        <ModalButton
          onClick={() => {
            onNext();
            onSubmit(phoneNumer);
          }}
          type="primary"
          size="large"
        >
          Next
        </ModalButton>
      </ModalButtonRow>
    </ModalScreenCard>
  );
};

export const MFAChallenge = ({
  activating,
  type,
  onVerify,
  onBack,
  title,
  error,
}) => {
  const subheaderMap = {
    SOFTWARE_TOKEN_MFA: "your authenticator app generated",
    SMS_MFA: "you received via SMS",
  };

  let buttonCopy = activating ? "Activate" : "Disable";
  if (activating === null) {
    buttonCopy = "Submit";
  }

  const [code, setCode] = useState("");
  return (
    <ModalScreenCard>
      <MFAModalTitle>{title}</MFAModalTitle>
      <MFAText>{`Enter the code ${subheaderMap[type]}`}</MFAText>
      <MFACodeInput
        placeholder="XXXXXX"
        onChange={(e) => {
          setCode(e.target.value);
        }}
      />
      <ErrorText>{error}</ErrorText>
      <ModalButtonRow>
        <ModalButtonPrev
          size="large"
          onClick={onBack}
          disabled={onBack === null}
        >
          Back
        </ModalButtonPrev>
        <ModalButton
          onClick={() => {
            onVerify(code, activating);
          }}
          size="large"
          type="primary"
          disabled={code.length !== 6}
        >
          {buttonCopy}
        </ModalButton>
      </ModalButtonRow>
    </ModalScreenCard>
  );
};

const CompletedView = ({ activating, title, onLoad, cognitoId }) => {
  const [recoveryCode, setRecoveryCode] = useState("");

  useEffect(() => {
    onLoad();
    async function handleRecovery(creating) {
      if (creating) {
        await createRecoveryCode(cognitoId, setRecoveryCode);
      } else {
        await deleteRecoveryCode(cognitoId);
      }
    }
    handleRecovery(activating);
  }, []);
  return (
    <ModalScreenCard>
      <MFAModalTitle>{title}</MFAModalTitle>
      <CompletionCheck
        src="/resources/images/completion-check.png"
        disabled={!activating}
      />
      <MFAModalSubtitle>{`Successful ${
        activating ? "activation" : "deactivation"
      }`}</MFAModalSubtitle>
      <MFAText>
        {activating
          ? "You have taken an important step to keep your account secure."
          : "You can turn on two-factor authentication anytime."}
      </MFAText>
      {activating && (
        <RecoveryContainer>
          <MFAText>
            {
              "Save this emergency code and store it somewhere safe. If you lose your mobile device, you can use this code to bypass 2FA and sign in."
            }
          </MFAText>
          <RecoveryCode>
            <MFAText>{recoveryCode}</MFAText>
          </RecoveryCode>
        </RecoveryContainer>
      )}
    </ModalScreenCard>
  );
};

export const MFAModalContents = ({
  activating,
  totpQRCode = "",
  type,
  onVerify,
  onCancel,
  current,
  onSubmit,
  error = "",
  cognitoId = "",
}) => {
  const [step, setStep] = useState(activating ? 0 : 1);
  const headerMap = {
    SOFTWARE_TOKEN_MFA: "authenticator app",
    SMS_MFA: "SMS code",
  };
  const title = activating
    ? `Activate 2FA via ${headerMap[type]}`
    : "Disable 2FA";

  // triggers the confirmation page if the state has properly changed
  const completed =
    (!activating && current === "NOMFA") || (activating && current !== "NOMFA");
  if (completed) {
    return (
      <CompletedView
        activating={activating}
        title={title}
        onLoad={() => setStep(activating ? 1 : 0)}
        cognitoId={cognitoId}
      />
    );
  }

  switch (step) {
    case 0:
      if (type === "SMS_MFA") {
        return (
          <SMSScreen
            onNext={() => setStep(1)}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        );
      }
      return (
        <TOTPScreen
          totpQRCode={totpQRCode}
          onCancel={onCancel}
          onNext={() => setStep(1)}
        />
      );
    case 1:
      return (
        <MFAChallenge
          activating={activating}
          type={type}
          onVerify={onVerify}
          onBack={() => setStep(0)}
          title={title}
          error={error.message}
        />
      );
  }
};

const createRecoveryCode = async (cognitoId, setCode) => {
  const endpoint = recovery.create({
    cognitoId,
  });
  endpoint()
    .then((res) => {
      setCode(res.code);
    })
    .catch(() =>
      notification.open({
        type: "error",
        message: "An error occurred while creating recovery codes.",
      })
    );
};

const deleteRecoveryCode = async (cognitoId) => {
  const endpoint = recovery.delete({
    cognitoId,
  });
  endpoint().catch((e) => {
    console.error(e);
  });
};

export const formatCryptoVolume = (v) => {
  if (v > 0) {
    let comma = Number(v).toLocaleString("en-US");
    return `>${comma}`;
  }
  return v;
};

export const formatCryptoFee = (fee) => {
  return `${Number(fee).toFixed(
    Math.max(((fee + "").split(".")[1] || "").length, 2)
  )}%`;
};

export const feeCryptoLevel = (volume) => {
  const numVolume = Number(volume);
  switch (true) {
    case numVolume > 100000000:
      return "8";
    case numVolume > 50000000:
      return "7";
    case numVolume > 25000000:
      return "6";
    case numVolume > 10000000:
      return "5";
    case numVolume > 5000000:
      return "4";
    case numVolume > 1000000:
      return "3";
    case numVolume > 500000:
      return "2";
    case numVolume >= 0:
      return "1";
  }
};
