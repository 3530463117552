import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import useGetLiveAccounts from "../api/hooks/useGetLiveAccounts";
import { Account, PaperAccount } from "../api/rest/account";
import useGetPaperAccountsWithDetails from "../api/hooks/useGetPaperAccountsWithDetails";
import { syncAccounts } from "src/reducers/account";
import useLocalStorage from "src/hooks/useLocalStorage";

export interface AccountContextProps {
  liveAccounts: Account[];
  paperAccounts: Account[];
  paperAccountsNoDetail: PaperAccount[];
  refetchLiveAccounts: () => void;
  refetchPaperAccounts: (reset?: boolean) => void;
  currentAccount?: Account;
  setCurrentAccount: (newAccount: Account) => void;
  setProduct: (product: string) => void;
}

const ACCOUNT_CONTEXT_DEFAULTS: AccountContextProps = {
  liveAccounts: [],
  paperAccounts: [],
  paperAccountsNoDetail: [],
  refetchLiveAccounts: () => null,
  refetchPaperAccounts: () => null,
  currentAccount: undefined,
  setCurrentAccount: () => null,
  setProduct: () => null,
};

type AccountProviderProps = {
  children: ReactNode;
  dispatch?: any;
  status?: string;
};

export const AccountContext = createContext<AccountContextProps>(
  ACCOUNT_CONTEXT_DEFAULTS
);

const getCurrentProduct = (product?: string) =>
  product === "dynamic"
    ? window.localStorage.getItem("product") ?? "paper"
    : product;

const AccountProvider = ({
  children,
  dispatch,
  status,
}: AccountProviderProps) => {
  const [currentAccount, setCurrentAccount] = useState<Account>();
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Date | undefined>();
  const [product, setProduct] = useState<string>();

  const [savedAccountId, setSavedAccountId] = useLocalStorage("savedAccount");

  const {
    data: liveAccounts,
    refetch: refetchLiveAccounts,
    isLoading: isLiveAccountsLoading,
  } = useGetLiveAccounts({
    enabled: status === "loaded",
  });
  const {
    data: paperAccounts,
    paper_accounts: paperAccountsNoDetail,
    refetch: refetchPaperAccounts,
    isLoading: isPaperAccountsLoading,
  } = useGetPaperAccountsWithDetails({
    enabled: status === "loaded",
  });

  const handleSetCurrentAccount = (account?: Account) => {
    setCurrentAccount(account);

    setSavedAccountId(account?.id);

    dispatch?.(
      syncAccounts(
        getAccountReduxStoreFormat({
          liveAccounts: liveAccounts ?? [],
          paperAccountsNoDetail: paperAccountsNoDetail ?? [],
          currentAccount: account,
        })
      )
    );

    setLastUpdatedAt(undefined);
  };

  const handleRefetchPaperAccounts = async (
    resetAccount?: boolean | PaperAccount
  ) => {
    await refetchPaperAccounts();

    if (resetAccount) {
      // trigger useEffect to select a new current account as currentAccount deleted
      setCurrentAccount(undefined);
    } else {
      handleSyncRedux();
    }
  };

  const handleSyncRedux = () => {
    setLastUpdatedAt(new Date());
  };

  // Initial Setup and when deletion occurs
  useEffect(() => {
    if (
      !isPaperAccountsLoading &&
      !isLiveAccountsLoading &&
      !currentAccount &&
      product
    ) {
      const currentProduct = getCurrentProduct(product);

      if (currentProduct === "live") {
        const initialAccount = liveAccounts?.find(
          (liveAccount) => liveAccount.id === savedAccountId
        );

        handleSetCurrentAccount(
          initialAccount ?? liveAccounts?.[0] ?? undefined
        );
      } else if (currentProduct === "paper") {
        const initialAccount = paperAccounts?.find(
          (paperAccount) => paperAccount.id === savedAccountId
        );

        handleSetCurrentAccount(initialAccount ?? paperAccounts?.[0]);
      } else {
        // if product is dynamic or alpaca, try to find savedAccount else default to Paper
        const initialAccount =
          liveAccounts?.find(
            (liveAccount) => liveAccount.id === savedAccountId
          ) ??
          paperAccounts?.find(
            (paperAccount) => paperAccount.id === savedAccountId
          );

        handleSetCurrentAccount(initialAccount ?? paperAccounts?.[0]);
      }
    }
  }, [isLiveAccountsLoading, isPaperAccountsLoading, currentAccount, product]);

  // Syncs Redux when all data loaded after a handleSyncRedux is called e.g. createAccount
  useEffect(() => {
    if (
      !isLiveAccountsLoading &&
      !isPaperAccountsLoading &&
      lastUpdatedAt &&
      currentAccount
    ) {
      handleSetCurrentAccount(currentAccount);
    }
  }, [
    lastUpdatedAt,
    isPaperAccountsLoading,
    isLiveAccountsLoading,
    currentAccount,
  ]);

  return (
    <AccountContext.Provider
      value={{
        liveAccounts: liveAccounts ?? [],
        paperAccounts,
        paperAccountsNoDetail: paperAccountsNoDetail ?? [],
        refetchLiveAccounts,
        refetchPaperAccounts: handleRefetchPaperAccounts,
        currentAccount,
        setCurrentAccount: handleSetCurrentAccount,
        setProduct,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccountContext = () => useContext(AccountContext);

// A Higher Order Component made to give AccountContext to class components
export const withAccountContext = (Component: any) => {
  return (props: any) => {
    const accountContext = useAccountContext();

    return <Component accountContext={accountContext} {...props} />;
  };
};

// Migrating away from Redux by removing one store at a time
// However, many stores are dependent on the account store, so transform
// the current props into the account store format and sync it
export const getAccountReduxStoreFormat = ({
  liveAccounts,
  paperAccountsNoDetail,
  currentAccount,
}: {
  liveAccounts: Account[];
  paperAccountsNoDetail: PaperAccount[];
  currentAccount?: Account;
}) => {
  return {
    account: liveAccounts[0],
    paper: {
      current:
        currentAccount?.id ?? paperAccountsNoDetail?.[0]?.paper_account_id,
      accounts: paperAccountsNoDetail,
    },
  };
};

export default AccountProvider;
