import useRequest, { QueryOptions } from "./useRequest";
import { getOwners, Owner } from "../rest/account";

export const useGetOwners = (
  key: any | any[] = "",
  accountId: string,
  options?: QueryOptions<Owner[]>
) => {
  const { data: owners, refetch } = useRequest(
    key,
    () => getOwners(accountId),
    {
      refetchInterval: false,
      enabled: !!accountId,
      ...options,
    }
  );

  return { owners, refetch };
};

export default useGetOwners;
