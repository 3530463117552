import Auth from "@aws-amplify/auth";

import axios, { AxiosRequestConfig } from "axios";

const instance = axios.create({ baseURL: (window as any).env.API_URL });

// set auth header
instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  try {
    // check if authenticated
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();
    config.headers.Authorization = `Bearer ${idToken.getJwtToken()}`;
  } catch (err) {
    // do nothing if not authenticated
  }
  return config;
});

export default instance;
