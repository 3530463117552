import useRequest, { QueryOptions } from "./useRequest";
import {
  OptionsApprovalStatus,
  getOptionsApprovalStatus,
} from "../rest/account";

export const useGetOptionsApprovalStatus = (
  key: any | any[] = "",
  accountID: string = "",
  options?: QueryOptions<OptionsApprovalStatus>
) => {
  const { data } = useRequest(key, () => getOptionsApprovalStatus(accountID), {
    ...options,
  });

  return { data };
};
