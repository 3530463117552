import { clsx } from "@alpacahq/ui";
import React from "react";
import { getPrettyCash } from "src/v2/utils/formatting";

export type SidebarAccountItemProperties = {
  name: string;
  id: string;
  number: string;
  margin?: string;
  percentage: number;
  equity?: string;
  last_equity?: string;
};

type SidebarAccountItemProps = SidebarAccountItemProperties & {
  onClick?: () => void;
  active?: boolean;
};

type SidebarAccountItemDividerProps = {
  label: string;
};

type SidebarAccountMenuItemProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

const SidebarAccountItem = ({
  name,
  number,
  equity,
  percentage,
  onClick,
  active,
}: SidebarAccountItemProps) => {
  return (
    <div
      className={clsx(
        `items-center w-full p-2.5 rounded-lg space-y-0.5`,
        !!onClick && "cursor-pointer hover:bg-gray-100",
        active && "bg-gray-100"
      )}
      onClick={onClick}
    >
      <div className="flex justify-between font-medium text-sm text-gray-900 gap-2">
        <div className="whitespace-nowrap truncate">{name}</div>
        <div className="font-normal">{getPrettyCash(equity)}</div>
      </div>
      <div className="flex justify-between gap-4 font-medium text-xs text-true-gray-600">
        <div>{number}</div>
        <div
          className={`${
            percentage >= 0 ? "text-emerald-500" : "text-rose-500"
          }`}
        >
          {percentage?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          %
        </div>
      </div>
    </div>
  );
};

export const SidebarAccountItemDivider = ({
  label,
}: SidebarAccountItemDividerProps) => {
  return (
    <div className="uppercase text-gray-500 font-medium text-xs mb-2.5">
      {label}
    </div>
  );
};

export const SidebarAccountMenuItem = ({
  children,
  onClick,
}: SidebarAccountMenuItemProps) => (
  <div
    className={clsx(
      "p-2.5 cursor-pointer rounded-md",
      !!onClick && "hover:bg-gray-100"
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

export default SidebarAccountItem;
