export const isCryptoEnabled = (countryCode, state, infoMap) => {
  if (countryCode === "USA") {
    const supportedStates = window.env.SUPPORTED_CRYPTO_STATES || "";
    return supportedStates.split(",").includes(state?.toUpperCase());
  }

  return Boolean(infoMap?.[countryCode]?.crypto_enabled);
};

export const inSupportedLocation = (accountDetails) => {
  const supportedCountries =
    window.env.SUPPORTED_CRYPTO_TRANSFER_COUNTRIES?.split(",") ?? [];

  const supportedStates = window.env.SUPPORTED_CRYPTO_STATES?.split(",") ?? [];

  return _inSupportedLocation(
    accountDetails,
    supportedCountries,
    supportedStates
  );
};

export const _inSupportedLocation = (
  accountDetails,
  supportedCountries,
  supportedStates
) => {
  if (!accountDetails) return false;
  const { state, country_of_tax_residence } = accountDetails;

  if (supportedCountries.includes(country_of_tax_residence)) {
    if (country_of_tax_residence === "USA") {
      return supportedStates.includes(state);
    }
    return true;
  }

  return false;
};
